import {
  HCP_CHAT_WITH_SALES,
  HCP_CHAT_WITH_MSL_USER_LEVEL,
  GET_CONTENT_ANALYTICS,
  NUMBER_OF_HCP_ACROSS_ORG,
  NUMBER_OF_CONVERSION_TRANSFERS,
  NUMBER_OF_USERS_IN_ORG,
  NUMBER_OF_ACTIVE_HCP,
  TOTAL_NUMBER_OF_ORGS,
  TOTAL_NUMBER_OF_HCPS,
  TOTAL_NUMBER_OF_SALES,
  TOTAL_NUMBER_OF_MSL,
} from "../constants/actionTypes";

const initialState = {
  hcpChatWithSales: [],
  hcpChatWithMSLUserLevel: [],
  contentAnalytics: [],
  noOfHCPAcrossOrganization: [],
  noOfConversionTransfer: [],
  noOfUsersInOrg: [],
  noOfActiveHCP: [],
  totalNumberOfOrgs: [],
  totalNumberOfHcps: [],
  totalSales: [],
  totalMSLs: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case HCP_CHAT_WITH_SALES:
      return {
        ...state,
        hcpChatWithSales:
          payload && payload.success && payload.data ? payload.data : [],
      };
    case HCP_CHAT_WITH_MSL_USER_LEVEL:
      return {
        ...state,
        hcpChatWithMSLUserLevel:
          payload && payload.success && payload.data ? payload.data : [],
      };
    case GET_CONTENT_ANALYTICS:
      return {
        ...state,
        contentAnalytics:
          payload && payload.success && payload.data ? payload.data : [],
      };
    case NUMBER_OF_HCP_ACROSS_ORG:
      return {
        ...state,
        noOfHCPAcrossOrganization:
          payload && payload.success && payload.data ? payload.data : [],
      };
    case NUMBER_OF_CONVERSION_TRANSFERS:
      return {
        ...state,
        noOfConversionTransfer:
          payload && payload.success && payload.data ? payload.data : [],
      };
    case NUMBER_OF_USERS_IN_ORG:
      return {
        ...state,
        noOfUsersInOrg:
          payload && payload.success && payload.data ? payload.data : [],
      };
    case NUMBER_OF_ACTIVE_HCP:
      return {
        ...state,
        noOfActiveHCP:
          payload && payload.success && payload.data ? payload.data : [],
      };
    case TOTAL_NUMBER_OF_ORGS:
      return {
        ...state,
        totalNumberOfOrgs:
          payload && payload.success && payload.data ? payload.data : [],
      };
    case TOTAL_NUMBER_OF_HCPS:
      return {
        ...state,
        totalNumberOfHcps:
          payload && payload.success && payload.data ? payload.data : [],
      };
    case TOTAL_NUMBER_OF_SALES:
      return {
        ...state,
        totalSales:
          payload && payload.success && payload.data ? payload.data : [],
      };
    case TOTAL_NUMBER_OF_MSL:
      return {
        ...state,
        totalMSLs:
          payload && payload.success && payload.data ? payload.data : [],
      };
    default:
      return state;
  }
}
