import { Link, useHistory } from "react-router-dom";
import ListErrors from "./ListErrors";
import React, { useEffect, useState } from "react";
import agent from "../agent";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  LOGIN_PAGE_UNLOADED,
} from "../constants/actionTypes";
import LanguageSelector from "./LanguageSelector";
import {ReactComponent as Logo} from "../assets/login_logo_white.svg";

import "./styles/Login.scss";

const mapStateToProps = (state) => ({
  ...state.auth,
  loginSuccess: state.common.loginSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeUsername: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "username", value }),
  onChangePassword: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "password", value }),
  onSubmit: (username, password) => {
    dispatch({ type: LOGIN, payload: agent.Auth.login(username, password) });
  },
  // onSubmit: (username, password) => {
  //   dispatch({ type: LOGIN, payload: {user:{token:"123456789acdefghijklmnopqrstuvwxyz", name: "AiSugimura"}} });
  // },
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
});

const Login = (props) => {
  const [showPassowrd, setShowPassword] = useState(false);
  const {
    username,
    password,
    errors,
    onChangeUsername,
    onChangePassword,
    onSubmit,
    onUnload,
    inProgress,
    loginSuccess
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const changeUsername = (ev) => onChangeUsername(ev.target.value);
  const changePassword = (ev) => onChangePassword(ev.target.value);
  const submitForm = (email, password) => (ev) => {
    ev.preventDefault();
    onSubmit(email, password);
  };

  useEffect(() => {
    if (loginSuccess) {
      history.push("/dashboard");
    }
    return () => {
      onUnload();
    };
  }, [loginSuccess]);
  return (
    <div className="auth-page">
      <div className="page">
        <LanguageSelector />
        <div className="row">
          <div className="col-md-6">
            <div className="hidden-banner">
              <div className="login-banner">
                <div className="main-message">
                  {/* <img src="../../assets/logo_black.svg" alt="logo" /> */}
                  <Logo style={{width: "100%"}} />

                </div>
                <div className="description mt-4">
                  <h2>Welcome To Consilium Health</h2>
                  <hr className="separator" />
                  <p>
                    {t("login.bottom.message")} <br />
                    <i>{t("login.bottom.smallText")}</i>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="brand-name">
              {/* <div className="logo"></div> */}
              <h4 className="text-xs-left ">{t("login.title")}</h4>
            </div>
            <h6 className="mb-4">{t("login.message")}</h6>

            <ListErrors errors={errors} />

            <form onSubmit={submitForm(username, password)}>
              <fieldset>
                <fieldset className="form-group">
                  <input
                    className="form-control form-control-md"
                    type="text"
                    placeholder={t("login.placeholder.username")}
                    value={username}
                    onChange={changeUsername}
                  />
                </fieldset>

                <fieldset className="form-group position-relative">
                  <input
                    className="form-control form-control-md"
                    type={showPassowrd ? "text" : "password"}
                    placeholder={t("login.placeholder.password")}
                    value={password}
                    onChange={changePassword}
                  />
                  <span
                    className={`password-icon ${showPassowrd}`}
                    onClick={(e) => setShowPassword(!showPassowrd)}
                  ></span>
                </fieldset>

                <p className="forgot-pwd text-right">
                  <Link to="/forgot">{t("login.forgotPassword")}</Link>
                </p>
                <button
                  className="login-button btn btn-md pull-md-right"
                  type="submit"
                  disabled={inProgress}
                >
                  {t("login.buttonText")}
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
