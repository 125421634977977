import React, { useState, useEffect } from "react";
import agent from "../../agent";
import { connect } from "react-redux";
import withAuthProvider, { AuthComponentProps } from "./msal/AuthProvider";
import moment from "moment";
import { toast } from "react-toastify";
import i18n from "i18next";
import {
  GET_CHAT_RECIPIENTS_LIST,
  SEND_MEETING_INVITE,
  MEETING_INVITE_SUCCESS,
} from "../../constants/actionTypes";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import SearchDropdown from "../../components/SearchDropdown";

import "react-datepicker/dist/react-datepicker.css";

const mapStateToProps = (state) => ({
  recipientsArray: state.chat.chatRecipients,
  sentMeetingInvite: state.schedule.sentMeetingInvite,
});

const mapDispatchToProps = (dispatch) => ({
  getRecipientsList: () =>
    dispatch({
      type: GET_CHAT_RECIPIENTS_LIST,
      payload: agent.Chat.getReceipients(),
    }),
  sendInvite: (data) =>
    dispatch({
      type: SEND_MEETING_INVITE,
      payload: agent.Chat.sendMessage(data),
    }),
  clearInviteSuccessState: () =>
    dispatch({ type: MEETING_INVITE_SUCCESS, payload: false }),
});

const MsalWrapper = (props) => {
  const {
    login,
    user,
    isAuthenticated,
    getCalendarEvents,
    calendarEvents,
    calendarData,
    createEvent,
    onScheduleFreeSlots,
    checkFreeSlots,
    createEventInCalendar,
  } = props;

  useEffect(() => {
    if (!isAuthenticated) {
      if (login) login();
    } else {
      // const d = new Date();
      // if(getCalendarEvents) getCalendarEvents(d.getTimezoneOffset(), d);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (checkFreeSlots) {
      const { startDate, endDate } = checkFreeSlots;
      const d = new Date();
      getCalendarEvents(startDate, endDate);
    }
  }, [checkFreeSlots]);
  useEffect(() => {
    if (calendarData && calendarData.length) {
      const scheduleItems = calendarData[0].scheduleItems;
      if (scheduleItems && scheduleItems.length < 1) {
        onScheduleFreeSlots("available");
      } else if (scheduleItems && scheduleItems.length) {
        onScheduleFreeSlots("unavailable");
      }
    }
  }, [calendarData]);
  useEffect(() => {
    if (createEventInCalendar && Object.keys(createEventInCalendar).length) {
      const {startTime, endTime, content} = createEventInCalendar;
      createEvent(startTime, endTime, content);
    }
  }, [createEventInCalendar]);
  return <div className="msal-container"></div>;
};

const MsalComponent = withAuthProvider(MsalWrapper);

const SchedulerPopup = (props) => {
  const {
    threadId,
    date,
    hour,
    minute,
    setSchedulePopupObject,
    recipientsArray,
    getRecipientsList,
    sendInvite,
    sentMeetingInvite,
    clearInviteSuccessState,
    // login,
    // user,
    // isAuthenticated,
    // getCalendarEvents,
    // calendarEvents,
  } = props;
  const { t } = useTranslation();
  const [hcpList, setHcpList] = useState([]);
  const [selectedData, setSelectedData] = useState(threadId || null);
  const [startDate, setStartDate] = useState(
    date ? new Date(date.toLocaleString()) : new Date(moment().toLocaleString())
  );
  const [startTime, setStartTime] = useState(
    date && hour && minute
      ? new Date(
          date.startOf("day").add(hour, "h").add(minute, "m").toLocaleString()
        )
      : new Date(moment().toLocaleString())
  );
  const [endTime, setEndTime] = useState(new Date(startTime));
  const [duration, setDuration] = useState(0);
  const [note, setNote] = useState("");
  const [freeSlots, setFreeSlots] = useState("");
  const [checkFreeSlots, setCheckFreeSlots] = useState({});
  const [createEventInCalendar, setCreateEventInCalendar] = useState({});

  const onScheduleFreeSlots = (data) => {
    setFreeSlots(data);
  };
  const closeSelf = () => {
    clearInviteSuccessState();
    setSchedulePopupObject(null);
  };
  const onDestroy = () => {
    setStartDate(null);
    setStartTime(null);
    setEndTime(null);
    setNote(null);
  };

  const sendMeetingInvite = () => {
    const meetingInviteMessage = `Appointment Request: at ${startTime} for ${duration} minutes.  ${note}`;
    const formData = {
      chatMessages: [
        {
          threadId: selectedData,
          messageType: 6,
          messageDirectionType: 4,
          content: meetingInviteMessage,
          attachmentUrl: "",
          createdAt: new Date().getTime(),
          udf1: duration, // duration of the meeting
          udf2: moment(startTime).utc().format("YYYY/MM/DD HH:mm:ss"), // Meeting start time
        },
      ],
    };
    const externalCalendarPostData = {
      startTime: moment(startTime).format(),
      endTime: moment(endTime).format(),
      content: meetingInviteMessage,
    };
    setCreateEventInCalendar(externalCalendarPostData);
    sendInvite(formData);
  };

  const submitSchedule = () => {
    setCheckFreeSlots({
      startDate: startTime, // duration of the meeting
      endDate: endTime,
    });
    // sendMeetingInvite();
  };

  useEffect(() => {
    if (freeSlots === "available") {
      sendMeetingInvite();
      setFreeSlots("");
    } else if (freeSlots === "unavailable") {
      toast(i18n.t("schedule.schedule_popup.schedule_conflict_external"));
      setFreeSlots("");
    }
  }, [freeSlots]);

  useEffect(() => {
    if (selectedData) {
      console.log(selectedData);
    }
  }, [selectedData]);

  useEffect(() => {
    if (recipientsArray && recipientsArray.length) {
      console.log(recipientsArray);
      const newHcpList = recipientsArray.map((item) => {
        return {
          username: `${item.hcp.fName} ${item.hcp.lName}`,
          uid: item.thread.uid, // using thread id as hcp id for the hcp dropdown alone.
        };
      });
      setHcpList(newHcpList);
    } else {
      // TODO
    }
  }, [recipientsArray]);

  useEffect(() => {
    if (startTime) {
      setEndTime(new Date(startTime));
    } else {
      setEndTime(new Date());
    }
  }, [startTime]);

  useEffect(() => {
    if (startTime && endTime) {
      const from = moment(startTime);
      const to = moment(endTime);
      const diff = to.diff(from, "minutes");
      setDuration(diff);
    } else {
      // setEndTime(new Date());
    }
  }, [startTime, endTime]);

  useEffect(() => {
    if (sentMeetingInvite) {
      closeSelf();
    }
  }, [sentMeetingInvite]);

  useEffect(() => {
    getRecipientsList();
    return () => {
      onDestroy();
    };
  }, []);

  const msalProps = {
    onScheduleFreeSlots,
    checkFreeSlots,
    createEventInCalendar,
  };

  return (
    <div className="schedule-popup-container">
      <div className="schedule-popup">
        <div className="schedule-popup-header">
          <h6 className="float-left">{t("schedule.schedule_popup.title")}</h6>
          <div className="close-button"></div>
        </div>
        <div className="schedule-popup-body">
          <MsalComponent {...msalProps} />
          <div className="form-group row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
              <label for="support_agent_id">
                {t("schedule.schedule_popup.select_hcp")}
              </label>
              <SearchDropdown
                preSelectId={threadId ? threadId : null}
                onChange={setSelectedData}
                options={hcpList}
                labelProp="username"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4  mb-2">
              <label for="support_agent_id">
                {t("schedule.schedule_popup.select_date")}
              </label>
              <br />
              <DatePicker
                className="form-control"
                selected={startDate}
                minDate={new Date()}
                maxDate={null}
                onChange={(d) => {
                  setStartDate(d);
                  setStartTime(new Date(d.toLocaleString()));
                }}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3  mb-2">
              <label for="support_agent_id">
                {t("schedule.schedule_popup.select_starttime")}
              </label>
              <DatePicker
                className="form-control"
                selected={startTime}
                // minTime={new Date()}
                // maxTime={null}
                onChange={(time) => {
                  setStartTime(time);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3  mb-2">
              <label for="support_agent_id">
                {t("schedule.schedule_popup.select_endtime")}
              </label>
              {startTime && (
                <DatePicker
                  className="form-control"
                  selected={endTime}
                  onChange={(time) => setEndTime(time)}
                  minTime={new Date(startTime)}
                  maxTime={new Date(moment(startTime).endOf("day"))}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  mb-2">
              <label>
                {t("schedule.schedule_popup.meeting_duration_text").replace(
                  "{x}",
                  duration
                )}
              </label>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  mb-2">
              <textarea
                className="meeting-note form-control"
                value={note}
                onChange={(e) => setNote(e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
        <div className="schedule-popup-footer">
          <button
            onClick={() => setSchedulePopupObject(null)}
            className="btn btn-secondary ml-4"
          >
            {t("common.cancel")}
          </button>
          <button
            className="btn btn-primary ml-4"
            onClick={() => submitSchedule()}
            disabled={!selectedData || !selectedData}
          >
            {t("common.save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerPopup);
