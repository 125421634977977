import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
import agent from "../../../agent";
import { ADD_TO_UNREAD_MESSAGES_LIST } from "../../../constants/actionTypes";
import AcceptRejectTransfer from "./AcceptRejectTransfer";
import SystemMessage from "./SystemMessage";
import TextMessage from "./TextMessage";
import PictureMessage from "./PictureMessage";
import VideoMessage from "./VideoMessage";
import DocumentMessage from "./DocumentMessage";
import PreApprovedTextMessage from "./PreApprovedTextMessage";
import MeetingInvite from "./MeetingInvite";

const mapStateToProps = (state) => ({
  token: state.common.token,
  currentUser: state.common.currentUser,
  newIncomingMessage: state.common.newMessage,
});

const mapDispatchToProps = (dispatch) => ({
  addToUnreadList: (data) =>
    dispatch({
      type: ADD_TO_UNREAD_MESSAGES_LIST,
      payload: data,
    }),
});

const Message = (props) => {
  const {
    chatThreadContainer,
    selectedRecipient,
    data,
    searchResultArray,
    addToUnreadList,
    currentUser,
    currentThreadStatus,
    currentThreadId,
    messageThread,
    updateIsReadStatusInThread,
  } = props;
  const { isRead, uid, messageDirectionType, agentId, messageType } = data;
  useEffect(() => {
    if (data && uid && data.messageDirectionType === 3 && !isRead) {
      addToUnreadList(data);
    }
  }, [isRead]);

  const textMsgProps = {
    data,
    searchResultArray,
    selectedRecipient,
    chatThreadContainer,
    updateIsReadStatusInThread,
  };
  const pictureMsgProps = {
    data,
    searchResultArray,
    selectedRecipient,
    chatThreadContainer,
    updateIsReadStatusInThread,
  };
  const videoMsgProps = {
    data,
    searchResultArray,
    selectedRecipient,
    chatThreadContainer,
    updateIsReadStatusInThread,
  };
  const meetingInviteMsgProps = {
    data,
    searchResultArray,
    selectedRecipient,
    chatThreadContainer,
    updateIsReadStatusInThread,
  };
  const preApprovedTextMsgProps = {
    data,
    searchResultArray,
    selectedRecipient,
    chatThreadContainer,
    updateIsReadStatusInThread,
  };
  const docMsgProps = {
    data,
    searchResultArray,
    selectedRecipient,
    chatThreadContainer,
    updateIsReadStatusInThread,
  };

  return (
    <React.Fragment>
      {messageDirectionType === 1 || messageDirectionType === "1" ? (
        <React.Fragment>
          {agentId &&
          currentUser &&
          agentId === currentUser.uid &&
          currentThreadStatus === 3 &&
          messageThread &&
          messageThread.length &&
          messageThread[messageThread.length - 1].uid === uid &&
          messageType &&
          messageType === 12 ? (
            <AcceptRejectTransfer
              data={data}
              currentThreadId={currentThreadId}
              currentThreadStatus={currentThreadStatus}
              searchResultArray={searchResultArray}
            />
          ) : (
            <SystemMessage
              data={data}
              currentThreadId={currentThreadId}
              currentThreadStatus={currentThreadStatus}
              searchResultArray={searchResultArray}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {messageDirectionType === 2 || messageDirectionType === "2" ? null : (
            <React.Fragment>
              {(messageType === 1 || messageType === "1") && (
                <TextMessage {...textMsgProps} />
              )}
              {(messageType === 3 ||
                messageType === "3" ||
                messageType === 17 ||
                messageType === "17") && <VideoMessage {...videoMsgProps} />}
              {(messageType === 4 || messageType === "4") && (
                <PictureMessage {...pictureMsgProps} />
              )}
              {(messageType === 6 || messageType === "6") && (
                <MeetingInvite {...meetingInviteMsgProps} />
              )}
              {(messageType === 7 || messageType === "7") && (
                <PreApprovedTextMessage {...preApprovedTextMsgProps} />
              )}
              {(messageType === 11 || messageType === "11") && (
                <DocumentMessage {...docMsgProps} />
              )}
              {(messageType === 18 ||
                messageType === "18" ||
                messageType === 19 ||
                messageType === "19") && (
                <SystemMessage
                  data={data}
                  currentThreadId={currentThreadId}
                  currentThreadStatus={currentThreadStatus}
                  searchResultArray={searchResultArray}
                />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
