import React from "react";
import { connect } from "react-redux";
//import { agent } from "superagent";
import agent from "../../agent"
import { UPDATE_TEMPLATE, GET_TEMPLATE } from "../../constants/actionTypes"

export class EmailPopup extends React.Component {
    constructor() {
        super()
        this.state = {
            showPopup: false,
            subject: '',
            message: '',
            selectedVal:null,
            pos:null,
        }
    }

    componentDidMount = () => {
        const { message, subject } = this.props.dataArray
        this.setState({ subject, message })
    }
    getPos = () => {
        var ctl = document.getElementById('EmailMessage');
        this.setState({ pos: ctl.selectionEnd })
    }
    onInputChange = (event) => {
        const name = event.target.name
        const value = event.target.value

        this.setState({ [name]: value })
    }

    submitForm = async () => {
        const { subject, message } = this.state
        const obj = {
            id: this.props.dataArray.id,
            subject,
            message
        }
        await this.props.updateTemplate(obj)
        this.props.getTemplates("")
        this.props.openPopup()
    }

    changeSelect = (e) => {
        console.log(e.target.value)
        this.setState({selectedVal:e.target.value})
        // var position = 6;
        // var output = [a.slice(0, position), b, a.slice(position)].join('');
        this.setState({message:[this.state.message.slice(0, this.state.pos), ` {{${e.target.value}}} `, this.state.message.slice(this.state.pos)].join('')})
    }

    render() {
        const { showPopup, dataArray } = this.props
        const { message, subject } = this.state
        let metaDataArr = dataArray.metaData.split(",");
        console.log(metaDataArr)
        return (
            <React.Fragment>
                {showPopup && (
                    <div className="email-form-popup">
                        <div className="email-form-container">
                            <div className="email-popup-header">
                                <h5 className="updated">{dataArray.emailtype}</h5>
                            </div>
                            <form>
                                <fieldset>
                                    <div className="form-group row">
                                        <div className="col-12 mb-2">
                                            <label for="name">Subject</label>
                                            <input
                                                name="subject"
                                                className="form-control form-control-md"
                                                type="text"
                                                placeholder="Subject"
                                                value={subject}
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-12 mb-2">
                                            <label for="access_token">Message</label>
                                            <textarea
                                                id="EmailMessage"
                                                name="message"
                                                className="form-control form-control-md min-height"
                                                type="text"
                                                value={message}
                                                onChange={this.onInputChange}
                                                onBlur={this.getPos}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-12 mb-2">
                                            <select className="metaSelect" onChange={this.changeSelect} value="Meta-Data">
                                                <option selected value="Meta-Data">Meta-Datas</option>
                                                {metaDataArr.length > 0 && metaDataArr.map((data, ind2) => {
                                                    return <option value={data}>{data}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="email-popup-footer">
                                    <button onClick={this.props.openPopup} className="btn btn-secondary ml-4" > Cancel </button>
                                    <button className="btn btn-primary ml-4"
                                        onClick={this.submitForm}
                                    //disabled={inProgress}
                                    > Save </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }


}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    updateTemplate: (data) => {
        dispatch({ type: UPDATE_TEMPLATE, payload: agent.Orgs.updateTemplate(data) })
    },
    getTemplates: (data) => {
        dispatch({ type: GET_TEMPLATE, payload: agent.Orgs.getTemplates(data) })
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(EmailPopup)    