import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  ARTICLE_SUBMITTED,
  SETTINGS_SAVED,
  LOGIN,
  REGISTER,
  DELETE_ARTICLE,
  ARTICLE_PAGE_UNLOADED,
  EDITOR_PAGE_UNLOADED,
  HOME_PAGE_UNLOADED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED,
  SETTINGS_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  CURRENT_VIEW,
  SHOW_CREATE_EDIT_USER_POPUP,
  SHOW_CREATE_EDIT_ORG_POPUP,
  GET_ROLES_LIST,
  GET_ORGS_LIST,
  GET_USERS_LIST,
  CONFIRM_BOX_OPEN,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM,
  GET_HCP_USERS_LIST,
  SEND_TEXT_MESSAGE,
  GET_CHAT_RECIPIENTS_LIST,
  ADD_MESSAGE,
  GET_TEMPLATE,
  LATEST_MESSAGES_LIST,
  CHAT_THREAD_UPDATED,
  ADD_TO_UNREAD_MESSAGES_LIST,
  UPDATE_AUTH_TOKEN,
  GET_HCP_BY_AGENT_ID,
  DASHBOARD_DATA_GET,
} from "../constants/actionTypes";

const defaultState = {
  appName: "Consilium",
  token: null,
  viewChangeCounter: 0,
  latestMessages: {},
  unReadMessages: {},
  dashboardData: [],
  loginSuccess: false,
};

const processLatestMessagesList = (state, action) => {
  const updatedList = state.latestMessages;
  updatedList[action.payload.threadId] = action.payload;
  return {
    ...state,
    latestMessages: { ...updatedList },
  };
};

const addToUnreadMessagesList = (state, action) => {
  const updatedList = state.unReadMessages;
  if (
    updatedList[action.payload.threadId] &&
    Array.isArray(updatedList[action.payload.threadId]) &&
    updatedList[action.payload.threadId].length
  ) {
    updatedList[action.payload.threadId].push(action.payload);
  } else {
    updatedList[action.payload.threadId] = [action.payload];
  }
  return {
    ...state,
    unReadMessages: { ...updatedList },
  };
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONFIRM_BOX_OPEN:
      return {
        ...state,
        isConfirmBoxOpen: action.payload,
      };
    case SHOW_CREATE_EDIT_ORG_POPUP:
      return {
        ...state,
        createEditOrgPopup: action.payload,
      };
    case SHOW_CREATE_EDIT_USER_POPUP:
      return {
        ...state,
        createEditUserPopup: action.payload,
      };
    case CURRENT_VIEW:
      return {
        ...state,
        viewName: action.payload.name,
        viewId: action.payload.id,
      };
    case APP_LOAD:
      return {
        ...state,
        appLoaded: true,
        currentUser:
          action.payload && action.payload.data && action.payload.data.user
            ? action.payload.data.user
            : null,
        currentOrg:
          action.payload && action.payload.data && action.payload.data.org
            ? action.payload.data.org
            : null,
        currentSettings:
          action.payload && action.payload.data && action.payload.data.settings
            ? action.payload.data.settings
            : null,
      };
    case REDIRECT:
      return { ...state, redirectTo: "/" };
    case LOGOUT:
      return { ...state, redirectTo: "/", token: null, currentUser: null, loginSuccess: false };
    case ARTICLE_SUBMITTED:
      const redirectUrl = `/article/${action.payload.article.slug}`;
      return { ...state, redirectTo: redirectUrl };
    case SETTINGS_SAVED:
      return {
        ...state,
        redirectTo: action.error ? null : "/",
        // currentUser: action.error ? null : action.payload.data.user,
      };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : "/",
        loginSuccess: action.error ? false : true,
        token: action.error ? null : action.payload.data.token,
        currentUser: action.error ? null : action.payload.data.user,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        // redirectTo: action.error ? null : "/",
        resetSuccess: action.error ? null : action.payload.isSuccess,
      };
    case RESET_PASSWORD_CONFIRM:
      return {
        ...state,
        // redirectTo: action.error ? null : "/",
        resetPasswordSuccess: action.error ? null : action.payload.isSuccess,
      };
    case DELETE_ARTICLE:
      return { ...state, redirectTo: "/" };
    case ARTICLE_PAGE_UNLOADED:
    case EDITOR_PAGE_UNLOADED:
    case HOME_PAGE_UNLOADED:
    case PROFILE_PAGE_UNLOADED:
    case PROFILE_FAVORITES_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    case GET_ROLES_LIST:
      return {
        ...state,
        roles: action.payload && action.payload.data ? action.payload.data : [],
      };
    case GET_ORGS_LIST:
      return {
        ...state,
        orgs: action.payload && action.payload.data ? action.payload.data : [],
      };
    case GET_USERS_LIST:
      return {
        ...state,
        users:
          action.payload && action.payload.data && action.payload.data.users
            ? [...action.payload.data.users]
            : [],
      };
    case GET_HCP_USERS_LIST:
      return {
        ...state,
        hcpUsers:
          action.payload && action.payload.data && action.payload.data.users
            ? action.payload.data.users
            : [],
      };
    case GET_CHAT_RECIPIENTS_LIST:
      return {
        ...state,
        chatRecipients:
          action.payload && action.payload.data ? action.payload.data : [],
      };
    case SEND_TEXT_MESSAGE:
      return {
        ...state,
        messageSent: !action.error && action.payload ? action.payload : false,
      };
    case ADD_MESSAGE:
      return {
        ...state,
        newMessage: action.payload,
      };
    case LATEST_MESSAGES_LIST:
      return processLatestMessagesList(state, action);
    case CHAT_THREAD_UPDATED:
      return {
        ...state,
        threadUpdated: action.payload,
      };
    case ADD_TO_UNREAD_MESSAGES_LIST:
      return addToUnreadMessagesList(state, action);
    case ADD_MESSAGE:
      return {
        ...state,
        newMessage: action.payload,
      };
    case UPDATE_AUTH_TOKEN:
      return {
        ...state,
        authToken:
          action.payload && action.payload.data && action.payload.data.user
            ? action.payload.data.user
            : [],
      };
    case GET_HCP_BY_AGENT_ID:
      return {
        ...state,
        userList:
          action.payload && action.payload.data && action.payload.data.users
            ? action.payload.data.users
            : [],
      };
    case GET_TEMPLATE:
      console.log("Payload >>>>", action.payload);
      return {
        ...state,
        userTemplate: action.payload,
      };
    case DASHBOARD_DATA_GET:
      return {
        ...state,
        dashboardData:
          action.payload && action.payload.data && action.payload.data.length
            ? action.payload.data[0]
            : [],
      };
    default:
      return state;
  }
};
