import React from "react";
import { connect } from "react-redux";
import EmailPopup from './EmailPopup'
import agent from "../../agent"
import { GET_TEMPLATE } from "../../constants/actionTypes"

const textData = [1, 2, 3, 4, 5]
export class MainView extends React.Component {
    constructor() {
        super()
        this.state = {
            showPopup: false,
            dataArray: []
        }
    }

    componentDidMount = () => {
        this.props.getTemplates("")
    }

    openPopup = (item) => {
        console.log(item)
        this.setState({ showPopup: !this.state.showPopup, dataArray: item })
    }

    render() {
        const { showPopup } = this.state
        return (
            <React.Fragment>
                <div className="mainview-container">
                    <div className="main-body-container">
                        <div className="contents-body">
                            <div className="contents-main-section">
                                <div className="text-section">
                                    <div className="grid-container">
                                        <div className="grid-header">
                                            <div className="text">Message</div>
                                            <div className="topic">Subject</div>
                                            <div className="emailtype">Email Type</div>
                                            {/* <div className="metadata">MetaData</div> */}
                                            <div className="Edit">Edit</div>
                                        </div>
                                        {this.props.userTemplate && this.props.userTemplate.data.length > 0 ?
                                            this.props.userTemplate.data.map((item, ind) => {
                                                let metaDataArr = item.metaData.split(",");
                                                console.log(metaDataArr)
                                                return (
                                                    <div className="grid-item" key={ind}>
                                                        <div className="text">{item.message}</div>
                                                        <div className="topic">{item.subject}</div>
                                                        <div className="updated">{item.emailtype}</div>
                                                        {/* <select className="metaSelect">
                                                            <option selected value="Meta-Data">Datas</option>
                                                            {metaDataArr.length > 0 && metaDataArr.map((data, ind2) => {
                                                                return <option>{data}</option>
                                                            })}
                                                        </select> */}
                                                        <div className="share" onClick={() => this.openPopup(item)}><i class="fa fa-pencil-square-o pointer f-20" aria-hidden="true"></i></div>
                                                    </div>
                                                );
                                            }) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showPopup ? <EmailPopup
                    showPopup={showPopup}
                    openPopup={this.openPopup}
                    dataArray={this.state.dataArray}
                /> : ''}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    ...state.articleList,
    userTemplate: state.common.userTemplate,

});

const mapDispatchToProps = (dispatch) => ({
    getTemplates: (data) => {
        dispatch({ type: GET_TEMPLATE, payload: agent.Orgs.getTemplates(data) })
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
