import { Link } from "react-router-dom";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { LOGOUT, CURRENT_VIEW } from "../constants/actionTypes";
import agent from "../agent";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => {
    dispatch({ type: LOGOUT , payload: agent.Auth.logout()})
  },
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
});

const SignOut = (props) => {
  const { t } = useTranslation();
  const { onViewChange, viewName, onSignOut } = props;

  return (
    <div className="sign-out-container">
      <a onClick={() => onSignOut()}>
        <div>{t(props.name, "Sign Out")}</div>
      </a>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
