import { Link } from "react-router-dom";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import agent from "../../../agent";
import ChatThread from "./ChatThread";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
});

const mapDispatchToProps = (dispatch) => ({});

const ChatThreadsView = (props) => {
  const { recipientsArray, selectedRecipient, onRecipientSelect } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {recipientsArray &&
        recipientsArray.length &&
        recipientsArray.map((item) => {
          return (
            <ChatThread
              key={item.hcp.uid}
              className={
                selectedRecipient &&
                selectedRecipient.hcp &&
                selectedRecipient.hcp.uid &&
                selectedRecipient.hcp.uid === item.hcp.uid
                  ? "visible"
                  : "hidden"
              }
              data={item}
              selectedRecipient={selectedRecipient}
              onRecipientSelect={onRecipientSelect}
            />
          );
        })}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatThreadsView);
