import { Link } from "react-router-dom";
import ListErrors from "./ListErrors";
import React from "react";
import agent from "../agent";
import { connect } from "react-redux";
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  LOGIN_PAGE_UNLOADED,
} from "../constants/actionTypes";

import "./styles/Login.scss";

const mapStateToProps = (state) => ({ ...state.auth });

const mapDispatchToProps = (dispatch) => ({
  onChangeEmail: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "email", value }),
  onChangePassword: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "password", value }),
  onSubmit: (email, password) =>
    dispatch({ type: LOGIN, payload: agent.Auth.login(email, password) }),
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
});

class PreLogin extends React.Component {
  constructor() {
    super();
    this.changeEmail = (ev) => this.props.onChangeEmail(ev.target.value);
    this.changePassword = (ev) => this.props.onChangePassword(ev.target.value);
    this.submitForm = (email, password) => (ev) => {
      ev.preventDefault();
      this.props.onSubmit(email, password);
    };
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const email = this.props.email;
    const password = this.props.password;
    return (
      <div className="pre-auth-page">
        <div className="brand-name">
          <div className="logo"></div>
          <h4 className="text-xs-center">Consilium</h4>
        </div>
        <div className="container page">
          <div className="main-block">
            <div className="demo-header offset-md-1">
              <h1>Demo Control</h1>
            </div>
            <div className="row">
              <div className="col-md-5 offset-md-1">
                <Link to="/login" className="pre-login-nav-link">
                  <button
                    className="pre-login-button btn btn-md btn-outline-secondary pull-md-right"
                    type="submit"
                    disabled={this.props.inProgress}
                  >
                    English - Login as Ai Sugimura - Sales Rep
                  </button>
                </Link>
                <button
                  className="pre-login-button btn btn-md btn-outline-secondary pull-md-right"
                  type="submit"
                  disabled={this.props.inProgress}
                >
                  English - Login as Ken Yoshida - MSL
                </button>
                <button
                  className="pre-login-button btn btn-md btn-outline-secondary pull-md-right"
                  type="submit"
                  disabled={this.props.inProgress}
                >
                  English - Rep Check-in w/ HCP w/ a document transfer
                </button>
                <button
                  className="pre-login-button btn btn-md btn-outline-secondary pull-md-right"
                  type="submit"
                  disabled={this.props.inProgress}
                >
                  English - MSL Initiated Calendar Invite
                </button>
                <button
                  className="pre-login-button btn btn-md btn-outline-secondary pull-md-right"
                  type="submit"
                  disabled={this.props.inProgress}
                >
                  English - Confirm HCP Identity
                </button>
              </div>
              <div className="col-md-5">
                <button
                  className="pre-login-button btn btn-md btn-outline-secondary pull-md-right"
                  type="submit"
                  disabled={this.props.inProgress}
                >
                  Japanese - Login as Ai Sugimura - Sales Rep
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreLogin);
