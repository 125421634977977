import agent from "../agent";
import {
  SEND_MESSAGE,
  ADD_MESSAGE,
  GET_CHAT_RECIPIENTS_LIST,
  GET_CHAT_THREADS,
  GET_TRANSFERABLE_PEERS,
  TRANSFER_CONVERSATION,
  SHOW_TRANSFER_SUCCESS_POPUP,
  SHOW_TRANSFER_CONVERSATION_POPUP,
  POST_IS_READ_STATUS,
  UPDATE_IS_READ_STATUS,
  ON_ADVERSE_EFFECT_UPDATE,
  SHOW_ADVERSE_EFFECT_POPUP,
  ON_THREAD_ACTIVE_STATUS_CHANGE,
} from "../constants/actionTypes";
import { notifySuccess, notifyError } from "./notification";

const chatMiddleware = (store) => (next) => (action) => {
  const state = store.getState();

  if (action.type === GET_CHAT_RECIPIENTS_LIST) {
    if (action.error) {
      console.log("error");
    } else if (action.payload) {
      const { data } = action.payload;
      console.log(action.payload);
      let threadIds = null;
      if (data && data.length) {
        threadIds = data.map((item) => item.thread.uid);
      }
      if (threadIds && threadIds.length) {
        store.dispatch({
          type: GET_CHAT_THREADS,
          payload: agent.Chat.getMessages({ threadIds: threadIds }),
        });
        store.dispatch({
          type: GET_TRANSFERABLE_PEERS,
          payload: agent.Chat.getTransferablePeers(),
        });
      }
    }
  } else if (action.type === GET_CHAT_THREADS) {
    if (action.error) {
      console.log("error");
    } else if (action.payload) {
      console.log(action.payload);
    }
  } else if (action.type === SEND_MESSAGE) {
    if (action.error) {
      console.log("error");
    } else if (action.payload) {
      console.log(action.payload);
      const sentMessage =
        action.payload &&
        action.payload &&
        action.payload.data &&
        action.payload.data[0] &&
        action.payload.data[0].insertedMessage
          ? action.payload.data[0].insertedMessage
          : null;
      if (sentMessage) {
        store.dispatch({
          type: ADD_MESSAGE,
          payload: sentMessage,
        });
        store.dispatch({
          type: GET_CHAT_RECIPIENTS_LIST,
          payload: agent.Chat.getReceipients(),
        });
      }
    }
  } else if (action.type === TRANSFER_CONVERSATION){
    if (action.error) {
      console.log("error");
    } else if (action.payload) {
      console.log(action.payload);
      // if (action.payload && action.payload.response && action.payload.response.data && action.payload.transferData) {
        store.dispatch({
          type: SHOW_TRANSFER_CONVERSATION_POPUP,
          payload: false,
        });
        store.dispatch({
          type: SHOW_TRANSFER_SUCCESS_POPUP,
          payload: action.payload.transferData,
        });
      // }
    }
  } else if (action.type === POST_IS_READ_STATUS){
    if (action.error) {
      console.log("error");
    } else if (action.payload) {
      console.log(action.payload);
      // if (action.payload && action.payload.response && action.payload.response.data && action.payload.transferData) {
        store.dispatch({
          type: UPDATE_IS_READ_STATUS,
          payload: action && action.payload ? action.payload : null,
        });
      // }
    }
  } else if (action.type === ON_ADVERSE_EFFECT_UPDATE){
    if (action.error) {
      notifyError(action);
    } else if (action.payload) {
      notifySuccess(action);
        store.dispatch({
          type: SHOW_ADVERSE_EFFECT_POPUP,
          payload: false,
        });
    }
  } else if (action.type === ON_THREAD_ACTIVE_STATUS_CHANGE){
    if (action.error) {
      notifyError(action);
    } else if (action.payload) {
      notifySuccess(action);
        store.dispatch({
          type: GET_CHAT_RECIPIENTS_LIST,
          payload: agent.Chat.getReceipients(),
        });
    }
  }

  next(action);
};

export default chatMiddleware;
