import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import agent from "../../../agent";
import { GO_TO_CHAT_RECIPIENT } from "../../../constants/actionTypes";
import {ReactComponent as BellIcon} from "../../../assets/icon_bell_circle.svg";
import {ReactComponent as BellIconWithDot} from "../../../assets/icon_bell_circle_dot.svg";

const mapStateToProps = (state) => ({
  chatThreadsClone: state.chat.chatThreadsClone,
  hcpUsers: state.common.hcpUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getMessages: (ids) => {
    agent.Chat.getMessages(ids);
  },
  goToChat: (data) => dispatch({ type: GO_TO_CHAT_RECIPIENT, payload: data }),
});

const BellNotifyNewMessages = (props) => {
  const { chatThreadsClone, hcpUsers, goToChat } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const balloon = useRef();
  const bell = useRef();
  const [chatList, setChatList] = useState(null);
  const [listVisible, setListVisible] = useState(false);

  const processData = (id) => {
    let name;
    if (id && hcpUsers && hcpUsers.length) {
      const found = hcpUsers.filter((item) => item.uid === id);
      if (found && found.length) {
        name = `${found[0].fName} ${found[0].lName}`;
      }
    }
    return name;
  };

  const updateStatus = () => {
    if (chatThreadsClone && chatThreadsClone.length) {
      const list = chatThreadsClone.filter((li) => {
        const unreadMsgs = li.chats.filter((item) => {
          return (
            item.messageDirectionType === 3 &&
            item.messageType !== 18 &&
            item.messageType !== 19 &&
            !item.isRead
          );
        });

        return unreadMsgs && unreadMsgs.length > 0;
      });
      if (list && list.length) {
        setChatList(list);
      } else {
        setChatList([]);
      }
    } else {
      setChatList([]);
    }
  };

  const toggleList = () => {
    if (listVisible) {
      hideList();
    } else {
      showList();
    }
  };

  const showList = () => {
    setListVisible(true);
  };

  const hideList = () => {
    setListVisible(false);
  };

  const naviagteToChat = (chatId) => {
    hideList();
    goToChat({ hcp_id: chatId });
    history.push("/connect");
  };

  const handleClickOutside = (event) => {
    if (
      balloon.current &&
      !balloon.current.contains(event.target) &&
      bell.current &&
      !bell.current.contains(event.target)
    ) {
      setListVisible(false);
    }
  };

  useEffect(() => {
    updateStatus();
  }, [chatThreadsClone]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      {chatList && chatList.length ? (
        <React.Fragment>
          <div ref={bell} onClick={toggleList} className="nav-link">
            {/* <div className="icon icon-bell orange-dot">
              <div className="icon-inset orange-dot"></div>
            </div> */}
            <div className="d-flex">
              <BellIconWithDot />
            </div>
          </div>
          {listVisible && (
            <div ref={balloon} className="new-msg-from-list">
              <h6 className="text-center">{t("header.new_msg_from")}</h6>
              {chatList.map((item) => {
                return (
                  <div
                    className="list-item"
                    onClick={() => naviagteToChat(item.chats[0].hcpId)}
                  >
                    {processData(item.chats[0].hcpId)}
                  </div>
                );
              })}
            </div>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="nav-link">
            {/* <div className="icon icon-bell"></div> */}
            <div className="d-flex">
              <BellIcon />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BellNotifyNewMessages);
