export const SIDE_MENU = {
  PRIMARY_MENU: [
    {
      id: "1",
      name: "sideMenu.dashboard", // translation key from translations.json
      link: "/dashboard",
      icon: "dashboard", // icon class name
      icon_secondary: "",
    },
    {
      id: "2",
      name: "sideMenu.connect",
      link: "/connect",
      icon: "connect",
      icon_secondary: "down",
      accessor: [3, 4],
      submenu: [
        {
          id: "2_1",
          name: "sideMenu.scheduling",
          link: "/scheduling",
          icon: "calendar",
          icon_secondary: "",
        },
      ],
    },
    {
      id: "3",
      name: "sideMenu.content",
      link: "/content",
      icon: "content",
      icon_secondary: "",
      accessor: [2, 3, 4],
    },
    {
      id: "4",
      name: "sideMenu.invite",
      link: "/invite",
      icon: "invite",
      icon_secondary: "",
      accessor: [3, 4],
    },
    {
      id: "5",
      name: "sideMenu.measure",
      link: "/measure",
      icon: "measure",
      icon_secondary: "",
    },
    {
      id: "10",
      name: "sideMenu.email_templates",
      link: "/emailTemplate",
      icon: "content",
      icon_secondary: "",
      accessor: [1, 2],
    },
  ],
  SECONDARY_MENU: [
    {
      id: "7",
      name: "sideMenu.organizations",
      link: "/organizations",
      icon: "organizations",
      icon_secondary: "",
      accessor: [1]
    },
    {
      id: "6",
      name: "sideMenu.users",
      link: "/users",
      icon: "users",
      icon_secondary: "",
      accessor: [1, 2] // User roles allowed to access this menu item
    },
    {
      id: "8",
      name: "sideMenu.settings",
      link: "/settings",
      icon: "settings",
      icon_secondary: "",
    },
    {
      id: "9",
      name: "sideMenu.support",
      link: "/support",
      icon: "support",
      icon_secondary: "",
    }
  ],
};
