export const APP_LOAD = "APP_LOAD";
export const REDIRECT = "REDIRECT";
export const ARTICLE_SUBMITTED = "ARTICLE_SUBMITTED";
export const SETTINGS_SAVED = "SETTINGS_SAVED";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const SETTINGS_PAGE_UNLOADED = "SETTINGS_PAGE_UNLOADED";
export const HOME_PAGE_LOADED = "HOME_PAGE_LOADED";
export const HOME_PAGE_UNLOADED = "HOME_PAGE_UNLOADED";
export const ARTICLE_PAGE_LOADED = "ARTICLE_PAGE_LOADED";
export const ARTICLE_PAGE_UNLOADED = "ARTICLE_PAGE_UNLOADED";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const ARTICLE_FAVORITED = "ARTICLE_FAVORITED";
export const ARTICLE_UNFAVORITED = "ARTICLE_UNFAVORITED";
export const SET_PAGE = "SET_PAGE";
export const APPLY_TAG_FILTER = "APPLY_TAG_FILTER";
export const CHANGE_TAB = "CHANGE_TAB";
export const PROFILE_PAGE_LOADED = "PROFILE_PAGE_LOADED";
export const PROFILE_PAGE_UNLOADED = "PROFILE_PAGE_UNLOADED";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const LOGIN_PAGE_UNLOADED = "LOGIN_PAGE_UNLOADED";
export const REGISTER_PAGE_UNLOADED = "REGISTER_PAGE_UNLOADED";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const EDITOR_PAGE_LOADED = "EDITOR_PAGE_LOADED";
export const EDITOR_PAGE_UNLOADED = "EDITOR_PAGE_UNLOADED";
export const ADD_TAG = "ADD_TAG";
export const REMOVE_TAG = "REMOVE_TAG";
export const UPDATE_FIELD_AUTH = "UPDATE_FIELD_AUTH";
export const UPDATE_FIELD_EDITOR = "UPDATE_FIELD_EDITOR";
export const FOLLOW_USER = "FOLLOW_USER";
export const UNFOLLOW_USER = "UNFOLLOW_USER";
export const PROFILE_FAVORITES_PAGE_UNLOADED =
  "PROFILE_FAVORITES_PAGE_UNLOADED";
export const PROFILE_FAVORITES_PAGE_LOADED = "PROFILE_FAVORITES_PAGE_LOADED";
export const CURRENT_VIEW = "CURRENT_VIEW";
export const SHOW_CREATE_EDIT_USER_POPUP = "SHOW_CREATE_EDIT_USER_POPUP";
export const GET_ROLES_LIST = "GET_ROLES_LIST";
export const GET_ORGS_LIST = "GET_ORGS_LIST";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_HCP_USERS_LIST = "GET_HCP_USERS_LIST";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SHOW_CREATE_EDIT_ORG_POPUP = "SHOW_CREATE_EDIT_ORG_POPUP";
export const CREATE_ORG = "CREATE_ORG";
export const UPDATE_ORG = "UPDATE_ORG";
export const DELETE_USER = "DELETE_USER";
export const DELETE_ORG = "DELETE_ORG";
export const CONFIRM_BOX_OPEN = "CONFIRM_BOX_OPEN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const OPEN_WEBSOCKET = "OPEN_WEBSOCKET";
export const UPDATE_SOCKET_OBJECT = "UPDATE_SOCKET_OBJECT";
export const INCREMENT_COUNTER = "INCREMENT_COUNTER";
export const SEND_TEXT_MESSAGE = "SEND_TEXT_MESSAGE";
export const GET_CHAT_RECIPIENTS_LIST = "GET_CHAT_RECIPIENTS_LIST";
export const GET_CHAT_THREADS = "GET_CHAT_THREADS";
export const LATEST_MESSAGES_LIST = "LATEST_MESSAGES_LIST";
export const CHAT_THREAD_UPDATED = "CHAT_THREAD_UPDATED";
export const ADD_TO_UNREAD_MESSAGES_LIST = "ADD_TO_UNREAD_MESSAGES_LIST";
export const SHOW_TRANSFER_CONVERSATION_POPUP =
  "SHOW_TRANSFER_CONVERSATION_POPUP";
export const GET_TRANSFERABLE_PEERS = "GET_TRANSFERABLE_PEERS";
export const TRANSFER_CONVERSATION = "TRANSFER_CONVERSATION";
export const SHOW_TRANSFER_SUCCESS_POPUP = "SHOW_TRANSFER_SUCCESS_POPUP";
export const ACCEPT_TRANSFER = "ACCEPT_TRANSFER";
export const REJECT_TRANSFER = "REJECT_TRANSFER";
export const SEND_TEST_MESSAGE = "SEND_TEST_MESSAGE";
export const SEND_DOCUMENT_ATTACHMENT = "SEND_DOCUMENT_ATTACHMENT";
export const GET_DOCUMENT_ATTACHMENT = "GET_DOCUMENT_ATTACHMENT";
export const GET_VIDEO_ATTACHMENT = "GET_VIDEO_ATTACHMENT";
export const GET_MESSAGE_ATTACHMENT = "GET_MESSAGE_ATTACHMENT";
export const SHOW_ATTACHMENT = "SHOW_ATTACHMENT";
export const SEND_VIDEO_ATTACHMENT = "SEND_VIDEO_ATTACHMENT";
export const SEND_TEXT_ATTACHMENT = "SEND_TEXT_ATTACHMENT";
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const RELOAD_NEW_ATTACHMENT = "RELOAD_NEW_ATTACHMENT";
export const POST_IS_READ_STATUS = "POST_IS_READ_STATUS";
export const UPDATE_IS_READ_STATUS = "UPDATE_IS_READ_STATUS";
export const CLONE_CHAT_THREAD = "CLONE_CHAT_THREAD";
export const SHARE_DATA = "SHARE_DATA";
export const SHARE_CONTENT_TO_RECIPIENT = "SHARE_CONTENT_TO_RECIPIENT";
export const SEARCH_CHAT_RECIPIENTS = "SEARCH_CHAT_RECIPIENTS";
export const SEND_MEETING_INVITE = "SEND_MEETING_INVITE";
export const MEETING_INVITE_SUCCESS = "MEETING_INVITE_SUCCESS";
export const GET_APPOINTMENTS_LIST = "GET_APPOINTMENTS_LIST";
export const GO_TO_CHAT_RECIPIENT = "GO_TO_CHAT_RECIPIENT";
export const UPDATE_AUTH_TOKEN = "UPDATE_AUTH_TOKEN";
export const GET_HCP_BY_AGENT_ID = "GET_HCP_BY_AGENT_ID";
export const DASHBOARD_DATA_GET = "DASHBOARD_DATA_GET";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const RESET_PASSWORD_CONFIRM = "RESET_PASSWORD_CONFIRM";
export const HCP_CHAT_WITH_SALES = "HCP_CHAT_WITH_SALES";
export const HCP_CHAT_WITH_MSL_USER_LEVEL = "HCP_CHAT_WITH_MSL_USER_LEVEL";
export const GET_CONTENT_ANALYTICS = "GET_CONTENT_ANALYTICS";
export const NUMBER_OF_HCP_ACROSS_ORG = "NUMBER_OF_HCP_ACROSS_ORG";
export const NUMBER_OF_CONVERSION_TRANSFERS = "NUMBER_OF_CONVERSION_TRANSFERS";
export const NUMBER_OF_USERS_IN_ORG = "NUMBER_OF_USERS_IN_ORG";
export const NUMBER_OF_ACTIVE_HCP = "NUMBER_OF_ACTIVE_HCP";
export const SHOW_ADVERSE_EFFECT_POPUP = "SHOW_ADVERSE_EFFECT_POPUP";
export const ON_ADVERSE_EFFECT_GET = "ON_ADVERSE_EFFECT_GET";
export const ON_ADVERSE_EFFECT_UPDATE = "ON_ADVERSE_EFFECT_UPDATE";
export const TOTAL_NUMBER_OF_ORGS = "TOTAL_NUMBER_OF_ORGS";
export const TOTAL_NUMBER_OF_HCPS = "TOTAL_NUMBER_OF_HCPS";
export const TOTAL_NUMBER_OF_SALES = "TOTAL_NUMBER_OF_SALES";
export const TOTAL_NUMBER_OF_MSL = "TOTAL_NUMBER_OF_MSL";
export const ON_THREAD_ACTIVE_STATUS_CHANGE = 'ON_THREAD_ACTIVE_STATUS_CHANGE';
