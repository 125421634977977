import { Link } from "react-router-dom";
import ListErrors from "./ListErrors";
import React, { useEffect, useState } from "react";
import agent from "../agent";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {ReactComponent as Logo} from "../assets/logo_black.svg";
import { RESET_PASSWORD_CONFIRM } from "../constants/actionTypes";
import LanguageSelector from "./LanguageSelector";

import "./styles/Forgot.scss";

const mapStateToProps = (state) => ({
  ...state.auth,
  resetPasswordSuccess: state.common.resetPasswordSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  confirmChangePassword: (userid, resetToken, pass) =>
    dispatch({
      type: RESET_PASSWORD_CONFIRM,
      payload: agent.Auth.confirmChangePassword(userid, resetToken, pass),
    }),
});

const ResetPassword = (props) => {
  const {
    errors,
    onSubmit,
    onUnload,
    inProgress,
    resetPasswordSuccess,
    confirmChangePassword,
  } = props;

  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [passLowerCase, setPassLowerCase] = useState(false);
  const [passUpperCase, setPassUpperCase] = useState(false);
  const [passNumber, setPassNumber] = useState(false);
  const [passSymbol, setPassSymbol] = useState(false);
  const [passLength, setPassLength] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const passwordValidate = (ev) => {
    let sequence = true;
    let s = [...ev.target.value];
    for (let i in s) {
      if (+s[+i + 1] == +s[i] + 1 && +s[+i + 2] == +s[i] + 2) {
        sequence = false;
      }
    }
    if (ev.target.value.match(/[a-z]/g)) {
      setPassLowerCase(true);
    } else {
      setPassLowerCase(false);
    }
    if (ev.target.value.match(/[A-Z]/g)) {
      setPassUpperCase(true)
    } else {
      setPassUpperCase(false)
    }
    if (ev.target.value.match(/[0-9]/g) && ev.target.value.match(/[0-9]/g) && sequence) {
      setPassNumber(true)
    } else {
      setPassNumber(false)
    }
    if (ev.target.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)) {
      setPassSymbol(true)
    } else {
      setPassSymbol(false)
    }
    if (ev.target.value.length > 7 && ev.target.value.length < 21) {
      setPassLength(true)
    } else {
      setPassLength(false)
    }
    setPassword(ev.target.value)
  }

  useEffect(() => {
    if (passUpperCase && passLowerCase && passLength && passNumber && passSymbol && password === repeatPassword) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }, [passLowerCase, passUpperCase, passNumber, passSymbol, passLength, disableSubmit])
  const submitForm = () => {
    if (resetToken && resetToken.length && password && password.length) {
      confirmChangePassword("", resetToken, password);
    }
  };

  useEffect(() => {
    const reset_token = window.location.pathname.split("/reset/")[1];
    if (reset_token && reset_token.length) {
      setResetToken(reset_token);
    }
    return () => {
      if (onUnload) onUnload();
    };
  }, [password, repeatPassword]);
  return (
    <div className="forgot auth-page">
      <div className="container page">
        <LanguageSelector />
        <div className="row">
          <div className="col-md-12">
            <div className="brand-name">
              <Logo style={{ width: "100%" }} />
            </div>

            <ListErrors errors={errors} />

            <div>
              <h5>{t("login.resetPassword.resetTitle")}</h5>
              <fieldset className="reset-form">
                {!resetPasswordSuccess && (
                  <React.Fragment>
                    <fieldset className="form-group">
                      <input
                        className="form-control form-control-md"
                        type="password"
                        placeholder={t("login.placeholder.password")}
                        value={password}
                        onFocus={() => setErrorMessage(true)}
                        onBlur={() => setErrorMessage(false)}
                        onChange={(ev) => passwordValidate(ev)}
                      />
                    </fieldset>
                    {errorMessage && <div className="row mb-2 px-3">
                      <div className="col-6 mb-12"><span className={`${!passLowerCase ? "false" : "true"}`}></span><span>Lowercase</span></div>
                      <div className="col-6 mb-12"><span className={`${!passUpperCase ? "false" : "true"}`}> </span><span>Uppercase</span></div>
                      <div className="col-6 mb-12"><span className={`${!passSymbol ? "false" : "true"}`}> </span><span>Symbol</span></div>
                      <div className="col-6 mb-12"><span className={`${!passLength ? "false" : "true"}`}> </span><span>Length (8-20)</span></div>
                      <div className="col-12 mb-12"><span className={`${!passNumber ? "false" : "true"}`}></span><span>Number (No sequential numbers)</span></div>
                    </div>}
                    <fieldset className="form-group">
                      <input
                        className="form-control form-control-md"
                        type="password"
                        placeholder={t("login.placeholder.repeat_password")}
                        value={repeatPassword}
                        onChange={(ev) => setRepeatPassword(ev.target.value)}
                      />
                    </fieldset>
                    {password &&
                      password.length && repeatPassword && repeatPassword.length &&
                      <div className="col-12 mb-2">
                        {repeatPassword !== password ? 
                        <><span className="false"></span>
                        <span>{t("common.passwordMatchText")}</span></> :
                        <><span className="true"></span>
                        <span>{t("common.passwordMatchSuccess")}</span></>}
                      </div>
                    }
                  </React.Fragment>
                )}
                {resetPasswordSuccess ? (
                  <h6>
                    {t("login.resetPassword.resetPasswordSuccessMessage")}
                  </h6>
                ) : (
                    <button
                      className="login-button btn btn-md pull-md-right"
                      onClick={() => submitForm()}
                      disabled={disableSubmit ? !(repeatPassword === password) : disableSubmit}
                    >
                      {t("login.resetPassword.confirmButtonText")}
                    </button>
                  )}

                <h6 className="mt-4 text-right">
                  <Link to="/login">{t("login.buttonText")}</Link>
                </h6>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
