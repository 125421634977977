import React, { Component } from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';

export class index extends React.Component {

    render() {
        return (
            <div className="search-schedule-container">
                <div className="provide-input">
                    <TextField id="outlined-basic" label="Provider Lookup" variant="outlined" value={this.props.search_name} onChange={this.props.onChange} />
                </div>
                <div className="names-list-container mt-3">
                    <ul className="search-names-list">
                        {this.props.dataArray && this.props.dataArray.length > 0 ? this.props.dataArray.map((item) => {
                            return (
                                <li className="search-names-items">
                                    <div className="dr">
                                        <div className="font-weight">{item.udf1 || ""}</div>
                                    </div>
                                    <div className="search-role-org-sub">
                                {this.props.orgName}
                              </div>
                                </li>
                            )
                        }) : <div className="ml-5"><p className="text-voilet font-weight"> No User Found! </p></div>}
                    </ul>
                </div>
            </div>
        )
    }


}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(index) 
