import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as MoreMenuIcon } from "../../../assets/icon_more_menu_vertical.svg";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const ChatRecipientMoreMenu = (props) => {
  const { updateThreadActiveStatus, setStatusControl, statusControl } = props;
  const { t } = useTranslation();
  const balloon = useRef();
  const bell = useRef();
  const [listVisible, setListVisible] = useState(false);

  const toggleList = () => {
    if (listVisible) {
      hideList();
    } else {
      showList();
    }
  };

  const showList = () => {
    setListVisible(true);
  };

  const hideList = () => {
    setListVisible(false);
  };

  const handleClickOutside = (event) => {
    if (
      balloon.current &&
      !balloon.current.contains(event.target) &&
      bell.current &&
      !bell.current.contains(event.target)
    ) {
      setListVisible(false);
    }
  };

  const changeActiveState = () => {
    setStatusControl(!statusControl);
    updateThreadActiveStatus(!statusControl);
    toggleList();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      <div ref={bell} onClick={toggleList} className="nav-link">
        <div className="d-flex">
          <MoreMenuIcon />
        </div>
      </div>
      {listVisible && (
        <div ref={balloon} className="new-msg-from-list">
          <div className="list-item" onClick={() => changeActiveState()}>
            {statusControl
              ? t("connect.make_inactive")
              : t("connect.make_active")}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatRecipientMoreMenu);
