import React, { useState, useEffect } from "react";
import agent from "../../agent";
import { connect } from "react-redux";
import moment from "moment";
import { HOURS, MINUTES } from "../../constants/scheduleConstants";
import { DELETE_ORG } from "../../constants/actionTypes";
import { useTranslation } from "react-i18next";
import SchedulerPopup from "./SchedulePopup";
import { ScheduleCalendar } from "./ScheduleComponents";
// import ScheduleMinute from "./ScheduleMinute";

import "react-datepicker/dist/react-datepicker.css";

const mapStateToProps = (state) => ({
  schedulesList: state.schedule.schedulesList,
  hcpUsers: state.common.hcpUsers,
  scheduleSearchList: state.schedule.scheduleSearchList,
});

const mapDispatchToProps = (dispatch) => ({});

const MainView = (props) => {
  const { schedulesList, hcpUsers, scheduleSearchList } = props;
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [resultsList, setResultsList] = useState([]);
  const [goToScheduleBlock, setGoToScheduleBlock ] = useState({});

  // const goToScheduleBlock = (data) => {
  //   console.log(data);
  // };
  const searchScheduleList = (searchString) => {
    if (searchString && searchString.length) {
      let found = scheduleSearchList.filter(
        (item) =>
          item.hcpName.toLowerCase().indexOf(searchString.toLowerCase()) > -1
      );
      setResultsList(found);
    } else {
      setGoToScheduleBlock({});
      setResultsList([]);
    }
  };
  const onSearchText = (value) => {
    setSearchText(value);
    searchScheduleList(value);
  };

  return (
    <div className="mainview-container">
      <div className="search-column p-4">
        <fieldset>
          <label className="title" for="search-input">{t("schedule.search_title")}</label>
          <input
            value={searchText}
            onChange={(e) => onSearchText(e.currentTarget.value)}
            name="search-input"
            type="text"
            className="form-control form-control-md col-sm-12"
          />
        </fieldset>
        <div className="results-container mt-4">
          <h6 className="title">{t("schedule.search_results_title")}</h6>
        </div>
        {resultsList && resultsList.length > 0 && (
          <div className="names-list">
            {resultsList.map((item) => {
              return (
                <div className="name" onClick={() => setGoToScheduleBlock(item)}>
                  {item.hcpName}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="schedules-column">
        <ScheduleCalendar schedulesList={schedulesList} hcpUsers={hcpUsers} goToScheduleBlock={goToScheduleBlock} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
