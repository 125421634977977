import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import agent from "../../../agent";
import {
  ACCEPT_TRANSFER,
  REJECT_TRANSFER,
} from "../../../constants/actionTypes";

const mapStateToProps = (state) => ({
  token: state.common.token,
  currentUser: state.common.currentUser,
  newIncomingMessage: state.common.newMessage,
  transferablePeers: state.chat.transferablePeers,
});

const mapDispatchToProps = (dispatch) => ({
  acceptTransfer: (data) =>
    dispatch({
      type: ACCEPT_TRANSFER,
      payload: agent.Chat.acceptTransfer(data),
    }),
  rejectTransfer: (data) =>
    dispatch({
      type: REJECT_TRANSFER,
      payload: agent.Chat.rejectTransfer(data),
    }),
});

const AcceptRejectTransfer = (props) => {
  const { t } = useTranslation();
  const {
    data,
    searchResultArray,
    acceptTransfer,
    rejectTransfer,
    currentThreadStatus,
    currentThreadId,
    transferablePeers,
  } = props;

  const [rejectMessage, setRejectMessage] = useState("");
  const [rejectClicked, setRejectClicked] = useState(false);

  const getUserName = (id) => {
    if (transferablePeers && transferablePeers.length) {
      const user = transferablePeers.filter((item) => item.uid === id);
      return user[0].username;
    }
  };

  const onAccept = () => {
    acceptTransfer({
      threadId: currentThreadId,
    });
  };

  const confirmReject = () => {
    rejectTransfer({
      message: rejectMessage,
      threadId: currentThreadId,
    });
  };

  return (
    <React.Fragment>
      <li
        id={data.uid}
        className={`message-item system-message transfer-acknowledge ${
          searchResultArray &&
          searchResultArray.length &&
          searchResultArray.filter((li) => li.uid === data.uid).length === 1
            ? "in-search-result"
            : ""
        }`}
        key={data.uid}
      >
        <div className="message-container col-12">
          <div className="message-from-preview">
            <div className="message-from text-secondary text-muted font-italic">
              {t("chat.transfer.transfer_request")}
            </div>
            <div className="message text-secondary font-italic">
              {t("chat.transfer.requested_transfer_name").replace(
                "{x}",
                getUserName(data.udf1)
              )}
              <br/>
              {data.content}
            </div>
            <div className="mt-4 text-center">
              <h6>{t("chat.transfer.accept_reject_transfer_text")}</h6>
            </div>
            <div className="accept-decline-block">
              {rejectClicked ? (
                <React.Fragment>
                  <textarea
                    name="name"
                    className="form-control form-control-md"
                    type="text"
                    value={rejectMessage}
                    onChange={(e) => setRejectMessage(e.currentTarget.value)}
                  />
                  <button
                    className="btn btn-primary col-5 ml-2 mt-2"
                    onClick={confirmReject}
                    disabled={!rejectMessage.length}
                  >
                    {t("chat.transfer.confirm_decline")}
                  </button>
                  <button
                    className="btn btn-secondary col-5 ml-2 mt-2"
                    onClick={() => setRejectClicked(false)}
                  >
                    {t("common.cancel")}
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <button
                    className="btn btn-secondary col-5 ml-2 mt-2"
                    onClick={() => setRejectClicked(true)}
                  >
                    {t("chat.transfer.decline_button_text")}
                  </button>
                  <button
                    className="btn btn-success col-5 ml-2 mt-2"
                    onClick={onAccept}
                  >
                    {t("chat.transfer.accept_button_text")}
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptRejectTransfer);
