import { Link } from "react-router-dom";
import ListErrors from "./ListErrors";
import React, { useEffect, useState } from "react";
import agent from "../agent";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { SEARCH_CHAT_RECIPIENTS } from "../constants/actionTypes";

import "./styles/SearchRecipients.scss";

const mapStateToProps = (state) => ({ ...state.auth });

const mapDispatchToProps = (dispatch) => ({
  searchChatRecipients: (searchString) =>
    dispatch({ type: SEARCH_CHAT_RECIPIENTS, payload: searchString }),
});

const SearchRecipients = (props) => {
  const { searchChatRecipients } = props;
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { t } = useTranslation();
  const showSearchBox = () => {
    setSearchBoxVisible(true);
  };
  const onSearchText = (input) => {
    setSearchText(input.currentTarget.value);
    searchChatRecipients(input.currentTarget.value);
  };
  const onClose = () => {
    setSearchText("");
    searchChatRecipients(null);
    setSearchBoxVisible(false);
  };

  useEffect(() => {
    return () => {
      searchChatRecipients(null);
    };
  }, []);
  return (
    <div className="recipients-search-box-container">
      <input
        className="search-input form-control"
        onChange={onSearchText}
        placeholder={t("connect.search_recipients_input_placeholder")}
      />
      {searchText && searchText.length > 0 ? (
        <div className="clear icon" onClick={() => onClose()}></div>
      ) : (
        <div
          className="search-recipients icon"
          onClick={() => showSearchBox()}
        ></div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchRecipients);
