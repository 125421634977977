import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  LOGOUT,
  CURRENT_VIEW,
  SHOW_CREATE_EDIT_USER_POPUP,
  CREATE_USER,
  UPDATE_USER,
} from "../constants/actionTypes";
import agent from "../agent";
import RoleSelector from "./RoleSelector";
import OrgSelector from "./OrgSelector";
import SearchDropdown from "./SearchDropdown";

import "./styles/CreatEditUser.scss";

const mapStateToProps = (state) => ({
  ...state.auth,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  createEditUserPopupData: state.common.createEditUserPopup,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => {
    dispatch({ type: LOGOUT, payload: agent.Auth.logout() });
  },
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
  closeUserPopup: () =>
    dispatch({ type: SHOW_CREATE_EDIT_USER_POPUP, payload: false }),
  create: (data) =>
    dispatch({ type: CREATE_USER, payload: agent.Users.createUser(data) }),
  update: (data) =>
    dispatch({ type: UPDATE_USER, payload: agent.Users.updateUser(data) }),
  createHcp: (data) =>
    dispatch({ type: CREATE_USER, payload: agent.Users.createHcp(data) }),
  updateHcp: (data) =>
    dispatch({ type: UPDATE_USER, payload: agent.Users.updateHcp(data) }),
});

const CreateEditUser = (props) => {
  const { t } = useTranslation();
  const {
    onViewChange,
    viewName,
    createEditUserPopupData,
    closeUserPopup,
    create,
    update,
    createHcp,
    updateHcp,
    inProgress,
    currentUser,
    usersArray,
  } = props;

  const [salesReps, setSalesReps] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);

  const [duplicateName, setDuplicateName] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [duplicatePhone, setDuplicatePhone] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const [formUsername, setFormUsername] = useState("");
  const [formPass, setFormPass] = useState("");
  const [formRepeatPass, setFormRepeatPass] = useState();
  const [formFname, setFormFname] = useState("");
  const [formLname, setFormLname] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formPhone, setFormPhone] = useState("");
  const [formVeevaLink, setFormVeevaLink] = useState("");
  const [formRoleId, setFormRoleId] = useState("");
  const [formOrgId, setFormOrgId] = useState(currentUser.orgId || "");
  const [formNotes, setFormNotes] = useState("");
  const [formComments, setFormComments] = useState("");
  const [formHcpExternalId, setFormHcpExternalId] = useState("");
  const [formHcpSupportAgentId, setFormHcpSupportAgentId] = useState("");
  const [formHcpTitle, setFormHcpTitle] = useState("");
  const [formHcpOrg, setFormHcpOrg] = useState("");
  const [formHcpSpeciality, setFormHcpSpeciality] = useState("");

  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState(false);
  const [passLowerCase, setPassLowerCase] = useState(false);
  const [passUpperCase, setPassUpperCase] = useState(false);
  const [passNumber, setPassNumber] = useState(false);
  const [passSymbol, setPassSymbol] = useState(false);
  const [passLength, setPassLength] = useState(false);
  const [passUserInfo, setPassUserInfo] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const updateUser = () => {
    if (formRoleId === "5" || formRoleId === 5) {
      const hcpformData = {
        user: {
          uid: createEditUserPopupData.uid,
          fName: formFname,
          lName: formLname,
          email: formEmail,
          phone: formPhone,
          orgId: formOrgId,
          roleId: formRoleId,
          notes: formNotes,
          comments: formComments,
          veevaLink: formVeevaLink,
          externalUserId: formHcpExternalId,
          personalSupportAgentId: formHcpSupportAgentId,
          // dob: "none",
          title: formHcpTitle,
          org: formHcpOrg,
          speciality: formHcpSpeciality
        },
        veeva: {},
        line: {},
      };
      updateHcp(hcpformData);
    } else {
      const formData = {
        user: {
          uid: createEditUserPopupData.uid,
          username: formUsername || null,
          fName: formFname,
          lName: formLname,
          email: formEmail,
          phone: formPhone,
          orgId: formOrgId,
          roleId: formRoleId,
          notes: formNotes,
          comments: formComments,
          veevaLink: formVeevaLink,
          // dob: "none"
        },
        veeva: {},
        line: {},
      };
      update(formData);
    }
  };

  const createUser = () => {
    if (formRoleId === "5" || formRoleId === 5) {
      const hcpFormData = {
        user: {
          fName: formFname,
          lName: formLname,
          email: formEmail,
          phone: formPhone,
          orgId: formOrgId || currentUser.orgId,
          roleId: formRoleId,
          notes: formNotes,
          comments: formComments,
          veevaLink: formVeevaLink,
          dob: "none",
          externalUserId: formHcpExternalId,
          personalSupportAgentId: formHcpSupportAgentId,
          title: formHcpTitle,
          org: formHcpOrg,
          speciality: formHcpSpeciality
        },
        veeva: {},
        line: {},
      };
      createHcp(hcpFormData);
    } else {
      const formData = {
        user: {
          username: formUsername || null,
          password: formRepeatPass || null,
          fName: formFname,
          lName: formLname,
          email: formEmail,
          phone: formPhone,
          orgId: formOrgId || currentUser.orgId,
          roleId: formRoleId,
          notes: formNotes,
          comments: formComments,
          veevaLink: formVeevaLink,
          dob: "none",
        },
        veeva: {},
        line: {},
      };
      create(formData);
    }
  };

  const checkAvailability = async (name, email, phone) => {
    const response = await agent.Users.availableProperties({
      username: name || false,
      email: email || false,
      phone: phone || false,
    });

    if (name) {
      setDuplicateName(response && response.data);
    } else if (email) {
      setDuplicateEmail(response && response.data);
    } else if (phone) {
      setDuplicatePhone(response && response.data);
    }
  };

  const submitForm = () => {
    if (createEditUserPopupData && createEditUserPopupData.uid) {
      updateUser();
    } else {
      createUser();
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const newForm = () => {
    // clear form data
    setFormUsername("");
    setFormFname("");
    setFormLname("");
    setFormEmail("");
    setFormPhone("");
    setFormVeevaLink("");
    setFormRoleId("");
    setFormOrgId(
      currentUser &&
        currentUser.orgId &&
        currentUser.roleId &&
        currentUser.roleId === 2
        ? currentUser.orgId
        : ""
    );
    setFormNotes("");
    setFormComments("");
    setFormHcpExternalId("");
    setFormHcpSupportAgentId("");
    setFormPass("");
    setFormRepeatPass("");
    setFormHcpTitle("");
    setFormHcpOrg("");
    setFormHcpSpeciality("");
    // show popup
    setShowPopup(true);
    setIsUpdateForm(false);
  };

  const populateFormData = (formData) => {
    const {
      uid,
      username,
      fName,
      lName,
      phone,
      email,
      veevaLink,
      roleId,
      orgId,
      notes,
      comments,
      externalUserId = "",
      personalSupportAgentId = "",
      title = "",
      org = "",
      speciality = ""
    } = formData;
    // set form data
    setFormUsername(username);
    setFormFname(fName);
    setFormLname(lName);
    setFormEmail(email);
    setFormPhone(phone);
    setFormVeevaLink(veevaLink);
    setFormRoleId(roleId);
    setFormOrgId(orgId);
    setFormNotes(notes);
    setFormComments(comments);
    // for HCP users only
    setFormHcpExternalId(externalUserId);
    setFormHcpSupportAgentId(personalSupportAgentId);
    setFormHcpTitle(title);
    setFormHcpOrg(org);
    setFormHcpSpeciality(speciality);
    // Show popup
    setShowPopup(true);
    setIsUpdateForm(true);
  };

  useEffect(() => {
    if (usersArray) {
      // Logic to filter only SALES REPS from the users list
      const salesRepsList = usersArray.filter((item) => {
        return item.roleId === "3" || item.roleId === 3;
      });
      setSalesReps(salesRepsList || []);
    }
    if (
      createEditUserPopupData &&
      typeof createEditUserPopupData === "object" &&
      createEditUserPopupData.uid
    ) {
      populateFormData(createEditUserPopupData);
    } else if (createEditUserPopupData) {
      newForm();
    } else {
      closePopup();
    }
    return () => {
      // clear form data
      setFormUsername("");
      setFormFname("");
      setFormLname("");
      setFormEmail("");
      setFormPhone("");
      setFormVeevaLink("");
      setFormRoleId("");
      setFormOrgId(
        currentUser &&
          currentUser.orgId &&
          currentUser.roleId &&
          currentUser.roleId === 2
          ? currentUser.orgId
          : ""
      );
      setFormNotes("");
      setFormComments("");
      setFormHcpExternalId("");
      setFormHcpSupportAgentId("");
      setFormPass("");
      setFormRepeatPass("");

      setDuplicateEmail(false);
      setDuplicateName(false);
      setDuplicatePhone(false);
    };
  }, [createEditUserPopupData]);

  const passwordValidate = (ev) => {
    let uValied,
      fValied,
      lValied = false;
    let sequence = true;
    let s = [...ev.target.value];
    for (let i in s) {
      if (+s[+i + 1] == +s[i] + 1 && +s[+i + 2] == +s[i] + 2) {
        sequence = false;
      }
    }

    let uName = [].concat
      .apply(
        [],
        [...Array(formUsername.split("").length).keys()].map((i) =>
          formUsername.substr(i).match(/.{3}/g)
        )
      )
      .filter((e) => e);
    let fName = [].concat
      .apply(
        [],
        [...Array(formFname.split("").length).keys()].map((i) =>
          formFname.substr(i).match(/.{3}/g)
        )
      )
      .filter((e) => e);
    let lName = [].concat
      .apply(
        [],
        [...Array(formLname.split("").length).keys()].map((i) =>
          formLname.substr(i).match(/.{3}/g)
        )
      )
      .filter((e) => e);

    uValied =
      uName.length && new RegExp(uName.join("|"), "i").test(ev.target.value)
        ? true
        : false;
    fValied =
      fName.length && new RegExp(fName.join("|"), "i").test(ev.target.value)
        ? true
        : false;
    lValied =
      lName.length && new RegExp(lName.join("|"), "i").test(ev.target.value)
        ? true
        : false;

    if (ev.target.value.match(/[a-z]/g)) {
      setPassLowerCase(true);
    } else {
      setPassLowerCase(false);
    }
    if (ev.target.value.match(/[A-Z]/g)) {
      setPassUpperCase(true);
    } else {
      setPassUpperCase(false);
    }
    if (
      ev.target.value.match(/[0-9]/g) &&
      ev.target.value.match(/[0-9]/g) &&
      sequence
    ) {
      setPassNumber(true);
    } else {
      setPassNumber(false);
    }
    if (ev.target.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)) {
      setPassSymbol(true);
    } else {
      setPassSymbol(false);
    }
    if (ev.target.value.length > 7 && ev.target.value.length < 21) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
    if (uValied || fValied || lValied) {
      setPassUserInfo(false);
    } else {
      setPassUserInfo(true);
    }
    setFormPass(ev.currentTarget.value);
  };
  const resetValidate = (ev) => {
    if (
      passUpperCase &&
      passLowerCase &&
      passLength &&
      passNumber &&
      passSymbol &&
      passUserInfo
    ) {
      // if (formPass === ev.target.value) {
      setDisableSubmit(false);
      // }
      // setDisableSubmit(true)
    } else {
      setDisableSubmit(true);
    }
    setFormRepeatPass(ev.target.value);
  };

  const checkRequiredFields = (input) => {
    if (input && input.length) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  };

  useEffect(() => {}, [
    formPass,
    passLowerCase,
    passUpperCase,
    passNumber,
    passSymbol,
    passLength,
    disableSubmit,
    formRepeatPass,
  ]);

  return (
    <React.Fragment>
      {showPopup && (
        <div className="user-form-popup">
          <div className="form-container">
            <div className="popup-header">
              {!isUpdateForm ? (
                <h5>{t("users.user_popup.new_user_title")}</h5>
              ) : (
                <h5>{t("users.user_popup.update_user_title")}</h5>
              )}
            </div>
            <form className="new-user">
              <fieldset>
                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <label for="role">{t("users.user_details.role")}</label>
                    {createEditUserPopupData && createEditUserPopupData.uid ? (
                      <RoleSelector
                        propRoleId={createEditUserPopupData.roleId}
                        onSelect={setFormRoleId}
                      />
                    ) : (
                      <RoleSelector onSelect={setFormRoleId} />
                    )}
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <label for="org">{t("users.user_details.org")}</label>
                    {createEditUserPopupData && createEditUserPopupData.uid ? (
                      <OrgSelector
                        propOrgId={createEditUserPopupData.orgId}
                        onSelect={setFormOrgId}
                      />
                    ) : (
                      <OrgSelector onSelect={setFormOrgId} />
                    )}
                  </div>
                </div>
                {formRoleId !== "5" &&
                  createEditUserPopupData.roleId !== "5" &&
                  createEditUserPopupData.roleId !== 5 && (
                    <div className="form-group row">
                      <div className="col-12 mb-2">
                        <label for="username" className="required">
                          {t("users.user_details.username")}
                        </label>
                        <input
                          name="username"
                          className="form-control form-control-md"
                          type="text"
                          // placeholder={t("login.placeholder.username")}
                          value={formUsername}
                          onChange={(e) => {
                            setFormUsername(e.currentTarget.value);
                            checkRequiredFields(e.currentTarget.value);
                          }}
                          disabled={isDisabled}
                          onBlur={() => {
                            checkAvailability(formUsername);
                          }}
                          autoComplete={"off"}
                        />
                        {duplicateName && (
                          <label className="text-danger" for="username">
                            {t("users.duplicate.duplicate_name_message")}
                          </label>
                        )}
                      </div>
                    </div>
                  )}

                {(formRoleId === "5" ||
                  createEditUserPopupData.roleId === 5) && (
                  <div className="form-group row">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                      <label for="hcp_external_id" className="required">
                        {t("users.user_details.hcp_external_id")}
                      </label>
                      <input
                        name="hcp_external_id"
                        className="form-control form-control-md"
                        type="text"
                        value={formHcpExternalId}
                        onChange={(e) => {
                          setFormHcpExternalId(e.currentTarget.value);
                          checkRequiredFields(e.currentTarget.value);
                        }}
                        disabled={isDisabled}
                        autoComplete={"off"}
                      />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                      <label for="support_agent_id">
                        {t("users.user_details.support_agent_id")}
                      </label>
                      <SearchDropdown
                        preSelectId={
                          createEditUserPopupData &&
                          createEditUserPopupData.personalSupportAgentId
                            ? createEditUserPopupData.personalSupportAgentId
                            : null
                        }
                        onChange={setFormHcpSupportAgentId}
                        options={salesReps}
                        labelProp="username"
                      />
                    </div>
                  </div>
                )}

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="fname">
                      {t("users.user_details.firstname")}
                    </label>
                    <input
                      name="fname"
                      className="form-control form-control-md"
                      type="text"
                      // placeholder={t("login.placeholder.password")}
                      value={formFname}
                      onChange={(e) => setFormFname(e.currentTarget.value)}
                      disabled={isDisabled}
                      autoComplete={"off"}
                    />
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="lname">
                      {t("users.user_details.lastname")}
                    </label>
                    <input
                      name="lname"
                      className="form-control form-control-md"
                      type="text"
                      // placeholder={t("login.placeholder.password")}
                      value={formLname}
                      onChange={(e) => setFormLname(e.currentTarget.value)}
                      disabled={isDisabled}
                      autoComplete={"off"}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="email" className="required">
                      {t("users.user_details.e_mail")}
                    </label>
                    <input
                      name="email"
                      className="form-control form-control-md"
                      type="email"
                      // placeholder={t("login.placeholder.password")}
                      value={formEmail}
                      onChange={(e) => {
                        setFormEmail(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                      onBlur={() => {
                        checkAvailability(false, formEmail);
                      }}
                      autoComplete={"off"}
                    />
                    {duplicateEmail && (
                      <label className="text-danger" for="email">
                        {t("users.duplicate.duplicate_email_message")}
                      </label>
                    )}
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="phone" className="required">
                      {t("users.user_details.phone")}
                    </label>
                    <input
                      name="phone"
                      className="form-control form-control-md"
                      type="number"
                      // placeholder={t("login.placeholder.password")}
                      value={formPhone}
                      onChange={(e) => {
                        if (e.currentTarget.value.length < 16) {
                          setFormPhone(e.currentTarget.value);
                          checkRequiredFields(e.currentTarget.value);
                        }
                      }}
                      onBlur={() => {
                        checkAvailability(false, false, formPhone);
                      }}
                      disabled={isDisabled}
                      autoComplete={"off"}
                    />
                    {duplicatePhone && (
                      <label className="text-danger" for="phone">
                        {t("users.duplicate.duplicate_phone_message")}
                      </label>
                    )}
                  </div>
                </div>

                {(formRoleId === "5" ||
                  createEditUserPopupData.roleId === 5) && (
                  <div className="form-group row">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4  mb-2">
                      <label for="hcp_title">
                        {t("users.user_details.hcp_title")}
                      </label>
                      <input
                        name="hcp_title"
                        className="form-control form-control-md"
                        type="text"
                        value={formHcpTitle}
                        onChange={(e) => {
                          setFormHcpTitle(e.currentTarget.value);
                        }}
                        disabled={isDisabled}
                        autoComplete={"off"}
                      />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4  mb-2">
                      <label for="hcp_speciality">
                        {t("users.user_details.hcp_speciality")}
                      </label>
                      <input
                        name="hcp_speciality"
                        className="form-control form-control-md"
                        type="text"
                        value={formHcpSpeciality}
                        onChange={(e) => {
                          setFormHcpSpeciality(e.currentTarget.value);
                        }}
                        disabled={isDisabled}
                        autoComplete={"off"}
                      />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4  mb-2">
                      <label for="hcp_org">
                        {t("users.user_details.hcp_org")}
                      </label>
                      <input
                        name="hcp_org"
                        className="form-control form-control-md"
                        type="text"
                        value={formHcpOrg}
                        onChange={(e) => {
                          setFormHcpOrg(e.currentTarget.value);
                        }}
                        disabled={isDisabled}
                        autoComplete={"off"}
                      />
                    </div>
                  </div>
                )}

                {!isUpdateForm && formRoleId !== "5" && (
                  <div className="form-group row">
                    <div className="col-12 mb-2">
                      <label for="pass">
                        {t("users.user_details.password")}
                      </label>
                      <input
                        name="pass"
                        className="form-control form-control-md"
                        type="password"
                        value={formPass}
                        onFocus={() => setMessage(true)}
                        onBlur={() => setMessage(false)}
                        onChange={(e) => passwordValidate(e)}
                        disabled={isDisabled}
                        autoComplete={"off"}
                      />
                    </div>
                    {message && (
                      <div className="row mb-2 px-3">
                        <div className="col-6 mb-12">
                          <span
                            className={`${!passLowerCase ? "false" : "true"}`}
                          ></span>
                          <span>Lowercase</span>
                        </div>
                        <div className="col-6 mb-12">
                          <span
                            className={`${!passUpperCase ? "false" : "true"}`}
                          >
                            {" "}
                          </span>
                          <span>Uppercase</span>
                        </div>
                        <div className="col-6 mb-12">
                          <span className={`${!passSymbol ? "false" : "true"}`}>
                            {" "}
                          </span>
                          <span>Symbol</span>
                        </div>
                        <div className="col-6 mb-12">
                          <span className={`${!passLength ? "false" : "true"}`}>
                            {" "}
                          </span>
                          <span>Length (8-20)</span>
                        </div>
                        <div className="col-12 mb-12">
                          <span
                            className={`${!passNumber ? "false" : "true"}`}
                          ></span>
                          <span>Number (No sequential numbers)</span>
                        </div>
                        <div className="col-12 mb-12">
                          <span
                            className={`${!passUserInfo ? "false" : "true"}`}
                          ></span>
                          <span>
                            Should not contain username, first name, last name
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="col-12 mb-2">
                      <label for="rpass">
                        {t("users.user_details.repeatPassword")}
                      </label>
                      <input
                        name="rpass"
                        className="form-control form-control-md"
                        type="password"
                        value={formRepeatPass}
                        onChange={
                          (e) => resetValidate(e)
                          // setFormRepeatPass(e.currentTarget.value)
                        }
                        disabled={isDisabled || formRoleId === 5}
                      />
                    </div>
                    {formPass &&
                      formPass.length &&
                      formRepeatPass &&
                      formRepeatPass.length && (
                        <div className="col-12 mb-2">
                          {formRepeatPass !== formPass ? (
                            <>
                              <span className="false"></span>
                              <span>{t("common.passwordMatchText")}</span>
                            </>
                          ) : (
                            <>
                              <span className="true"></span>
                              <span>{t("common.passwordMatchSuccess")}</span>
                            </>
                          )}
                        </div>
                      )}
                  </div>
                )}
                <div className="form-group row">
                  <div className="col-12">
                    <label for="veeva_link">
                      {t("users.user_details.veeva_link")}
                    </label>
                    <input
                      name="veeva_link"
                      className="form-control form-control-md col-sm-12"
                      type="url"
                      // placeholder={t("login.placeholder.username")}
                      value={formVeevaLink}
                      onChange={(e) => setFormVeevaLink(e.currentTarget.value)}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12">
                    <label for="fname">{t("users.user_details.notes")}</label>
                    <input
                      name="notes"
                      className="form-control form-control-md col-sm-12"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={formNotes}
                      onChange={(e) => setFormNotes(e.currentTarget.value)}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12">
                    <label for="comments">
                      {t("users.user_details.comments")}
                    </label>
                    <input
                      name="comments"
                      className="form-control form-control-md col-sm-12"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={formComments}
                      onChange={(e) => setFormComments(e.currentTarget.value)}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
            <div className="popup-footer">
              <button
                onClick={closeUserPopup}
                className="btn btn-secondary ml-4"
              >
                {t("common.cancel")}
              </button>
              <button
                className="btn btn-primary ml-4"
                onClick={submitForm}
                // disabled={(!disableSubmit && (formPass === formRepeatPass)) ? false : true}
                disabled={
                  (!isUpdateForm && formRoleId !== "5"
                    ? !disableSubmit && formPass === formRepeatPass
                      ? false
                      : true
                    : false) ||
                  duplicateEmail ||
                  duplicateName ||
                  duplicatePhone
                }
              >
                {t("common.save")}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditUser);
