import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainView from "./MainView";
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  APPLY_TAG_FILTER,
  CURRENT_VIEW,
  LOGOUT,
  GET_ROLES_LIST,
  GET_ORGS_LIST,
  GET_USERS_LIST,
} from "../../constants/actionTypes";
import agent from "../../agent";
import CreateEditOrg from "../CreatEditOrg";
import ConfirmPopup from "../ConfirmPopup";

import "./OrgsView.scss";

const Promise = global.Promise;

const mapStateToProps = (state) => ({
  ...state.home,
  appName: state.common.appName,
  currentUser: state.common.currentUser,
  token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => dispatch({ type: LOGOUT }),
  onClickTag: (tag, pager, payload) =>
    dispatch({ type: APPLY_TAG_FILTER, tag, pager, payload }),
  onLoad: (tab, pager, payload) =>
    dispatch({ type: HOME_PAGE_LOADED, tab, pager, payload }),
  onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
  updateRolesList: () =>
    dispatch({ type: GET_ROLES_LIST, payload: agent.Roles.getList() }),
  updateOrgsList: () =>
    dispatch({ type: GET_ORGS_LIST, payload: agent.Orgs.getList() }),
  updateUsersList: () =>
    dispatch({ type: GET_USERS_LIST, payload: agent.Users.getList() }),
});

const OrgsView = (props) => {
  const {
    token,
    currentUser,
    onLoad,
    onViewChange,
    onUnload,
    onSignOut,
    updateRolesList,
    updateOrgsList,
    updateUsersList,
  } = props;

  const [deletePopupObj, setDeletePopupObj] = useState(null);

  useEffect(() => {
    // if(!token && !currentUser){
    //   onSignOut();
    // }
    updateRolesList();
    updateOrgsList();
    // updateUsersList();
    onViewChange({ id: "7", name: "sideMenu.organizations" });
    return () => {
      onUnload();
    };
  }, []);

  return (
    <div className="view-container orgs-view">
      <MainView showDeletePopup={setDeletePopupObj} />
      <CreateEditOrg />
      {deletePopupObj && <ConfirmPopup {...deletePopupObj} />}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgsView);
