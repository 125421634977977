import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import agent from "../../../agent";
import {
  ACCEPT_TRANSFER,
  REJECT_TRANSFER,
} from "../../../constants/actionTypes";

const mapStateToProps = (state) => ({
  token: state.common.token,
  currentUser: state.common.currentUser,
  newIncomingMessage: state.common.newMessage,
  transferablePeers: state.chat.transferablePeers,
});

const mapDispatchToProps = (dispatch) => ({});

const SystemMessage = (props) => {
  const { t } = useTranslation();
  const {
    data,
    searchResultArray,
    acceptTransfer,
    rejectTransfer,
    currentThreadStatus,
    currentThreadId,
    transferablePeers,
  } = props;

  const getUserName = (id) => {
    if (transferablePeers && transferablePeers.length) {
      const user = transferablePeers.filter((item) => item.uid === id);
      return user[0].username;
    }
  };

  return (
    <React.Fragment>
      <li
        id={data.uid}
        className={`message-item system-message ${
          searchResultArray &&
          searchResultArray.length &&
          searchResultArray.filter((li) => li.uid === data.uid).length === 1
            ? "in-search-result"
            : ""
        }`}
        key={data.uid}
      >
        <div className="message-container col-12">
          <div className="message-from-preview">
            <div className="message-from text-secondary text-muted font-italic">
              {/* {t("chat.system_message.message_title")} */}
              {`${new Date(data.ts).toLocaleString()}`}
            </div>
            {data.messageType === 12 && (
              <div className="message text-secondary text-muted font-italic">
                {t("chat.system_message.transfered_to")
                  .replace("{x}", getUserName(data.agentId))
                  .replace("{y}", new Date(data.ts).toLocaleString())}
              </div>
            )}
            {data.messageType === 13 && (
              <div className="message text-secondary text-muted font-italic">
                {t("chat.system_message.has_acknowledged").replace(
                  "{x}",
                  getUserName(data.agentId)
                )}
              </div>
            )}
            {data.messageType === 14 && (
              <React.Fragment>
                <div className="message text-secondary text-muted font-italic">
                  {t("chat.system_message.has_rejected").replace(
                    "{x}",
                    getUserName(data.udf1)
                  )}
                </div>
                <div className="message text-secondary text-muted font-italic">
                  {data.content}
                </div>
              </React.Fragment>
            )}
            {data.messageType === 15 && (
              <React.Fragment>
                <div className="message text-secondary text-muted font-italic">
                  {t("chat.system_message.transfer_no_response")
                    .replace("{x}", getUserName(data.agentId))
                    .replace("{y}", getUserName(data.udf1))}
                </div>
                <div className="message text-secondary text-muted font-italic">
                  {/* {data.transfer_no_response} */}
                </div>
              </React.Fragment>
            )}
            {data.messageType === 18 && (
              <div className="message font-italic meeting meeting-confirmed">
                {t("chat.system_message.appointment_confirmed_text")}
              </div>
            )}
            {data.messageType === 19 && (
              <div className="message font-italic meeting meeting-declined">
                {t("chat.system_message.appointment_declined_text")}
              </div>
            )}
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessage);
