import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
import VideoThumbnail from "../../VideoThumbnail";

const mapStateToProps = (state) => ({
  token: state.common.token,
  currentUser: state.common.currentUser,
  newIncomingMessage: state.common.newMessage,
});

const mapDispatchToProps = (dispatch) => ({});

const PictureMessage = (props) => {
  const {
    data,
    searchResultArray,
    selectedRecipient,
    chatThreadContainer,
    updateIsReadStatusInThread,
  } = props;
  const {
    isRead,
    uid,
    messageDirectionType,
    content,
    attachmentUrl,
    ts,
    tagName,
  } = data;
  const [isMessageRead, setIsMessageRead] = useState(isRead || false);
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  const postIsReadStatus = () => {
    if (updateIsReadStatusInThread && !isMessageRead) {
      updateIsReadStatusInThread(uid);
      // setIsMessageRead(true);
    }
  };

  const onVisbilityChange = (isVisible) => {
    console.log(content, "~~~", isRead ? "Read" : "notRead", "---", isVisible);
    if (isVisible) {
      setIsMessageVisible(isVisible);
    } else {
      setIsMessageVisible(false);
    }
  };

  const visibilityProps = {
    onChange: onVisbilityChange,
    containment:
      chatThreadContainer && chatThreadContainer.current
        ? chatThreadContainer.current
        : null,
    delayedCall: true,
    resizeCheck: true,
    scrollDelay: 500,
    intervalDelay: 1000,
    minTopValue: 100,
    offset: { top: -500, bottom: -500 },
  };

  useEffect(() => {
    if (
      (messageDirectionType === 3 || messageDirectionType === "3") &&
      !isRead
    ) {
      postIsReadStatus();
    }
  }, [isMessageVisible]);

  return (
    <React.Fragment>
      <li
        id={uid}
        className={`message-item picture-message ${
          messageDirectionType && messageDirectionType === 4 ? "sent" : ""
        } ${
          searchResultArray &&
          searchResultArray.length &&
          searchResultArray.filter((li) => li.uid === uid).length === 1
            ? "in-search-result"
            : ""
        }`}
        key={uid}
      >
        <VisibilitySensor {...visibilityProps}>
          <div className="message-container col-sm-12 col-md-11 col-lg-8 col-xl-6">
            {messageDirectionType && messageDirectionType === 3 && (
              <div className="sender-name-tag">
                <h5>
                  {tagName ||
                    `${
                      selectedRecipient &&
                      selectedRecipient.hcp &&
                      selectedRecipient.hcp.fName
                        ? selectedRecipient.hcp.fName[0]
                        : ""
                    }${
                      selectedRecipient &&
                      selectedRecipient.hcp &&
                      selectedRecipient.hcp.lName
                        ? selectedRecipient.hcp.lName[0]
                        : ""
                    }`}
                </h5>
              </div>
            )}
            <div className="message-preview">
              {isMessageVisible && (
                <img
                  style={{
                    maxWidth: "280px",
                    maxHeight: "420px",
                    minWidth: "172px",
                    minHeight: "82px",
                  }}
                  src={attachmentUrl}
                  alt="image"
                />
              )}
              <div className="message">{content}</div>
              <div className="message-time">
                <div className="time-stamp">
                  {new Date(ts).toLocaleString()}
                </div>
                <div className={`info-icon warning`}></div>
              </div>
            </div>
          </div>
        </VisibilitySensor>
      </li>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PictureMessage);
