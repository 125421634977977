import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { store, history } from './store';

import { Route, Switch, BrowserRouter } from 'react-router-dom';

import App from './components/App';

import './i18n';

import './assets/fonts/material.ttf';

import './components/styles/index.scss';

console.log = function (...args) {
  // console.dir(...args);
};
console.error = function (...args) {
  console.log(...args);
};
console.warn = function (...args) {
  console.log(...args);
};
console.info = function (...args) {

  console.log(...args);
};

ReactDOM.render((
  <Provider store={store}>
    <BrowserRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </BrowserRouter>
  </Provider>

), document.getElementById('root'));
