import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import agent from "../../../agent";
import { search } from "superagent";
import { ON_THREAD_ACTIVE_STATUS_CHANGE } from "../../../constants/actionTypes";
import ChatRecipientMoreMenu from "./ChatRecipientMoreMenu";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
});

const mapDispatchToProps = (dispatch) => ({
  threadActivate: (data) =>
    dispatch({
      type: ON_THREAD_ACTIVE_STATUS_CHANGE,
      payload: agent.Chat.activateThread(data),
    }),
  threadDeactivate: (data) =>
    dispatch({
      type: ON_THREAD_ACTIVE_STATUS_CHANGE,
      payload: agent.Chat.deactivateThread(data),
    }),
});

const SearchThread = (props) => {
  const {
    selectedRecipient,
    threadListRef,
    messageThread,
    searchResultArray,
    setSearchResultArray,
    scrollChatThreadToEnd,
    threadActivate,
    threadDeactivate,
  } = props;
  const { hcp, thread } = selectedRecipient;
  const { uid, fName, lName, prefix, title, org, speciality } = hcp;
  const searchInput = useRef();
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState("");
  const [foundString, setFoundString] = useState("");
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0);
  const [statusControl, setStatusControl] = useState(false);

  const moveToItem = () => {
    if (
      searchResultArray &&
      searchResultArray.length &&
      searchResultArray[currentSearchIndex] &&
      searchResultArray[currentSearchIndex].uid
    ) {
      const currentItem = document.getElementById(
        searchResultArray[currentSearchIndex].uid
      );
      currentItem.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const moveUp = () => {
    if (searchResultArray && searchResultArray.length) {
      if (currentSearchIndex > 0) {
        // TODO
        let newIndex = currentSearchIndex - 1;
        setCurrentSearchIndex(newIndex);
      }
    }
  };

  const moveDown = () => {
    if (searchResultArray && searchResultArray.length) {
      if (currentSearchIndex < searchResultArray.length - 1) {
        // TODO
        let newIndex = currentSearchIndex + 1;
        setCurrentSearchIndex(newIndex);
      }
    }
  };

  const clearSearch = () => {
    setSearchResultArray([]);
    setCurrentSearchIndex(0);
    searchInput.current.blur();
    setSearchString("");
    searchInput.current.value = "";
    scrollChatThreadToEnd();
  };

  const naviagetTofoundItems = () => {
    // setSearchResultArray(searchData);
    if (searchResultArray && searchResultArray.length) {
      setCurrentSearchIndex(searchResultArray.length - 1);
    }
  };

  const submitSearch = () => {
    if (searchString) {
      searchInput.current.blur();
      const foundItems = messageThread.filter((item) => {
        return (
          item.content.toLowerCase().indexOf(searchString.toLowerCase()) > -1
        );
      });
      console.log(foundItems);
      if (foundItems && foundItems.length) {
        // naviagetTofoundItems(foundItems);
        setSearchResultArray(foundItems);
        setFoundString(searchString);
      } else {
        setSearchResultArray([]);
        setCurrentSearchIndex(0);
        setFoundString("");
      }
    }
  };

  const onEnterKey = (e) => {
    console.log(e);
    if (e.key.toLowerCase() === "enter") {
      submitSearch();
    }
  };

  const updateThreadActiveStatus = (status) => {
    if (status) {
      threadActivate({ threadId: thread.uid });
    } else {
      threadDeactivate({ threadId: thread.uid });
    }
  };

  useEffect(() => {
    moveToItem();
  }, [currentSearchIndex]);

  useEffect(() => {
    naviagetTofoundItems(searchResultArray);
  }, [searchResultArray]);

  useEffect(() => {
    if (selectedRecipient) {
      const { thread } = selectedRecipient;
      if (thread && thread.isArchived) {
        setStatusControl(false);
      } else {
        setStatusControl(true);
      }
    }
  }, [selectedRecipient, thread]);

  const moreMenuProps = {
    updateThreadActiveStatus,
    setStatusControl,
    statusControl
  };

  return (
    <React.Fragment>
      <div className="search-container">
        <div className="conversation-title">
          {selectedRecipient && (
            <React.Fragment>
              <p>
                {/* {t("connect.conversation_with").replace(
                "{x}",
                fName ? `${prefix || "Dr"} ${fName} ${lName} ` : ""
              )} */}
                {`${prefix || "Dr."} ${fName} ${lName} `}
              </p>
              {title && org && speciality && (
                <div className="title-block">{`${speciality} | ${org} | ${title} `}</div>
              )}
            </React.Fragment>
          )}
        </div>

        {/* <div class="switch-container">
          <h6 className="ml-0 pl-0">Chat thread status</h6>
          <div class="row">
            <div className="d-flex align-items-center">
              <h6>Inactive</h6>
              <button
                type="button"
                data-toggle="button"
                className={
                  statusControl
                    ? "btn btn-toggle active m-2"
                    : "btn btn-toggle m-2"
                }
                onClick={() => {
                  setStatusControl(!statusControl);
                  updateThreadActiveStatus(!statusControl);
                }}
              >
                <div class="handle"></div>
              </button>
              <h6>Active</h6>
            </div>
          </div>
        </div> */}

        <div className="search-box d-flex align-items-center">
          <div className="more-menu mr-4">
            <ChatRecipientMoreMenu {...moreMenuProps} />
          </div>
          <div className="input-group">
            <input
              ref={searchInput}
              type="text"
              className="form-control"
              placeholder={t("connect.quick_search_conversation")}
              onChange={(e) => setSearchString(e.currentTarget.value)}
              onKeyPress={onEnterKey}
            />
            <div className="input-group-append">
              <span className="input-group-text"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="search-results-container">
        {foundString ? (
          <React.Fragment>
            {searchResultArray && searchResultArray.length > 0 ? (
              <div className="search-results-block">
                <div className="search-results-count">
                  <div className="search-text">
                    {`Search result for `}
                    <bold>{`"${foundString}"`}</bold>
                  </div>
                  <div className="search-count">{`${
                    currentSearchIndex + 1
                  } of ${searchResultArray.length}`}</div>
                </div>
                <div className="search-result-controls">
                  <div onClick={moveUp} className="previous controls"></div>
                  <div onClick={moveDown} className="next controls"></div>
                  <div onClick={clearSearch} className="clear controls"></div>
                </div>
              </div>
            ) : (
              <div className="d-flex">
                {/* <label>No results found</label> */}
              </div>
            )}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchThread);
