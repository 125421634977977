import React, { useEffect, useState } from "react";
import Select from "react-select";
import agent from "../../agent";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  LOGIN_PAGE_UNLOADED,
} from "../../constants/actionTypes";

import "../styles/RoleSelector.scss";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  orgs: state.common.orgs,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeUsername: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "username", value }),
  onChangePassword: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "password", value }),
  onSubmit: (username, password) => {
    dispatch({ type: LOGIN, payload: agent.Auth.login(username, password) });
  },
});

const SearchDropdown = (props) => {
  const { labelProp, options, onChange, onDestroy, preSelectId, placeholder } = props;
  const { t } = useTranslation();
  const [optionsList, setOptionsList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const setOptions = (items) => {
    const processedItems = items.map((item) => {
      return { value: item.uid, label: item[labelProp] || item.name };
    });
    setOptionsList(processedItems);
    if (preSelectId) {
      const preSelectItem = processedItems.filter((item) => {
        return item.value === preSelectId;
      });
      if (preSelectItem && preSelectItem.length) {
        setSelectedItem(preSelectItem[0]);
      }
    }
  };

  const onSelectChange = (selected, action) => {
    setSelectedItem(selected);
    if (onChange) onChange(selected.value);
  };

  useEffect(() => {
    if(options && options.length){
      setOptions(options);
    }else{
      setOptions([]);
    }
    return () => {
      if (onDestroy) onDestroy();
      setSelectedItem(null);
    };
  }, [options]);
  return (
    <div className="search-dropdown">
      <Select
        placeholder={placeholder}
        onChange={onSelectChange}
        options={optionsList}
        value={selectedItem}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchDropdown);
