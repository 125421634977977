import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  SHOW_ADVERSE_EFFECT_POPUP,
  ON_ADVERSE_EFFECT_GET,
  ON_ADVERSE_EFFECT_UPDATE,
} from "../../constants/actionTypes";
import SearchDropdown from "../SearchDropdown";
import agent from "../../agent";
import { ReactComponent as ConnectIcon } from "../../assets/icon_baloons.svg";

import "./AdverseEffectPopup.scss";

const mapStateToProps = (state) => ({
  ...state.auth,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  showAdverseEffectPopup: state.chat.showAdverseEffectPopup,
  currentUser: state.common.currentUser,
  transferablePeers: state.chat.transferablePeers,
  adverseEffectData: state.chat.adverseEffectData,
});

const mapDispatchToProps = (dispatch) => ({
  closeTransferPopup: () =>
    dispatch({ type: SHOW_ADVERSE_EFFECT_POPUP, payload: false }),
  getDetails: (id) =>
    dispatch({
      type: ON_ADVERSE_EFFECT_GET,
      payload: agent.Chat.getAdverseEffectDetailsById(id),
    }),
  updateDetails: (data) =>
    dispatch({
      type: ON_ADVERSE_EFFECT_UPDATE,
      payload: agent.Chat.addAdverseEffectDetailsByMsgId(data),
    }),
});

const AdverseEffectPopup = (props) => {
  const { t } = useTranslation();
  const {
    showAdverseEffectPopup,
    closeTransferPopup,
    inProgress,
    currentUser,
    adverseEffectData,
    getDetails,
    updateDetails,
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const [colleague, setColleague] = useState("");
  const [colleagueName, setColleagueName] = useState("");
  const [noteToColleague, setNoteToColleague] = useState("");
  const [messageUniqueId, setMessageUniqueId] = useState("");
  const [orgUniqueId, setOrgUniqueId] = useState("");

  const [hcpId, setHcpId] = useState(null);
  const [hcpName, setHcpName] = useState("");
  const [hcpPhone, setHcpPhone] = useState("");
  const [hcpEmail, setHcpEmail] = useState("");
  const [agentId, setAgentId] = useState(null);
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [adverseEffectNotes, setAdverseEffectNotes] = useState([]);
  const [msgPreview, setMsgPreview] = useState("");

  const [age, setAge] = useState("");
  const [sex, setSex] = useState("");
  const [location, setLocation] = useState("");

  const submitForm = () => {
    let payload;
    if (
      adverseEffectData &&
      adverseEffectData.length &&
      adverseEffectData[0].uid
    ) {
      payload = {
        uid: adverseEffectData[0].uid,
        msgContent: msgPreview,
        notes: adverseEffectNotes,
        sex: sex,
        age: age,
        location: location,
      };
    } else {
      payload = {
        uid: null,
        msgContent: msgPreview,
        notes: adverseEffectNotes,
        sex: sex,
        age: age,
        location: location,
        msgId: messageUniqueId,
        agentId: agentId,
        hcpId: hcpId,
        orgId: orgUniqueId
      };
    }
    updateDetails(payload);
  };

  const closePopup = () => {
    if (closeTransferPopup) closeTransferPopup();
  };

  const resetForm = () => {
    // clear form data
  };

  useEffect(() => {
    resetForm();
  }, [showAdverseEffectPopup]);

  useEffect(() => {
    if (showAdverseEffectPopup && currentUser) {
      const { data, selectedRecipient } = showAdverseEffectPopup;
      const { uid: msgUniqueId, content } = data;
      const { hcp } = selectedRecipient;
      const {
        uid: currentHcpId,
        fName: hcpFName,
        lName: hcpLName,
        phone: hPhone,
        email: hEmail,
        prefix,
        title,
        org,
        speciality,
      } = hcp;
      const {
        uid: currentUserId,
        fName: uFName,
        lName: uLName,
        username,
        orgId,
        roleId,
        phone,
        email,
      } = currentUser;

      if (msgUniqueId) {
        getDetails(msgUniqueId);
        setMessageUniqueId(msgUniqueId);
      }
      setOrgUniqueId(orgId);
      setHcpId(currentHcpId);
      setHcpName(`${hcpFName} ${hcpLName}`);
      setHcpPhone(hPhone);
      setHcpEmail(hEmail);
      setAgentId(currentUserId);
      setUserName(`${uFName} ${uLName}`);
      setUserPhone(phone);
      setUserEmail(email);
    } else {
      getDetails(null);
      setOrgUniqueId("");
      setHcpId("");
      setHcpName("");
      setHcpPhone("");
      setHcpEmail("");
      setAgentId("");
      setUserName("");
      setUserPhone("");
      setUserEmail("");
    }
  }, [showAdverseEffectPopup, currentUser]);

  useEffect(() => {
    if (adverseEffectData && adverseEffectData.length) {
      const {
        sex: sexData = "",
        age: ageData = "",
        location: locationData = "",
        msgContent: msgData = "",
        notes: notesData = "",
      } = adverseEffectData[0];
      setSex(sexData);
      setAge(ageData);
      setLocation(locationData);
      setMsgPreview(msgData);
      setAdverseEffectNotes(notesData);
    } else {
      if (showAdverseEffectPopup) {
        const { data } = showAdverseEffectPopup;
        const { content: originalMsg = "" } = data;
        setMsgPreview(originalMsg);
      }
      setSex("");
      setAge("");
      setLocation("");
      setAdverseEffectNotes("");
    }
  }, [adverseEffectData]);

  return (
    <React.Fragment>
      {showAdverseEffectPopup && (
        <div className="adverse-effect-popup">
          <div className="form-container">
            <div className="popup-header pt-4">
              {/* <ConnectIcon className="mr-2" width="25" height="25" /> */}
              <div className="info-icon warning"></div>
              <h5>{t("chat.adverse.popup.title")}</h5>
              <div className="close-button" onClick={closePopup}></div>
            </div>
            <form>
              <fieldset>
                <h5>{t("chat.adverse.popup.hcp_details_title")}</h5>
                <div className="form-group row">
                  <div className="col-4 mb-2">
                    <label htmlFor="msg">{t("common.name")}</label>
                    <input
                      name="msg"
                      className="form-control form-control-md"
                      type="text"
                      disabled
                      // placeholder={t("login.placeholder.username")}
                      value={hcpName || ""}
                      onChange={(e) => setHcpName(e.currentTarget.value)}
                    />
                  </div>
                  <div className="col-4 mb-2">
                    <label htmlFor="msg">{t("common.phone")}</label>
                    <input
                      name="msg"
                      className="form-control form-control-md"
                      type="text"
                      disabled
                      // placeholder={t("login.placeholder.username")}
                      title={hcpPhone}
                      value={hcpPhone}
                      onChange={(e) => setHcpPhone("anand")}
                    />
                  </div>
                  <div className="col-4 mb-2">
                    <label htmlFor="msg">{t("common.email")}</label>
                    <input
                      name="msg"
                      className="form-control form-control-md"
                      type="text"
                      disabled
                      // placeholder={t("login.placeholder.username")}
                      title={hcpEmail}
                      value={hcpEmail}
                      onChange={(e) => setHcpEmail(e.currentTarget.value)}
                    />
                  </div>
                </div>

                <h5 className="mt-2 mb-2">
                  {t("chat.adverse.popup.user_details_title")}
                </h5>
                <div className="form-group row">
                  <div className="col-4 mb-2">
                    <label htmlFor="name">{t("common.name")}</label>
                    <input
                      name="name"
                      className="form-control form-control-md"
                      type="text"
                      disabled
                      // placeholder={t("login.placeholder.username")}
                      title={userName}
                      value={userName}
                      onChange={(e) => setUserName(e.currentTarget.value)}
                    />
                  </div>
                  <div className="col-4 mb-2">
                    <label htmlFor="phone">{t("common.phone")}</label>
                    <input
                      name="phone"
                      className="form-control form-control-md"
                      type="text"
                      disabled
                      // placeholder={t("login.placeholder.username")}
                      title={userPhone}
                      value={userPhone}
                      onChange={(e) => setUserPhone(e.currentTarget.value)}
                    />
                  </div>
                  <div className="col-4 mb-2">
                    <label htmlFor="email">{t("common.email")}</label>
                    <input
                      name="email"
                      className="form-control form-control-md"
                      type="text"
                      disabled
                      // placeholder={t("login.placeholder.username")}
                      title={userEmail}
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.currentTarget.value)}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12 mb-2">
                    <label htmlFor="msg">
                      {t("chat.adverse.popup.chat_msg")}
                    </label>
                    <textarea
                      name="msg"
                      className="form-control form-control-md"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={msgPreview || ""}
                      onChange={(e) => setMsgPreview(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 mb-2">
                    <label htmlFor="notes">
                      {t("chat.adverse.popup.notes")}
                    </label>
                    <textarea
                      name="notes"
                      className="form-control form-control-md"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={adverseEffectNotes}
                      onChange={(e) =>
                        setAdverseEffectNotes(e.currentTarget.value)
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-4 mb-2">
                    <label htmlFor="age">{t("chat.adverse.popup.age")}</label>
                    <input
                      name="age"
                      className="form-control form-control-md"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={age}
                      onChange={(e) => setAge(e.currentTarget.value)}
                    />
                  </div>
                  <div className="col-4 mb-2">
                    <label htmlFor="sex">{t("chat.adverse.popup.sex")}</label>
                    <input
                      name="sex"
                      className="form-control form-control-md"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={sex}
                      onChange={(e) => setSex(e.currentTarget.value)}
                    />
                  </div>
                  <div className="col-4 mb-2">
                    <label htmlFor="location">
                      {t("chat.adverse.popup.location")}
                    </label>
                    <input
                      name="location"
                      className="form-control form-control-md"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={location}
                      onChange={(e) => setLocation(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
            <div className="popup-footer d-flex">
              <button
                className={`btn btn-secondary mr-4 ml-4`}
                onClick={closePopup}
              >
                {t("common.cancel")}
              </button>
              <button
                className={`btn ${
                  isDisabled ? "btn-secondary" : "btn-primary"
                }`}
                onClick={submitForm}
                disabled={isDisabled || inProgress}
              >
                {t("common.submit")}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdverseEffectPopup);
