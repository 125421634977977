import ArticleList from "../ArticleList";
import React, { useState, useEffect } from "react";
import agent from "../../agent";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { useTranslation } from "react-i18next";
import ChatRecipients from "./ChatComponents/ChatRecipients";
import ChatThreadsView from "./ChatComponents/ChatThreadsView";
import SearchRecipients from "../SearchRecipients";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  allHcps: state.common.hcpUsers,
  recipientsArray: state.chat.chatRecipients,
  newIncomingMessage: state.common.newMessage,
  shareContentToHcp: state.chat.shareContentToHcp,
  searchChatRecipients: state.chat.searchChatRecipients,
  navigateToChatRecipient: state.chat.navigateToChatRecipient,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const MainView = (props) => {
  const {
    recipientsArray: actualList,
    selectedRecipient,
    setSelectedRecipient,
    newIncomingMessage,
    getRecipientsList,
    shareContentToHcp,
    navigateToChatRecipient,
    searchChatRecipients,
  } = props;
  const { t } = useTranslation();
  const [recipientsArray, setRecipientsArray] = useState(null);

  const updateRecipientsList = () => {
    getRecipientsList();
  };

  const onRecipientSelect = (item) => {
    // alert();
    setSelectedRecipient(item);
  };

  const chatRecipientsProps = {
    recipientsArray,
    selectedRecipient,
    onRecipientSelect,
  };

  const chatThreadProps = {
    recipientsArray,
    selectedRecipient,
    onRecipientSelect,
  };

  useEffect(() => {
    if (recipientsArray && recipientsArray.length) {
      if (shareContentToHcp && shareContentToHcp.hcp_id) {
        const shareToHcp = recipientsArray.filter(
          (item) => item.hcp.uid === shareContentToHcp.hcp_id
        );
        if (shareToHcp && shareToHcp.length) {
          setSelectedRecipient(shareToHcp[0]);
        } else {
          setSelectedRecipient(recipientsArray[0]);
        }
      } else if (navigateToChatRecipient && navigateToChatRecipient.hcp_id) {
        const goToHcp = recipientsArray.filter(
          (item) => item.hcp.uid === navigateToChatRecipient.hcp_id
        );
        if (goToHcp && goToHcp.length) {
          setSelectedRecipient(goToHcp[0]);
        } else {
          setSelectedRecipient(recipientsArray[0]);
        }
      } else {
        setSelectedRecipient(recipientsArray[0]);
      }
    }
  }, [recipientsArray]);

  useEffect(() => {}, [shareContentToHcp]);

  useEffect(() => {
    if (actualList && actualList.length) {
      setRecipientsArray(actualList);
    } else if (actualList && actualList.uid) {
      setRecipientsArray([{ ...actualList }]);
    }
  }, [actualList]);

  useEffect(() => {
    if (newIncomingMessage && actualList && actualList.length) {
      const { hcpId, threadId, ts } = newIncomingMessage;
      const isPresentInRecipientsArray = actualList.filter(
        (item) => item.hcp.uid === hcpId && item.thread.uid === threadId
      );
      if (isPresentInRecipientsArray && isPresentInRecipientsArray.length) {
        // TODO
      } else {
        updateRecipientsList();
      }
    } else {
      updateRecipientsList();
    }
  }, [newIncomingMessage]);

  useEffect(() => {
    if (searchChatRecipients && searchChatRecipients.length) {
      const indexedList = actualList.filter((item) => {
        if (item.hcp && item.hcp.fName && item.hcp.lName) {
          const nameString = `${item.hcp.fName}${item.hcp.lName}`;
          return (
            nameString
              .toLowerCase()
              .indexOf(searchChatRecipients.toLowerCase()) > -1
          );
        }
      });
      setRecipientsArray(indexedList || []);
    } else {
      if (actualList && actualList.length) {
        setRecipientsArray(actualList);
      }
    }
  }, [searchChatRecipients]);

  return (
    <div className="mainview-container">
      <div className="names-container">
        <div className="page-name">
          <h6 className="title">{`${t("connect.connect")} (${
            actualList ? actualList.length : "0"
          })`}</h6>
          <SearchRecipients />
        </div>
        <div className="names-list-container">
          <ChatRecipients {...chatRecipientsProps} />
        </div>
      </div>
      <div className="main-body-container">
        <ChatThreadsView {...chatThreadProps} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
