import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  SHOW_TRANSFER_CONVERSATION_POPUP,
  SHOW_TRANSFER_SUCCESS_POPUP,
} from "../../constants/actionTypes";
import SearchDropdown from "../SearchDropdown";
import agent from "../../agent";
import {ReactComponent as ConnectIcon} from "../../assets/icon_baloons.svg";

import "./TransferConversation.scss";

const mapStateToProps = (state) => ({
  ...state.auth,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  transferConversationPopup: state.chat.transferConversationPopup,
  transferSuccessPopup: state.chat.transferSuccess,
  currentUser: state.common.currentUser,
  transferablePeers: state.chat.transferablePeers,
});

const mapDispatchToProps = (dispatch) => ({
  closeTransferSuccessPopup: () =>
    dispatch({ type: SHOW_TRANSFER_SUCCESS_POPUP, payload: false }),
  transferChat: (formData) =>
    dispatch({
      type: SHOW_TRANSFER_CONVERSATION_POPUP,
      payload: agent.Chat.transferChat(formData),
    }),
});

const TransferSuccessPopup = (props) => {
  const { t } = useTranslation();
  const {
    transferConversationPopup,
    closeTransferSuccessPopup,
    transferablePeers,
    transferChat,
    transferSuccessPopup,
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const [colleague, setColleague] = useState("");
  const [noteToColleague, setNoteToColleague] = useState("");
  const [colleaguesList, setColleaguesList] = useState([]);

  const transfer = () => {
    const formData = {
      transferTo: colleague,
      message: noteToColleague,
      threadId: transferConversationPopup,
    };
    transferChat(formData);
    console.log(formData);
  };

  const getUserName = (id) => {
    if (transferablePeers && transferablePeers.length) {
      const user = transferablePeers.filter((item) => item.uid === id);
      return user[0].username;
    }
  };

  const submitForm = () => {
    transfer();
  };

  const closePopup = () => {
    if (closeTransferSuccessPopup) closeTransferSuccessPopup();
  };

  const resetForm = () => {
    // clear form data
    setColleague("");
    setNoteToColleague("");
  };

  useEffect(() => {
    resetForm();
  }, [transferConversationPopup]);

  useEffect(() => {
    if (colleague && noteToColleague && noteToColleague.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [colleague, noteToColleague]);

  useEffect(() => {
    if (transferablePeers) {
      setColleaguesList(transferablePeers);
    }
  }, [transferablePeers]);

  return (
    <React.Fragment>
      {transferSuccessPopup && (
        <div className="transfer-success-popup">
          <div className="form-container">
            <div className="close-button" onClick={closePopup}></div>
            <form>
              <div className="d-flex">
                <ConnectIcon className="mr-2" width="25" height="25" />
                <h5>{t("chat.transfer.success_information").replace("{x}", getUserName(transferSuccessPopup.transferTo))}</h5>
              </div>
              <h6>{t("chat.transfer.success_acknowledge_note")}</h6>
            </form>
            {/* <div className="popup-footer">
              <button className="btn btn-primary w-100" onClick={closePopup}>
                {t("chat.transfer.success_button_text")}
              </button>
            </div> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferSuccessPopup);
