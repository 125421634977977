import React, { useEffect } from "react";
import { connect } from "react-redux";
import MainView from "./MainView";
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  APPLY_TAG_FILTER,
  CURRENT_VIEW,
  LOGOUT,
} from "../../constants/actionTypes";

import "./ContentView.scss";

const Promise = global.Promise;

const mapStateToProps = (state) => ({
  ...state.home,
  appName: state.common.appName,
  currentUser: state.common.currentUser,
  token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => dispatch({ type: LOGOUT }),
  onClickTag: (tag, pager, payload) =>
    dispatch({ type: APPLY_TAG_FILTER, tag, pager, payload }),
  onLoad: (tab, pager, payload) =>
    dispatch({ type: HOME_PAGE_LOADED, tab, pager, payload }),
  onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
});

const ContentView = (props) => {
  const {  token, currentUser, onLoad, onViewChange, onUnload, onSignOut, inPopupView } = props;
  // componentWillMount() {
  //   const tab = props.token ? "feed" : "all";
  //   const articlesPromise = props.token
  //     ? agent.Articles.feed
  //     : agent.Articles.all;

  //   props.onLoad(
  //     tab,
  //     articlesPromise,
  //     Promise.all([agent.Tags.getAll(), articlesPromise()])
  //   );
  //   props.onViewChange({id: "1" ,name:"sideMenu.dashboard"});
  // }

  // componentWillUnmount() {
  //   props.onUnload();
  // }

  useEffect(() => {
    // if(!token && !currentUser){
    //   onSignOut();
    // }
    if(!inPopupView){
      onViewChange({ id: "3", name: "sideMenu.content" });
    }
    return () => {
      onUnload();
    };
  }, []);

  return (
    <div className="view-container content-view">
      <MainView inPopupView={inPopupView} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentView);
