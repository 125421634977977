import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  SHOW_TRANSFER_CONVERSATION_POPUP,
  TRANSFER_CONVERSATION,
} from "../../constants/actionTypes";
import SearchDropdown from "../SearchDropdown";
import agent from "../../agent";
import {ReactComponent as ConnectIcon} from "../../assets/icon_baloons.svg";

import "./TransferConversation.scss";

const mapStateToProps = (state) => ({
  ...state.auth,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  transferConversationPopup: state.chat.transferConversationPopup,
  currentUser: state.common.currentUser,
  transferablePeers: state.chat.transferablePeers,
});

const mapDispatchToProps = (dispatch) => ({
  closeTransferPopup: () =>
    dispatch({ type: SHOW_TRANSFER_CONVERSATION_POPUP, payload: false }),
  transferChat: (formData) =>
    dispatch({
      type: TRANSFER_CONVERSATION,
      payload: {
        response: agent.Chat.transferChat(formData),
        transferData: formData,
      },
    }),
});

const TransferConversation = (props) => {
  const { t } = useTranslation();
  const {
    transferConversationPopup,
    closeTransferPopup,
    inProgress,
    transferablePeers,
		transferChat,
		currentUser,
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const [colleague, setColleague] = useState("");
  const [colleagueName, setColleagueName] = useState("");
  const [noteToColleague, setNoteToColleague] = useState("");
  const [colleaguesList, setColleaguesList] = useState([]);

  const getUserName = (id) => {
    if (transferablePeers && transferablePeers.length) {
      const user = transferablePeers.filter((item) => item.uid === id);
      return user[0].username;
    }
  };

  const transfer = () => {
    const formData = {
      transferTo: colleague,
      message: noteToColleague,
      threadId: transferConversationPopup,
    };
    transferChat(formData);
    console.log(formData);
  };

  const submitForm = () => {
    transfer();
  };

  const closePopup = () => {
    if (closeTransferPopup) closeTransferPopup();
  };

  const resetForm = () => {
    // clear form data
    setColleague("");
    setNoteToColleague("");
  };

  useEffect(() => {
    resetForm();
  }, [transferConversationPopup]);

  useEffect(() => {
    if (colleague && noteToColleague && noteToColleague.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [colleague, noteToColleague]);

  useEffect(() => {
    if (transferablePeers && transferablePeers.length && currentUser) {
			const filteredList = transferablePeers.filter(item => item.uid !== currentUser.uid);
      setColleaguesList(filteredList);
    }
  }, [transferablePeers]);

  useEffect(() => {
    if (colleague) {
      const name = getUserName(colleague);
      if (name) {
        setColleagueName(name);
      }
    }
  }, [colleague]);

  return (
    <React.Fragment>
      {transferConversationPopup && (
        <div className="transfer-conversation-popup">
          <div className="form-container">
            <div className="popup-header pt-4">
              <ConnectIcon className="mr-2" width="25" height="25" />
              <h5>{t("chat.transfer.title")}</h5>
              <div className="close-button" onClick={closePopup}></div>
            </div>
            <form>
              <fieldset>
                <div className="form-group row">
                  <div className="col-12 mb-2">
                    <SearchDropdown
                      // preSelectId={}
                      onChange={setColleague}
                      options={
                        colleaguesList && colleaguesList.length
                          ? colleaguesList
                          : null
                      }
                      placeholder={t(
                        "chat.transfer.transferee.input_placeholder_text"
                      )}
                      labelProp="username"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 mb-2">
                    <textarea
                      name="name"
                      className="form-control form-control-md"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={noteToColleague}
                      onChange={(e) =>
                        setNoteToColleague(e.currentTarget.value)
                      }
                    />
                    {colleagueName && (
                      <div className="transfer-warning">
                        {t("chat.transfer.transfer_warning_message").replace(
                          "{x}",
                          colleagueName
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </fieldset>
            </form>
            <div className="popup-footer">
              <button
                className={`btn ${
                  isDisabled ? "btn-secondary" : "btn-primary"
                } w-100`}
                onClick={submitForm}
                disabled={isDisabled || inProgress}
              >
                {t("chat.transfer.transfer_button_text")}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferConversation);
