import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import agent from "../../agent";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { CHANGE_TAB, CURRENT_VIEW } from "../../constants/actionTypes";
import { SIDE_MENU } from "../../constants/sideMenuConstants";
import SignOut from "../SignOut";
import MenuItem from "./MenuItem";

import "./SideMenu.scss";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  viewId: state.common.viewId,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
});

const MenuList = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    data,
    containerClass,
    currentView,
    linkClickHandler,
    userRoleId,
  } = props;
  // const [showSubMenu, setShowSubMenu] = useState(null);
  const onLinkClick = (linkData) => {
    history.push(linkData.link);
    if (linkClickHandler) {
      linkClickHandler(linkData);
    }
  };
  // const onSecondaryIconClick = clickedItem => {
  //   setShowSubMenu(clickedItem.id);
  // };

  const menuItemProps = {
    onLinkClick,
    currentView,
  };

  return (
    <ul className={containerClass}>
      {data &&
        data.length &&
        data.map((item) => {
          let listItem;
          const { accessor } = item;
          if (accessor && accessor.length) {
            if (userRoleId && accessor.indexOf(userRoleId) !== -1) {
              listItem = <MenuItem data={item} {...menuItemProps} />;
            } else {
              listItem = null;
            }
          } else {
            listItem = <MenuItem data={item} {...menuItemProps} />;
          }
          return listItem;
        })}
    </ul>
  );
};

const SideMenu = (props) => {
  const { onViewChange, viewName, viewId, currentUser } = props;
  // const [currentView, setCurrentView] = useState("");
  const [currentUserRoleId, setCurrentUserRoleId] = useState(null);
  const { PRIMARY_MENU, SECONDARY_MENU } = SIDE_MENU;

  useEffect(() => {
    if (currentUser && currentUser.roleId) {
      setCurrentUserRoleId(currentUser.roleId);
    }
  }, [currentUser]);

  return (
    <div className="sidemenu-container">
      <MenuList
        userRoleId={currentUserRoleId ? currentUserRoleId : null}
        data={PRIMARY_MENU}
        containerClass="primary-menu"
        currentView={viewId}
        linkClickHandler={onViewChange}
      />
      <MenuList
        userRoleId={currentUserRoleId ? currentUserRoleId : null}
        data={SECONDARY_MENU}
        containerClass="secondary-menu"
        currentView={viewId}
        linkClickHandler={onViewChange}
      />
      <SignOut name="sideMenu.signout" />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
