import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import agent from "../../../agent";
import UnReadCount from "./UnReadCount";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  newMessage: state.common.newMessage,
  chatThreads: state.chat.chatThreads,
});

const mapDispatchToProps = (dispatch) => ({
  getMessages: (ids) => {
    agent.Chat.getMessages(ids);
  },
});

const Recipient = (props) => {
  const {
    data,
    getMessages,
    selectedRecipient,
    onRecipientSelect,
    newMessage,
    chatThreads,
  } = props;
  const { thread } = data;
  const { t } = useTranslation();
  const [latestMessages, setLatestMessage] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [previousConns, setPreviousConns] = useState();
  const [upcomingConns, setUpcomingConns] = useState();

  const updateStatus = () => {
    if (chatThreads && chatThreads.length && thread && thread.uid) {
      const currentThread = chatThreads.filter((item) => {
        return item.id === thread.uid;
      });
      if (
        currentThread &&
        currentThread.length &&
        currentThread[0].chats &&
        currentThread[0].chats.length
      ) {
        const lastMsg =
          currentThread[0].chats[currentThread[0].chats.length - 1];
        setLatestMessage(lastMsg);

        const prev = currentThread[0].chats.filter((item) => {
          return (
            item.messageType !== 18 &&
            item.messageType !== 19 &&
            item.messageType !== 6 &&
            item.messageType !== 12 &&
            item.messageType !== 15
          );
        });
        const next = currentThread[0].chats.filter((item) => {
          return (
            item.messageType === 6 &&
            new Date(item.udf2).getTime() > new Date().getTime()
          );
        });
        if (prev && prev.length) {
          if(prev.length > 4){
            const prev5 = prev.slice(prev.length - 5, prev.length);
            setPreviousConns(prev5);
          }else{
            setPreviousConns(prev);
          }
          
        }
        if (next && next.length) {
          if(next.length > 4){
            const next5 = next.slice(next.length - 5, next.length);
            setUpcomingConns(next5);
          }else{
            setUpcomingConns(next);
          }
          
        }
      }
    }
  };

  useEffect(() => {
    updateStatus();
  }, [newMessage, chatThreads, data]);

  return (
    <li
      className={
        selectedRecipient && selectedRecipient.hcp.uid === data.hcp.uid
          ? "names-item selected"
          : "names-item"
      }
      key={data.hcp.uid}
      onClick={() => onRecipientSelect(data)}
    >
      <div className="recipient">
        <div className="recipient-name-row">
          <div>{`${data.hcp.fName} ${data.hcp.lName}` || ""}</div>
          <h6
            title={
              latestMessages && latestMessages.ts && latestMessages.ts
                ? moment.utc(latestMessages.ts).local().fromNow()
                : ""
            }
          >
            {latestMessages && latestMessages.ts && latestMessages.ts
              ? moment.utc(latestMessages.ts).local().fromNow()
              : ""}
          </h6>
        </div>
        <div className="message-preview">
          <div className="message">
            {latestMessages && latestMessages.content
              ? latestMessages.content
              : ""}
          </div>
          <UnReadCount threadId={data.thread.uid} />
        </div>
        {selectedRecipient && selectedRecipient.hcp.uid === data.hcp.uid && (
          <div className="extras">
            <div onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? (
                <small>{t("chat.history.hide_details")}</small>
              ) : (
                <small>{t("chat.history.conn_details")}</small>
              )}
            </div>
            {showDetails && (
              <React.Fragment>
                <hr className="mt-2 mb-2"></hr>
                <div className="connection-details">
                  {previousConns && previousConns.length && (
                    <div>
                      <small>{t("chat.history.prev_conn")}</small>
                      {previousConns.map((item) => {
                        return (
                          <p key={item.uid} title={item && item.content && item.content.length > 0 ? item.content : item.attachmentUrl}>
                            {moment(item.ts).format("ll")} - {item && item.content && item.content.length > 0 ? item.content : item.attachmentUrl}
                          </p>
                        );
                      })}
                    </div>
                  )}
                  {upcomingConns && upcomingConns.length && (
                    <div>
                      <small>{t("chat.history.upcoming_conn")}</small>
                      {upcomingConns.map((item) => {
                        return (
                          <p key={item.uid} title={item.content}>
                            {moment(item.ts).format("ll")} - {item.content}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Recipient);
