import superagentPromise from "superagent-promise";
import _superagent from "superagent";

const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT = process.env.REACT_APP_BACKEND;
// const API_ROOT = 'https://ws.consiliumdev.com';
// const API_ROOT = 'http://122.160.12.75:3002';
const API_ROOT = 'https://dev.consiliumdev.com/api';

const encode = encodeURIComponent;
const responseBody = (res) => res.body;

let token = null;
const tokenPlugin = (req) => {
  if (token) {
    req.set("authorization", `Bearer ${token}`);
  }
};

const requests = {
  del: (url) =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: (url) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
};

const Auth = {
  current: () => requests.get("/account/info"),
  login: (username, password) =>
    requests.post("/account/login", { username, password }),
  register: (username, email, password) =>
    requests.post("/users", { user: { username, email, password } }),
  save: (user) => requests.put("/user", { user }),
  logout: () => requests.get("/account/logout"),
  resetPassword: (confirmEmail, username, confirmDob = "none") =>
    requests.post("/account/resetPassword", {
      username,
      confirmEmail,
      confirmDob,
    }),
  confirmChangePassword: (userid, resetToken, password) =>
    requests.post("/account/updateresetPassword", {
      userid,
      token: resetToken,
      password,
    }),
};

const Roles = {
  getList: () => requests.get("/account/getAllRoles"),
};

const Orgs = {
  getList: () => requests.get("/admin/getAllOrg"),
  createOrg: (formData) => requests.post("/admin/addOrg", formData),
  updateOrg: (formData) => requests.post("/admin/updateOrg", formData),
  deleteOrg: (deleteId) => requests.get(`/admin/deleteOrg/${deleteId}`),
  getTemplates: (formData) => requests.post("/admin/getTemplates", formData),
  updateTemplate: (formData) =>
    requests.post("/admin/updateTemplate", formData),
  availableProperties: (formData) =>
    requests.post("/admin/isAvailableInOrgs", formData),
};

const Users = {
  getList: () => requests.get("/admin/getAllUser"),
  createUser: (formData) => requests.post("/admin/addUser", formData),
  updateUser: (formData) => requests.post("/admin/updateUser", formData),
  deleteUser: (deleteId) => requests.get(`/admin/deleteUser/${deleteId}`),
  createHcp: (formData) => requests.post("/admin/addHcp", formData),
  updateHcp: (formData) => requests.post("/admin/updateHcp", formData),
  getHcpList: () => requests.get("/admin/getAllHcp"),
  deleteHcp: (deleteId) => requests.get(`/admin/deleteHcp/${deleteId}`),
  availableProperties: (formData) =>
    requests.post("/admin/isAvailableInUsers", formData),
};

const Measure = {
  noOfHCPAcrossOrganization: (formData) =>
    requests.post("/admin/getHCPStatisticsSales", formData),
  getHCPChatWithSales: (formData) =>
    requests.post("/admin/getHCPChatWithSales", formData),
  getHCPChatWithHCPMSLUserLevel: (formData) =>
    requests.post("/admin/getHCPChatWithSalesMSLevel", formData),
  getContentAnalytics: (formData) =>
    requests.post("/admin/contentAnalytics", formData),
  getNoOfConversionTransfers: (formData) =>
    requests.post("/admin/getNumberOfConversationTransfers", formData),
  getNumberOfUsersInOrg: (formData) =>
    requests.post("/admin/getNumberOfUsersInOrganizationMSLLevel", formData),
  getNumberOfActiveHCP: (formData) =>
    requests.post("/admin/getNoOfTotalActiveHCPRecords", formData),
  getTotalNmberOfOrgs: (formData) =>
    requests.post("/admin/totalNoOfOrganizations", formData),
  getTotalNmberOfHCPS: (formData) =>
    requests.post("/admin/totalNoOfHCP", formData),
  getTotalNmberOfSales: (formData) =>
    requests.post("/admin/totalNumberOfSales", formData),
  getTotalNumberOfMSL: (formData) =>
    requests.post("/admin/totalNumberOfMSL", formData),
};

const Dashboard = {
  getDashboardData: () => requests.get("/connect/getDashboardDetails"),
};

const Chat = {
  sendMessage: (message) => requests.post("/connect/sendMessageToHcp", message),
  getReceipients: () => requests.get("/connect/getMyOpenThreads"),
  getMessages: (formData) =>
    requests.post("/connect/getChatMessages", formData),
  getTransferablePeers: () => requests.get("/connect/getTransferablePeers"),
  transferChat: (formData) => requests.post("/connect/transferChat", formData),
  acceptTransfer: (formData) =>
    requests.post("/connect/acceptTransfer", formData),
  rejectTransfer: (formData) =>
    requests.post("/connect/rejectTransfer", formData),
  closeThread: (formData) => requests.post("/connect/closeThread", formData),
  updateMsgReadStatus: (formData) =>
    requests.post("/connect/updateChatReadStatus", formData),
  generateAuthToken: (formData) =>
    requests.post("/connect/refreshAuthToken", formData),
  getHcpByAgentId: (formData) =>
    requests.post("/connect/findHcpByAgentId", formData),
  getAdverseEffectDetailsById: (msgId) =>
    requests.post("/connect/getAdverseEffectDetailsByMsgId", { msgId }),
  addAdverseEffectDetailsByMsgId: (formData) =>
    requests.post("/connect/addAdverseEffectDetailsByMsgId", formData),
  deactivateThread: (formData) =>
    requests.post("/connect/deactivateThread", formData),
  activateThread: (formData) =>
    requests.post("/connect/activateThread", formData),
};

const Schedule = {
  getSchedulesList: () => requests.get("/connect/getMyAllSchedule"),
};

const Tags = {
  getAll: () => requests.get("/tags"),
};

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlug = (article) => Object.assign({}, article, { slug: undefined });
const Articles = {
  all: (page) => requests.get(`/articles?${limit(10, page)}`),
  byAuthor: (author, page) =>
    requests.get(`/articles?author=${encode(author)}&${limit(5, page)}`),
  byTag: (tag, page) =>
    requests.get(`/articles?tag=${encode(tag)}&${limit(10, page)}`),
  del: (slug) => requests.del(`/articles/${slug}`),
  favorite: (slug) => requests.post(`/articles/${slug}/favorite`),
  favoritedBy: (author, page) =>
    requests.get(`/articles?favorited=${encode(author)}&${limit(5, page)}`),
  feed: () => requests.get("/articles/feed?limit=10&offset=0"),
  get: (slug) => requests.get(`/articles/${slug}`),
  unfavorite: (slug) => requests.del(`/articles/${slug}/favorite`),
  update: (article) =>
    requests.put(`/articles/${article.slug}`, { article: omitSlug(article) }),
  create: (article) => requests.post("/articles", { article }),
};

const Comments = {
  create: (slug, comment) =>
    requests.post(`/articles/${slug}/comments`, { comment }),
  delete: (slug, commentId) =>
    requests.del(`/articles/${slug}/comments/${commentId}`),
  forArticle: (slug) => requests.get(`/articles/${slug}/comments`),
};

const Profile = {
  follow: (username) => requests.post(`/profiles/${username}/follow`),
  get: (username) => requests.get(`/profiles/${username}`),
  unfollow: (username) => requests.del(`/profiles/${username}/follow`),
};

const content = {
  add: (file) => requests.post(`/admin/saveContent`, file),
  addVideo: (file) => requests.post(`/admin/saveContent`, file),
  addText: (file) => requests.post(`/admin/saveContent`, file),
  getDocument: (page, sort, search) =>
    requests.get(
      `/admin/getContent?media_type=11&orderBy=${sort}&${limit(
        13,
        page
      )}&search=${search}`
    ),
  getVideo: (page, sort, search) =>
    requests.get(
      `/admin/getContent?media_type=3&orderBy=${sort}&${limit(
        9,
        page
      )}&search=${search}`
    ),
  getMessage: (page, sort, search) =>
    requests.get(
      `/admin/getContent?media_type=1&orderBy=${sort}&${limit(
        100,
        page
      )}&search=${search}`
    ),
  deleteContent: (id) => requests.del(`/admin/deleteContent/${id}`),
};

export default {
  Articles,
  Auth,
  Roles,
  Orgs,
  Users,
  Dashboard,
  Chat,
  Schedule,
  Comments,
  Profile,
  Tags,
  content,
  setToken: (_token) => {
    token = _token;
  },
  Measure,
};
// Testing Prod CI/CD
