import { Link } from "react-router-dom";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import agent from "../../../agent";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
});

const mapDispatchToProps = (dispatch) => ({});

const HcpProvider = (props) => {
  const { selectedRecipient } = props;
  const { t } = useTranslation();

  return (
    <div className="provider-container">
      <div className="profile-column">
        <div className="provider text-uppercase">
          {t("connect.provider_profile")}
          <div className="icon icon-provider"></div>
        </div>
        <div className="name">{selectedRecipient.name}</div>
        <div className="address">
          <div className="icon icon-location"></div>
          {selectedRecipient.address}
        </div>
        <div className="phone">
          <div className="icon icon-phone"></div>
          {selectedRecipient.phone}
        </div>
      </div>
      <div className="activity-column">
        <div className="title">{t("connect.your_activity_with_provider")}</div>
        <div className="previous">
          <div className="title-previous font-weight-normal">
            {t("connect.previous")}
          </div>
          <div className="pre-item font-weight-light">
            22 APR 20 -{t("connect.shared_pdf_doc_title").replace("{x}", "X")}
          </div>
          <div className="pre-item">
            21 APR 20 - {t("connect.text_conversation")}
          </div>
        </div>
        <div className="upcoming">
          <div className="title-upcoming font-weight-normal">
            {t("connect.upcoming")}
          </div>
          <div className="up-item font-weight-light">
            15 JUL 20 - {t("connect.audio_appointment")}, 9:30 EDT
          </div>
        </div>
      </div>
      <div className="empty-column"></div>
      <div className="controls-column">
        {/* <div className="icon icon-phone"></div>
                  <div className="icon icon-video"></div> */}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpProvider);
