import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { CONFIRM_BOX_OPEN } from "../constants/actionTypes";

import "./styles/ConfirmPopup.scss";

const mapStateToProps = (state) => ({
  isConfirmBoxOpen: state.common.isConfirmBoxOpen
});

const mapDispatchToProps = (dispatch) => ({
  onConfirmBoxOpen: () =>
    dispatch({ type: CONFIRM_BOX_OPEN, payload: true }),
});

const ConfirmPopup = (props) => {
  const { t } = useTranslation();
  const {
    inProgress,
    onConfirm,
    onCancel,
    onDestroy,
    primaryButtonText = "common.yes",
    secondaryButtonText = "common.no",
    confirmTitle = "common.confirm",
    confirmMessage = "",
  } = props;

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  };

  useEffect(() => {
    return () => {
      if (onDestroy) onDestroy();
    };
  }, []);

  return (
    <React.Fragment>
      <div className="confirm-popup">
        <div className="popup-container">
          <div className="popup-header">
            <h5>{t(confirmTitle)}</h5>
          </div>
          <div className="popup-body">{t(confirmMessage)}</div>
          <div className="popup-footer">
            <button className="btn btn-secondary ml-4" onClick={handleCancel}>
              {t(secondaryButtonText)}
            </button>
            <button
              className="btn btn-primary ml-4"
              onClick={handleConfirm}
              disabled={inProgress ? inProgress : false}
            >
              {t(primaryButtonText)}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPopup);
