import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainView from "./MainView";
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  APPLY_TAG_FILTER,
  CURRENT_VIEW,
  LOGOUT,
  GET_ROLES_LIST,
  GET_ORGS_LIST,
  GET_USERS_LIST,
  GET_HCP_USERS_LIST,
} from "../../constants/actionTypes";
import agent from "../../agent";
import CreateEditUser from "../CreatEditUser";
import ConfirmPopup from "../ConfirmPopup";

import "./UsersView.scss";

const Promise = global.Promise;

const mapStateToProps = (state) => ({
  ...state.home,
  appName: state.common.appName,
  currentUser: state.common.currentUser,
  token: state.common.token,
  users: state.common.users,
  hcpUsers: state.common.hcpUsers,
  orgs: state.common.orgs,
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => dispatch({ type: LOGOUT }),
  onClickTag: (tag, pager, payload) =>
    dispatch({ type: APPLY_TAG_FILTER, tag, pager, payload }),
  onLoad: (tab, pager, payload) =>
    dispatch({ type: HOME_PAGE_LOADED, tab, pager, payload }),
  onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
  updateRolesList: () =>
    dispatch({ type: GET_ROLES_LIST, payload: agent.Roles.getList() }),
  updateOrgsList: () =>
    dispatch({ type: GET_ORGS_LIST, payload: agent.Orgs.getList() }),
  updateUsersList: () =>
    dispatch({ type: GET_USERS_LIST, payload: agent.Users.getList() }),
  updateHcpUsersList: () =>
    dispatch({ type: GET_HCP_USERS_LIST, payload: agent.Users.getHcpList() }),
});

const UsersView = (props) => {
  const {
    token,
    currentUser,
    onLoad,
    onViewChange,
    onUnload,
    onSignOut,
    updateRolesList,
    updateOrgsList,
    updateUsersList,
    updateHcpUsersList,
    users = [],
    hcpUsers = [],
  } = props;

  const [deletePopupObj, setDeletePopupObj] = useState(null);
  const [usersArray, setUsersArray] = useState();
  const [hcpsArray, setHcpsArray] = useState();

  const filterUsersBasedOnOrg = (list) => {
    let listItems;
    if (currentUser && currentUser.roleId === 1) {
      // const usersList = listData.filter(item => currentUser && currentUser.orgId === item.orgId);
      if(list){
        listItems = list;
      }
    } else if (currentUser && currentUser.roleId === 2) {
      listItems = list.filter(
        (item) => currentUser && currentUser.orgId === item.orgId
      );
    }
    return listItems;
  };

  useEffect(() => {
    // if(!token && !currentUser){
    //   onSignOut();
    // }
    updateRolesList();
    updateOrgsList();
    updateHcpUsersList();
    updateUsersList();
    onViewChange({ id: "6", name: "sideMenu.users" });
    return () => {
      onUnload();
    };
  }, []);

  useEffect(() => {
    const newUserList = filterUsersBasedOnOrg(users);
    if(newUserList && newUserList.length){
      setUsersArray([...newUserList]);
    } else {
      setUsersArray([]);
    }
  }, [users]);

  useEffect(() => {
    const newHcpList = filterUsersBasedOnOrg(hcpUsers);
    if(newHcpList && newHcpList.length){
      setHcpsArray([...newHcpList]);
    }else{
      setHcpsArray([]);
    }
  }, [hcpUsers]);

  return (
    <div className="view-container users-view">
      <MainView usersArray={usersArray} hcpsArray={hcpsArray} showDeletePopup={setDeletePopupObj} />
      <CreateEditUser usersArray={usersArray}/>
      {deletePopupObj && <ConfirmPopup {...deletePopupObj} />}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersView);
