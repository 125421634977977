import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainView from "./MainView";
import "./Measure.scss";

const Measure = () => {
  return (
    <div className="view-container measure-view">
      <MainView />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Measure);
