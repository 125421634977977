import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./SideMenu.scss";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const MenuItem = (props) => {
  const { data, onLinkClick, currentView } = props;
  const { t } = useTranslation();
  const [showSubMenu, setShowSubMenu] = useState(false);

  return (
    <React.Fragment>
      <li
        className={`menu-item ${
          currentView && currentView === data.id ? `selected` : "not-selected"
        } ${showSubMenu ? "expanded" : "collapsed"}`}
      >
        <div className="item">
          <div
            className={`menu-icon icon-${data.icon}`}
            onClick={() => onLinkClick(data)}
          ></div>

          <div
            className="menu-name text-capitalize"
            onClick={() => onLinkClick(data)}
          >
            {t(data.name)}
          </div>

          <div
            onClick={() => setShowSubMenu(!showSubMenu)}
            className={`menu-icon-secondary icon-${data.icon_secondary}`}
          ></div>
        </div>
        {data.submenu && data.submenu.length && showSubMenu && (
          <ul className="submenu">
            {data.submenu.map((subitem) => {
              return (
                <li
                  className={`submenu-item ${
                    currentView && currentView === subitem.id
                      ? `selected`
                      : "not-selected"
                  }`}
                >
                  <div className="sub-item">
                    <div
                      className={`submenu-icon icon-${subitem.icon}`}
                      onClick={() => onLinkClick(subitem)}
                    ></div>
                    <div
                      className="submenu-name text-capitalize"
                      onClick={() => onLinkClick(subitem)}
                    >
                      {t(subitem.name)}
                    </div>
                    <div
                      className={`submenu-icon-secondary icon-${subitem.icon_secondary}`}
                    ></div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </li>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
