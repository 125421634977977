import { Link } from "react-router-dom";
import ListErrors from "./ListErrors";
import React, { useEffect, useState } from "react";
import agent from "../agent";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  UPDATE_FIELD_AUTH,
  RESET_PASSWORD,
  LOGIN_PAGE_UNLOADED,
} from "../constants/actionTypes";
import {ReactComponent as Logo} from "../assets/logo_black.svg";
import LanguageSelector from "./LanguageSelector";

import "./styles/Forgot.scss";

const mapStateToProps = (state) => ({
  ...state.auth,
  resetSuccess: state.common.resetSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeEmail: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "email", value }),
  onChangeUsername: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "username", value }),
  onSubmit: (email, username) =>
    dispatch({
      type: RESET_PASSWORD,
      payload: agent.Auth.resetPassword(email, username),
    }),
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
});

const Forgot = (props) => {
  const { errors, onSubmit, onUnload, inProgress, resetSuccess } = props;

  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const submitForm = () => {
    onSubmit(email, username);
  };

  useEffect(() => {
    return () => {
      onUnload();
    };
  }, []);
  return (
    <div className="forgot auth-page">
      <div className="container page">
        <LanguageSelector />
        <div className="row">
          <div className="col-md-12">
            <div className="brand-name">
              <Logo style={{ width: "100%" }} />
              {/* <h4 className="text-xs-left ">Consilium</h4> */}
            </div>

            <ListErrors errors={errors} />

            <div>
              <h5>{t("login.resetPassword.title")}</h5>
              <fieldset>
                <fieldset className="form-group">
                  <input
                    className="form-control form-control-md"
                    type="email"
                    placeholder={t("login.placeholder.email")}
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                  />
                </fieldset>

                <fieldset className="form-group">
                  <input
                    className="form-control form-control-md"
                    type="text"
                    placeholder={t("login.placeholder.username")}
                    value={username}
                    onChange={(ev) => setUsername(ev.target.value)}
                  />
                </fieldset>
                {resetSuccess ? (
                  <h6>{t("login.resetPassword.resetLinkSuccessMessage")}</h6>
                ) : (
                  <button
                    className="login-button btn btn-md pull-md-right"
                    onClick={() => submitForm()}
                    disabled={inProgress}
                  >
                    {t("login.resetPassword.buttonText")}
                  </button>
                )}
                <h6 className="mt-4 text-right">
                  <Link to="/login">{t("login.buttonText")}</Link>
                </h6>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
