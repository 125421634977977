import { Link } from "react-router-dom";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { CHANGE_TAB, CURRENT_VIEW } from "../constants/actionTypes";

const mapStateToProps = (state) => ({
  // ...state.articleList,
  // tags: state.home.tags,
  // token: state.common.token,
  // viewName: state.common.viewName,
});

const mapDispatchToProps = (dispatch) => ({
  // onTabClick: (tab, pager, payload) =>
  //   dispatch({ type: CHANGE_TAB, tab, pager, payload }),
  // onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
});

const NoMatch = (props) => {
  const { t } = useTranslation();
  // const { onViewChange, viewName } = props;
  // const [currentView, setCurrentView] = useState("");

  return (
    <div className="no-match-container">
      <div>{t("404 not found", "404 not found")}</div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NoMatch);
