import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import agent from "../../agent";
import MainView from "./MainView";
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  APPLY_TAG_FILTER,
  CURRENT_VIEW,
  LOGOUT,
  GET_HCP_USERS_LIST,
  GET_CHAT_RECIPIENTS_LIST,
} from "../../constants/actionTypes";

import "./ConnectView.scss";
import TransferConversation from "./TransferConversation";
import TransferSuccessPopup from "./TransferSuccessPopup";
import AdverseEffectPopup from "./AdverseEffectPopup";

const Promise = global.Promise;

const mapStateToProps = (state) => ({
  ...state.home,
  appName: state.common.appName,
  currentUser: state.common.currentUser,
  token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => dispatch({ type: LOGOUT }),
  onClickTag: (tag, pager, payload) =>
    dispatch({ type: APPLY_TAG_FILTER, tag, pager, payload }),
  onLoad: (tab, pager, payload) =>
    dispatch({ type: HOME_PAGE_LOADED, tab, pager, payload }),
  onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
  getHcpsList: () =>
    dispatch({ type: GET_HCP_USERS_LIST, payload: agent.Users.getHcpList() }),
  getRecipientsList: () =>
    dispatch({
      type: GET_CHAT_RECIPIENTS_LIST,
      payload: agent.Chat.getReceipients(),
    }),
});

const ConnectView = (props) => {
  const {
    token,
    currentUser,
    onLoad,
    onViewChange,
    onUnload,
    onSignOut,
    getHcpsList,
    getRecipientsList,
  } = props;
  const { uid } = currentUser;
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  // componentWillMount() {
  //   const tab = props.token ? "feed" : "all";
  //   const articlesPromise = props.token
  //     ? agent.Articles.feed
  //     : agent.Articles.all;

  //   props.onLoad(
  //     tab,
  //     articlesPromise,
  //     Promise.all([agent.Tags.getAll(), articlesPromise()])
  //   );
  //   props.onViewChange({id: "1" ,name:"sideMenu.dashboard"});
  // }

  // componentWillUnmount() {
  //   props.onUnload();
  // }

  useEffect(() => {
    // if(!token && !currentUser){
    //   onSignOut();
    // }
    onViewChange({ id: "2", name: "sideMenu.connect" });
    // getHcpsList();
    // getRecipientsList();
    return () => {
      onUnload();
    };
  }, [uid]);

  const mainViewProps = {
    selectedRecipient,
    setSelectedRecipient,
    getRecipientsList,
  };

  return (
    <div className="view-container connect-view">
      {uid && (
        <React.Fragment>
          <MainView {...mainViewProps} />
          <TransferConversation />
          <TransferSuccessPopup />
          <AdverseEffectPopup />
        </React.Fragment>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectView);
