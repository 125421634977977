import { Link } from "react-router-dom";
import React, { useState } from "react";
import agent from "../agent";
import { connect } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CHANGE_TAB,
  CURRENT_VIEW,
  SHOW_CREATE_EDIT_USER_POPUP,
  SHOW_CREATE_EDIT_ORG_POPUP,
} from "../constants/actionTypes";
import { ReactComponent as Logo } from "../assets/logo_white.svg";
import { SIDE_MENU } from "../constants/sideMenuConstants";
import SearchRecipients from "./SearchRecipients";
import LanguageSelector from "./LanguageSelector";
import { ReactComponent as UserIconCircle } from "../assets/icon_user_circle.svg";

import "./styles/Header.scss";
import BellNotifyNewMessages from "./ConnectView/ChatComponents/BellNotifyNewMessages";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  currentUser: state.common.currentUser,
  currentOrg: state.common.currentOrg,
  chatThreads: state.chat.chatThreads,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
  showUserPopup: () =>
    dispatch({ type: SHOW_CREATE_EDIT_USER_POPUP, payload: true }),
  showOrgPopup: () =>
    dispatch({ type: SHOW_CREATE_EDIT_ORG_POPUP, payload: true }),
});

const LoggedInView = (props) => {
  const {currentOrg, currentUser} = props;
  const { uid, username, orgId, roleId } = currentUser;
  if (props.currentUser) {
    return (
      <div className="header-right pull-xs-right">
        <div className="header-item  new-msg-notify d-flex align-items-center justify-content-center">
          {/* <Link to="/connect" className="nav-link">
            <div className="icon icon-bell">
              <div className="icon-inset orange-dot"></div>
            </div>
          </Link> */}
          <BellNotifyNewMessages />
        </div>
        <div className="header-item ml-2 d-flex align-items-center justify-content-center">
          <LanguageSelector />
        </div>
        <div className="user-name ml-4 d-flex align-items-center justify-content-center text-white">
          <small className="user-org-name" title={`${username} (${roles[roleId].name}) \n ${currentOrg?currentOrg.name:""}`}>
            <span>
              {username} {`(${roles[roleId].name})`}
            </span>
            <bold>{currentOrg ? currentOrg.name : ""}</bold>
          </small>
          <div className="ml-2 d-flex">
            <UserIconCircle />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const roles = {
  1: { name: "Super Admin" },
  2: { name: "Org Admin" },
  3: { name: "Sales Rep" },
  4: { name: "MHL" },
};

const Header = (props) => {
  const { t } = useTranslation();
  const { currentUser, showUserPopup, showOrgPopup, chatThreads, currentOrg } = props;
  // const { uid, username, orgId, roleId } = currentUser;
  return (
    <div className="header-container">
      <Link to="/" className="brand">
        <div className="header-brand-name">
          {/* <div className="logo"></div> */}
          <Logo />
          {/* <h4 className="text-xs-left ">{props.appName.toLowerCase()}</h4> */}
        </div>
        {/* <div className="user-name">
          <small title={`${username} (${roles[roleId].name})`}>
            {username} {`(${roles[roleId].name})`}
          </small>
        </div> */}
      </Link>

      <div className="page-name">
        <div className="name-block">
          {/* <h6 className="text-capitalize">{t(props.viewName)}</h6> */}
        </div>
        <div className="control-block">
          {/* <Switch>
            <Route path="/users">
              <div className="add-user" onClick={() => showUserPopup()}></div>
            </Route>
            <Route path="/organizations">
              <div className="add-user" onClick={() => showOrgPopup()}></div>
            </Route>
            <Route path="/connect">
              <SearchRecipients />
            </Route> 
          </Switch> */}
        </div>
      </div>

      <div className="empty-column"></div>

      <LoggedInView currentUser={currentUser} currentOrg={currentOrg} />
    </div>
  );
};

// export default Header;
export default connect(mapStateToProps, mapDispatchToProps)(Header);
