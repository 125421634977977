import agent from "../agent";
import { toast } from "react-toastify";
import chatMiddleware from "./chatMiddleware";
import socketMiddleware from "./socketMiddleware";
import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
  REGISTER,
  CREATE_USER,
  UPDATE_USER,
  GET_USERS_LIST,
  GET_HCP_USERS_LIST,
  GET_ORGS_LIST,
  SHOW_CREATE_EDIT_USER_POPUP,
  SHOW_CREATE_EDIT_ORG_POPUP,
  CREATE_ORG,
  UPDATE_ORG,
  DELETE_ORG,
  DELETE_USER,
  SEND_MEETING_INVITE,
  MEETING_INVITE_SUCCESS,
  GET_APPOINTMENTS_LIST,
  UPDATE_AUTH_TOKEN,
  APP_LOAD,
} from "../constants/actionTypes";
import { notifySuccess, notifyError } from "./notification";

const promiseMiddleware = (store) => (next) => (action) => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });
    window.localStorage.setItem("LASTCALL", new Date().getTime());
    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      (res) => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        console.log("RESULT", res);
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);
      },
      (error) => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        console.log("ERROR", error);
        action.error = true;
        action.payload =
          error && error.response && error.response.body
            ? error.response.body
            : error.response || error;
        if (!action.skipTracking) {
          store.dispatch({ type: ASYNC_END, promise: action.payload });
        }
        // notifyError(action);
        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = (store) => (next) => (action) => {
  if (action.type === REGISTER || action.type === LOGIN) {
    if (!action.error) {
      window.localStorage.setItem("jwt", action.payload.data.token);
      window.localStorage.setItem("LSLT", new Date().getTime());
      agent.setToken(action.payload.data.token);
    }
  } else if (action.type === LOGOUT) {
    window.localStorage.setItem("jwt", "");
    agent.setToken(null);
  } else if (action.type === APP_LOAD) {
    if (action.error) {
      window.localStorage.setItem("jwt", "");
      agent.setToken(null);
    }
  }

  next(action);
};

const usersFormMiddleware = (store) => (next) => (action) => {
  if (
    action.type === CREATE_USER ||
    action.type === UPDATE_USER ||
    action.type === DELETE_USER
  ) {
    if (action.error) {
      console.log("error");
    } else if (action.payload) {
      notifySuccess(action);
      store.dispatch({ type: SHOW_CREATE_EDIT_USER_POPUP, payload: false });
      store.dispatch({ type: GET_USERS_LIST, payload: agent.Users.getList() });
      store.dispatch({
        type: GET_HCP_USERS_LIST,
        payload: agent.Users.getHcpList(),
      });
    }
    console.log(action);
  } else if (
    action.type === CREATE_ORG ||
    action.type === UPDATE_ORG ||
    action.type === DELETE_ORG ||
    action.type === UPDATE_AUTH_TOKEN
  ) {
    if (action.error) {
      console.log("error");
    } else if (action.payload) {
      if (action.type === CREATE_ORG) {
        toast("Organization created successfully!");
      } else if (action.type === UPDATE_ORG) {
        toast("Organization information updated successfully!");
      } else if (action.type === DELETE_ORG) {
        toast("Organization Deleted successfully!");
      }
      store.dispatch({ type: SHOW_CREATE_EDIT_ORG_POPUP, payload: false });
      store.dispatch({ type: GET_ORGS_LIST, payload: agent.Orgs.getList() });
    }
  } else if (action.type === SEND_MEETING_INVITE) {
    if (action.error) {
      console.log("error");
    } else if (action.payload) {
      store.dispatch({ type: MEETING_INVITE_SUCCESS, payload: true });
      store.dispatch({
        type: GET_APPOINTMENTS_LIST,
        payload: agent.Schedule.getSchedulesList(),
      });

      toast("Meeting request sent successfully!");
    }
  }
  next(action);
};

function isPromise(v) {
  return v && typeof v.then === "function";
}

export {
  promiseMiddleware,
  localStorageMiddleware,
  usersFormMiddleware,
  socketMiddleware,
  chatMiddleware,
};
