import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import screenfull from "screenfull";
import ReactPlayer from "react-player";
import Duration from "./Duration";
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  APPLY_TAG_FILTER,
  CURRENT_VIEW,
} from "../../constants/actionTypes";

const MULTIPLE_SOURCES = [
  {
    src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4",
    type: "video/mp4",
  },
  {
    src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.ogv",
    type: "video/ogv",
  },
  {
    src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.webm",
    type: "video/webm",
  },
];

const Promise = global.Promise;

const mapStateToProps = (state) => ({
  ...state.home,
  appName: state.common.appName,
  currentUser: state.common.currentUser,
  token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onClickTag: (tag, pager, payload) =>
    dispatch({ type: APPLY_TAG_FILTER, tag, pager, payload }),
  onLoad: (tab, pager, payload) =>
    dispatch({ type: HOME_PAGE_LOADED, tab, pager, payload }),
  onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
});

class App extends Component {
  state = {
    url: null,
    pip: false,
    playing: false,
    controls: true,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  };

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };
  debugger;
  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    console.log("onPlay");
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  handlePause = () => {
    console.log("onPause");
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    console.log("onProgress", state);
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleEnded = () => {
    console.log("onEnded");
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration) => {
    console.log("onDuration", duration);
    this.setState({ duration });
  };

  handleClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player));
  };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip,
    } = this.state;
    const SEPARATOR = " · ";

    return (
      <li className="video-item">
        <div className="video-inset">
          <div className="video-icon">
            <div>
              <ReactPlayer
                ref={this.ref}
                className="react-player"
                width="125%"
                height="200%"
                url={this.props.media_location}
                pip={pip}
                playing={playing}
                controls={controls}
                light={light}
                loop={loop}
                playbackRate={playbackRate}
                volume={volume}
                muted={muted}
                onReady={() => console.log("onReady")}
                onStart={() => console.log("onStart")}
                onPlay={this.handlePlay}
                onEnablePIP={this.handleEnablePIP}
                onDisablePIP={this.handleDisablePIP}
                onPause={this.handlePause}
                onBuffer={() => console.log("onBuffer")}
                onSeek={(e) => console.log("onSeek", e)}
                onEnded={this.handleEnded}
                onError={(e) => console.log("onError", e)}
                onProgress={this.handleProgress}
                onDuration={this.handleDuration}
              />
            </div>
          </div>
          <div className="video-name">{this.props.file_name}</div>
        </div>
        <div className="overlay">
           {(this.props.currentUser.roleId === 3 || this.props.currentUser.roleId === 4 ||  this.props.currentUser.roleId === 5) && <input
              type="checkbox"
              className="icon select-checkbox"
              defaultValue={null}
              onClick={() => this.props.handleCheck(this.props.uid)}
            />}
          <div
            className="icon icon-fullscreen"
            onClick={this.handleClickFullscreen}
          ></div>
          <div className="icon icon-download-small">
						<a href={this.props.media_location} download={true}/>
					</div>
          {(this.props.currentUser.roleId === 3 || this.props.currentUser.roleId === 4 ||  this.props.currentUser.roleId === 5) && 
          <div className="icon icon-chat-small" onClick={e => this.props.share(this.props.uid, "single")}></div>}
          {this.props.currentUser.roleId === 2 && 
          <div onClick={() => {this.props.deleteContent('video', this.props.uid)}}>
            <h2 className="delete">-</h2>
          </div>}
        </div>
        <div className="video-duration">
          <Duration seconds={duration} />
        </div>
      </li>
    );
  }
}

// export default hot(module)(App);
export default connect(mapStateToProps, mapDispatchToProps)(App);
