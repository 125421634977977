import React, { useEffect, useState } from "react";
import agent from "../agent";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  LOGIN_PAGE_UNLOADED,
} from "../constants/actionTypes";

import "./styles/RoleSelector.scss";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  orgs: state.common.orgs,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeUsername: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "username", value }),
  onChangePassword: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "password", value }),
  onSubmit: (username, password) => {
    dispatch({ type: LOGIN, payload: agent.Auth.login(username, password) });
  },
});

const OrgSelector = (props) => {
  const {
    orgs,
    onSubmit,
    currentUser,
    propOrgId,
    propDisabled,
    onSelect,
  } = props;
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState({});
  const [orgsList, setOrgsList] = useState([]);

  const submitForm = (email, password) => (ev) => {
    ev.preventDefault();
    onSubmit(email, password);
  };
  const filterOrgsList = (list) => {
    if (currentUser && currentUser.roleId) {
      if (currentUser.roleId === 1) {
        setOrgsList(list);
        if (propOrgId) {
          setSelectedItem(propOrgId);
        } else {
          setSelectedItem(currentUser.orgId);
        }
      } else if (currentUser.roleId === 2) {
        const filteredList = list.filter((item) => item.uid !== "1");
        setOrgsList(filteredList);
        if (propOrgId) {
          setSelectedItem(propOrgId);
        } else {
          setSelectedItem(currentUser.orgId);
        }
      } else {
        setOrgsList([]);
      }
    }
  };

  const onChange = (e) => {
    setSelectedItem(e.target.value);
    if (onSelect) {
      onSelect(e.target.value);
    }
  };

  useEffect(() => {
    if (orgs && orgs.length) {
      filterOrgsList(orgs);
    }
    return () => {
      // onUnload();
    };
  }, [propOrgId, orgs]);
  return (
    <div className="form-group org-selector">
      <select
        value={selectedItem}
        onChange={onChange}
        className="form-control"
        disabled={propDisabled || currentUser.roleId !== 1}
      >
        {orgsList.map((item) => {
          return (
            <option value={item.uid} key={item.uid}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgSelector);
