import React, { useState, useEffect } from "react";
import agent from "../../agent";
import { connect } from "react-redux";
import {
  CHANGE_TAB,
  SHOW_CREATE_EDIT_ORG_POPUP,
  DELETE_ORG,
} from "../../constants/actionTypes";
import { useTranslation } from "react-i18next";
// import RoleSelector from "../RoleSelector";
// import OrgSelector from "../OrgSelector";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  // users: state.common.users,
  orgs: state.common.orgs,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
  showOrgPopup: (editData) =>
    dispatch({ type: SHOW_CREATE_EDIT_ORG_POPUP, payload: editData || true }),
  deleteOrg: (deleteData) =>
    dispatch({ type: DELETE_ORG, payload: agent.Orgs.deleteOrg(deleteData) }),
});

const MainView = (props) => {
  const { showOrgPopup, orgs, showDeletePopup, deleteOrg } = props;
  const { t } = useTranslation();
  const [orgsArray, setOrgsArray] = useState(orgs || []);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const onOrgSelect = (item) => {
    setSelectedOrg(item);
  };

  const submitForm = (formData) => {
    console.log(formData);
  };

  const hideDeletePopup = () => {
    showDeletePopup(null);
  };

  const afterDeletePopupClose = () => {
    // TODO
  };

  const onDeleteOrg = (deleteObj) => {
    const { uid } = deleteObj;
    const popupData = {
      uid: uid,
      inProgress: null,
      onConfirm: () => deleteOrg(uid),
      onCancel: hideDeletePopup,
      onDestroy: afterDeletePopupClose,
      primaryButtonText: "common.yes",
      secondaryButtonText: "common.no",
      confirmTitle: "common.confirm",
      confirmMessage: "orgs.org_delete.confirm_message",
    };
    showDeletePopup(popupData);
  };

  const getOrgName = (id) => {
    if (orgs && orgs.length) {
      const org = orgs.filter((item) => item.uid === id);
      return org[0].name;
    }
  };

  useEffect(() => {
    setOrgsArray(orgs);
  }, [orgs]);

  useEffect(() => {
    if (orgsArray && orgsArray.length && !selectedOrg) {
      setSelectedOrg(orgsArray[0]);
    } else if (orgsArray && orgsArray.length && selectedOrg) {
      const alreadySelected = orgsArray.filter((item) => {
        return item.uid === selectedOrg.uid;
      });
      if (alreadySelected && alreadySelected.length) {
        setSelectedOrg(alreadySelected[0]);
      } else {
        setSelectedOrg(orgsArray[0]);
      }
    }
    hideDeletePopup();
  }, [orgsArray]);

  return (
    <div className="mainview-container">
      <div className="names-container">
        <div className="title-container">
          <h6 className="title">{t("orgs.list_title")}</h6>
          <div className="add-user" onClick={() => showOrgPopup()}></div>
        </div>
        <div className="names-list-container">
          <ul className="names-list">
            {orgsArray &&
              orgsArray.length &&
              orgsArray.map((item) => {
                return (
                  <li
                    className={
                      selectedOrg && selectedOrg.uid === item.uid
                        ? "names-item selected"
                        : "names-item"
                    }
                    key={item.uid}
                    onClick={() => onOrgSelect(item)}
                  >
                    <div className="dr">
                      <div>{item.name || ""}</div>
                      {/* <div className="message-preview">{getOrgName(item.orgId)}</div> */}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className="main-body-container">
        {selectedOrg && selectedOrg.uid && (
          <div className="user-details-container">
            <div className="title-container mb-4">
              <h4>{t("orgs.org_details.title")}</h4>
              <div className="controls">
                <div
                  className="icon edit-icon"
                  onClick={() => showOrgPopup(selectedOrg)}
                ></div>
                <div
                  className="icon delete-icon"
                  onClick={() => onDeleteOrg(selectedOrg)}
                ></div>
              </div>
            </div>
            <form onSubmit={() => submitForm()}>
              <fieldset>
                <div className="form-group row">
                  <div className="col-12 mb-2">
                    <label for="name">{t("orgs.org_details.name")}</label>
                    <input
                      name="name"
                      className="form-control form-control-md"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={selectedOrg.name}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="access_token">
                      {t("orgs.org_details.access_token")}
                    </label>
                    <input
                      name="access_token"
                      className="form-control form-control-md"
                      type="text"
                      value={selectedOrg.accessToken || ""}
                      disabled={isDisabled}
                    />
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="website">{t("orgs.org_details.website")}</label>
                    <input
                      name="website"
                      className="form-control form-control-md col-sm-12"
                      type="url"
                      // placeholder={t("login.placeholder.username")}
                      value={selectedOrg.website}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="email">{t("orgs.org_details.email")}</label>
                    <input
                      name="email"
                      className="form-control form-control-md"
                      type="email"
                      // placeholder={t("login.placeholder.password")}
                      value={selectedOrg.email}
                      disabled={isDisabled}
                    />
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="phone">{t("orgs.org_details.phone")}</label>
                    <input
                      name="phone"
                      className="form-control form-control-md"
                      type="phone"
                      // placeholder={t("login.placeholder.password")}
                      value={selectedOrg.phone}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="email">{t("orgs.org_details.admin_email")}</label>
                    <input
                      name="admin_email"
                      className="form-control form-control-md"
                      type="admin_email"
                      // placeholder={t("login.placeholder.password")}
                      value={selectedOrg.adminEmail}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <hr></hr>
                <label className="mb-2">
                  {t("orgs.org_details.settings_title")}
                </label>
                <br />
                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="salestosales"
                      name="salestosales"
                      checked={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.canSalesTransferToSales
                          : false
                      }
                      value={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.canSalesTransferToSales
                          : false
                      }
                      disabled={isDisabled}
                    />
                    <label class="form-check-label" for="salestosales">
                      {t("orgs.org_details.transfer_sales_to_sales")}
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="msltomsl"
                      name="msltomsl"
                      checked={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.canMslTransferToMsl
                          : false
                      }
                      value={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.canMslTransferToMsl
                          : false
                      }
                      disabled={isDisabled}
                    />
                    <label class="form-check-label" for="msltomsl">
                      {t("orgs.org_details.transfer_msl_to_msl")}
                    </label>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="salestoMsl"
                      name="salestoMsl"
                      checked={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.canSalesTransferToMsl
                          : false
                      }
                      value={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.canSalesTransferToMsl
                          : false
                      }
                      disabled={isDisabled}
                    />
                    <label class="form-check-label" for="salestoMsl">
                      {t("orgs.org_details.transfer_sales_to_msl")}
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="msltosales"
                      name="msltosales"
                      checked={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.canMslTransferToSales
                          : false
                      }
                      value={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.canMslTransferToSales
                          : false
                      }
                      disabled={isDisabled}
                    />
                    <label class="form-check-label" for="msltosales">
                      {t("orgs.org_details.transfer_msl_to_sales")}
                    </label>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  mb-2">
                    <label for="durationtoreleasehangingthread">
                      {t("orgs.org_details.hanging_thread_text")}
                    </label>
                    <input
                      name="durationtoreleasehangingthread"
                      id="durationtoreleasehangingthread"
                      className="form-control"
                      type="number"
                      // placeholder={t("login.placeholder.password")}
                      value={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.autoReleaseTimeForHangingThread
                          : null
                      }
                      disabled={isDisabled}
                    />
                    <br />
                    <label for="durationtoclosenoreplythread">
                      {t("orgs.org_details.autoclose_thread_text")}
                    </label>
                    <input
                      name="durationtoclosenoreplythread"
                      id="durationtoclosenoreplythread"
                      className="form-control form-control-md"
                      type="number"
                      value={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.autoCloseTimeForNoReplyThread
                          : null
                      }
                      disabled={isDisabled}
                    />
                    <br />
                    <label for="durationtostartnewthread">
                      {t("orgs.org_details.consecutive_thread_text")}
                    </label>
                    <input
                      name="durationtostartnewthread"
                      id="durationtostartnewthread"
                      className="form-control form-control-md"
                      type="number"
                      value={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings
                              .minTimeGapRequiredToCreateNewThread
                          : null
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="notificationemail">
                      {t("orgs.org_details.notification_email")}
                    </label>
                    <input
                      name="notificationemail"
                      className="form-control form-control-md"
                      type="email"
                      value={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.notificationEmail
                          : null
                      }
                      disabled={isDisabled}
                    />
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="notificationpass">
                      {t("orgs.org_details.notification_email_password")}
                    </label>
                    <input
                      name="notificationpass"
                      className="form-control form-control-md"
                      type="password"
                      value={
                        selectedOrg && selectedOrg.settings
                          ? selectedOrg.settings.pwd
                          : null
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <hr></hr>

                <div className="form-group row">
                  <div className="col-12">
                    <label for="notes">{t("orgs.org_details.notes")}</label>
                    <input
                      name="notes"
                      className="form-control form-control-md col-sm-12"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={selectedOrg.notes}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12">
                    <label for="comments">
                      {t("orgs.org_details.comments")}
                    </label>
                    <input
                      name="comments"
                      className="form-control form-control-md col-sm-12"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={selectedOrg.comments}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
