import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import agent from "../../../agent";
import Recipient from "./Recipient";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  chatThreads: state.chat.chatThreads,
});

const mapDispatchToProps = (dispatch) => ({});

const ChatRecipients = (props) => {
  const {
    recipientsArray,
    selectedRecipient,
    onRecipientSelect,
    chatThreads,
  } = props;
  const [sortableRecipientsArray, setSortableRecipientsArray] = useState([]);
  const [isActiveTabSelected, setIsActiveTabSelected] = useState(true);
  const { t } = useTranslation();

  const sortArrayBasedOnTimestamp = (items) => {
    console.log(chatThreads);
    if (chatThreads && chatThreads.length) {
      const newList = items.sort((itemA, itemB) => {
        let returnData;
        const itemDataA = chatThreads.filter(
          (li) => li.id === itemA.thread.uid
        )[0];
        const itemDataB = chatThreads.filter(
          (li) => li.id === itemB.thread.uid
        )[0];
        const latestA =
          itemDataA &&
          itemDataA.chats &&
          itemDataA.chats.length &&
          itemDataA.chats[itemDataA.chats.length - 1] &&
          itemDataA.chats[itemDataA.chats.length - 1].ts
            ? itemDataA.chats[itemDataA.chats.length - 1].ts
            : null;

        const latestB =
          itemDataB &&
          itemDataB.chats &&
          itemDataB.chats.length &&
          itemDataB.chats[itemDataB.chats.length - 1] &&
          itemDataB.chats[itemDataB.chats.length - 1].ts
            ? itemDataB.chats[itemDataB.chats.length - 1].ts
            : null;

        if (latestA && latestB) {
          const dateA = moment(latestA);
          const dateB = moment(latestB);
          const dateAisafterB = moment(dateA).isAfter(dateB);
          if (dateAisafterB) {
            returnData = -1;
          } else {
            returnData = 1;
          }
        } else {
          returnData = 0;
        }
        return returnData;
      });
      setSortableRecipientsArray([...newList]);
    } else {
      setSortableRecipientsArray(items);
    }
  };

  useEffect(() => {
    if (recipientsArray && chatThreads) {
      sortArrayBasedOnTimestamp(recipientsArray);
    }
  }, [recipientsArray, chatThreads]);

  return (
    <React.Fragment>
      <div className="tab-div">
        <div
          className={`${
            isActiveTabSelected ? "tab-item selected" : "tab-item"
          }`}
          onClick={() => setIsActiveTabSelected(true)}
        >
          {t("connect.active")}
        </div>
        <div
          className={`${
            isActiveTabSelected ? "tab-item" : "tab-item selected"
          }`}
          onClick={() => setIsActiveTabSelected(false)}
        >
          {t("connect.inactive")}
        </div>
      </div>
      <ul className="names-list">
        {isActiveTabSelected ? (
          <React.Fragment>
            {sortableRecipientsArray && sortableRecipientsArray.length > 0 ? (
              <React.Fragment>
                {sortableRecipientsArray.map((item) => {
                  if (item.thread && item.thread.isArchived) {
                    return null;
                  } else {
                    return (
                      <Recipient
                        key={item.hcp.uid}
                        data={item}
                        selectedRecipient={selectedRecipient}
                        onRecipientSelect={onRecipientSelect}
                      />
                    );
                  }
                })}
              </React.Fragment>
            ) : (
              <li className="no-recipients-block">No Recipients</li>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {sortableRecipientsArray && sortableRecipientsArray.length > 0 ? (
              <React.Fragment>
                {sortableRecipientsArray.map((item) => {
                  if (item.thread && item.thread.isArchived) {
                    return (
                      <Recipient
                        key={item.hcp.uid}
                        data={item}
                        selectedRecipient={selectedRecipient}
                        onRecipientSelect={onRecipientSelect}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </React.Fragment>
            ) : (
              <li className="no-recipients-block">No Recipients</li>
            )}
          </React.Fragment>
        )}
      </ul>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRecipients);
