import React, { useEffect } from "react";
import { connect } from "react-redux";
import MainView from "./MainView";
import "./invite.scss";

export class index extends React.Component {
   
    render() {
        return (<div className="view-container dashboard-view">
            <MainView />
        </div>)
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(index)

