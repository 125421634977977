import moment from "moment";
import {
  GET_HCP_USERS_LIST,
  MEETING_INVITE_SUCCESS,
  GET_APPOINTMENTS_LIST,
} from "../constants/actionTypes";

const initialState = {
  hcpUsers: [],
  sentMeetingInvite: false,
  schedulesList: {},
  scheduleSearchList: [],
};

const updateIsReadStatusinThread = (state, action) => {
  if (action && action.payload) {
    const { threadId, msgId } = action.payload;
    if (!threadId || !msgId) {
      return;
    }
    const fullThread = state.chatThreadsClone;
    const updatedThreads =
      fullThread && fullThread.length
        ? fullThread.map((item) => {
            if (item.id === threadId) {
              const newItem = { ...item };
              const inChatThread = newItem.chats.map((msg) => {
                if (msg.uid === msgId) {
                  if (msg.isRead === 0) {
                    msg.isRead = 1;
                  }
                }
                return msg;
              });
              return { ...newItem, chats: [...inChatThread] };
            } else {
              return item;
            }
          })
        : null;
    return {
      ...state,
      chatThreadsClone:
        updatedThreads && updatedThreads.length
          ? updatedThreads
          : state.chatThreadsClone,
    };
  } else {
    return {
      ...state,
    };
  }
};

// const sample = {agentId: 48
//   duration: 10
//   endDateTime: null
//   hcpId: 29
//   isCancelled: 0
//   iterationId: 0
//   meetingInviteReplyMessageId: 1666
//   mettingInviteMessageId: 1665
//   orgId: "5"
//   startDateTime: "2020-08-24T23:50:00.000Z"
//   threadId: 137
//   ts: 1598266454246
//   udf1: null
//   udf2: null
//   udf3: null
//   uid: 9
//   updatedDate: "2020-08-24T10:54:14.000Z"}

const processInvites = (state, action) => {
  const scheduleData = {};
  const fullData =
    action && action.payload && action.payload.data ? action.payload.data : [];
  // const { threadId } = action.payload;
  const searchListData =
    fullData && fullData.length
      ? fullData.map((item) => {
          if (item.startDateTime) {
            const appointmentId = moment(item.startDateTime)
              .utc()
              .local()
              .format("YYYY_MM_DD_HH_mm");
            // moment(startTime).utc().format("YYYY/MM/DD HH:mm:ss")
            scheduleData[appointmentId] = item;
            let hcpName;
            const found = state.hcpUsers.filter(
              (hcp) => hcp.uid === item.hcpId
            );
            if (found && found.length) {
              hcpName = `${found[0].fName}${found[0].lName}`;
            }
            return {
              id: appointmentId,
              date: item.startDateTime,
              hcpId: item.hcpId,
              duration: item.duration,
              hcpName: hcpName,
            };
          }
        })
      : null;

  return {
    ...state,
    schedulesList: scheduleData,
    scheduleSearchList: searchListData,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HCP_USERS_LIST:
      return {
        ...state,
        hcpUsers:
          action.payload && action.payload.data && action.payload.data.users
            ? action.payload.data.users
            : [],
      };
    case MEETING_INVITE_SUCCESS:
      return {
        ...state,
        sentMeetingInvite: action.payload,
      };
    case GET_APPOINTMENTS_LIST:
      return processInvites(state, action);
    default:
      return state;
  }
}
