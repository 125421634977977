import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import agent from "../../../agent";

const mapStateToProps = (state) => ({
  chatThreadsClone: state.chat.chatThreadsClone,
});

const mapDispatchToProps = (dispatch) => ({
  getMessages: (ids) => {
    agent.Chat.getMessages(ids);
  },
});

const UnReadCount = (props) => {
  const { threadId, chatThreadsClone } = props;
  const { t } = useTranslation();

  const [count, setCount] = useState(null);

  const updateStatus = () => {
    if (chatThreadsClone && chatThreadsClone.length && threadId) {
      const currentThread = chatThreadsClone.filter((item) => {
        return item.id === threadId;
      });
      if (
        currentThread &&
        currentThread.length &&
        currentThread[0].chats &&
        currentThread[0].chats.length
      ) {
        const unreadMsgs = currentThread[0].chats.filter((item) => {
          return (
            item.messageDirectionType === 3 &&
            item.messageType !== 18 &&
            item.messageType !== 19 &&
            !item.isRead
          );
        });
        setCount(unreadMsgs.length);
      }
    }
  };

  useEffect(() => {
    updateStatus();
  }, [chatThreadsClone, threadId]);

  return (
    <React.Fragment>
      {count ? (
        <div className="count-container">
          <div>{count}</div>
        </div>
      ) : (
        <React.Fragment>
          {count === null && <div className="count-spinner" />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UnReadCount);
