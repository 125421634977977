import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { HOURS, MINUTES } from "../../constants/scheduleConstants";
import { useTranslation } from "react-i18next";
import SchedulerPopup from "./SchedulePopup";

import "react-datepicker/dist/react-datepicker.css";

export const ScheduleCalendar = (props) => {
  const { goToScheduleBlock } = props;
  const { singleDayDisplayMode } = props;
  const [scheduleDays, setScheduleDays] = useState([]);
  const [schedulePopupObject, setSchedulePopupObject] = useState(null);

  const today = moment();
  const tomorrow = moment().add(1, "d");
  const dayAfterTomorrow = moment().add(2, "d");
  // const dayAfterTomorrow2 = moment().add(3, "d");

  const moveToSearchedItem = (data) => {
    const today = moment(data.date);
    const tomorrow = moment(data.date).add(1, "d");
    const dayAfterTomorrow = moment(data.date).add(2, "d");
    setScheduleDays([today, tomorrow, dayAfterTomorrow]);
    setTimeout(() => {
      const searchedSchedule = document.getElementById(data.id);
      if (searchedSchedule)
        searchedSchedule.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
    }, 500);
  };
  const moveTowardsPast = () => {
    const currentDays = [...scheduleDays];
    if (currentDays && currentDays.length) {
      const firstDay = currentDays[0];
      const dayToInclude = moment(firstDay).subtract(1, "d");
      currentDays.unshift(dayToInclude);
      currentDays.pop();
      setScheduleDays([...currentDays]);
    }
  };
  const moveToPresent = () => {
    setScheduleDays([today, tomorrow, dayAfterTomorrow]);
  };
  const moveTowardsFuture = () => {
    const currentDays = [...scheduleDays];
    if (currentDays && currentDays.length) {
      const lastDay = currentDays[currentDays.length - 1];
      const dayToInclude = moment(lastDay).add(1, "d");
      currentDays.push(dayToInclude);
      currentDays.shift();
      setScheduleDays([...currentDays]);
    }
  };

  useEffect(() => {
    if (goToScheduleBlock) {
      moveToSearchedItem(goToScheduleBlock);
    }
  }, [goToScheduleBlock]);

  useEffect(() => {
    if (singleDayDisplayMode) {
      setScheduleDays([today]);
    } else {
      setScheduleDays([today, tomorrow, dayAfterTomorrow]);
    }
    return () => {
      setScheduleDays([]);
    };
  }, []);

  const addSchedule = (date, hour, minute) => {
    // alert(
    //   `Schedule event for ${date.get("date")}/${date.get("month")}/${date.get(
    //     "year"
    //   )} ${hour}:${minute}`
    // );
    setSchedulePopupObject({
      date,
      hour,
      minute,
      setSchedulePopupObject,
      schedulesList: props.schedulesList,
    });
  };
  return (
    <div
      className={`schedule-container ${
        singleDayDisplayMode ? "single-day-mode" : ""
      }`}
    >
      {schedulePopupObject && !singleDayDisplayMode && (
        <SchedulerPopup {...schedulePopupObject} />
      )}
      <Schedule
        scheduleDays={scheduleDays}
        addSchedule={!singleDayDisplayMode ? addSchedule : null}
        moveTowardsPast={moveTowardsPast}
        moveTowardsFuture={moveTowardsFuture}
        schedulesList={props.schedulesList}
        hcpUsers={props.hcpUsers}
        singleDayDisplayMode={singleDayDisplayMode}
        searchedItemId={
          goToScheduleBlock && goToScheduleBlock.id
            ? goToScheduleBlock.id
            : null
        }
      />
    </div>
  );
};

export const Schedule = (props) => {
  const {
    scheduleDays,
    addSchedule,
    moveTowardsPast,
    moveTowardsFuture,
    schedulesList,
    hcpUsers,
    singleDayDisplayMode,
    searchedItemId,
  } = props;
  const dummyAddSchedule = () => {
    // TODO
  };
  const today = moment();
  return (
    <React.Fragment>
      <div className="sample-schedule">
        {!singleDayDisplayMode && (
          <React.Fragment>
            <div onClick={moveTowardsPast} className="navigation left"></div>
            <div onClick={moveTowardsFuture} className="navigation right"></div>
          </React.Fragment>
        )}
        {[today].map((item) => (
          <ScheduleDay
            data={item}
            singleDayDisplayMode={singleDayDisplayMode}
          />
        ))}
      </div>
      <div className="schedule">
        {scheduleDays &&
          scheduleDays.length > 0 &&
          scheduleDays.map((item) => {
            return (
              <ScheduleDay
                data={item}
                singleDayDisplayMode={singleDayDisplayMode}
                schedulesList={schedulesList}
                addSchedule={addSchedule || dummyAddSchedule}
                hcpUsers={hcpUsers}
                searchedItemId={searchedItemId}
              />
            );
          })}
      </div>
    </React.Fragment>
  );
};

export const ScheduleDay = (props) => {
  const {
    data,
    addSchedule,
    schedulesList,
    hcpUsers,
    singleDayDisplayMode,
    searchedItemId,
  } = props;
  const scheduleHours = HOURS;
  return (
    <div className="schedule-day">
      {!singleDayDisplayMode && (
        <div className="day-title">
          {moment(data, ["DD-MM-YYYY"]).format("dddd MMMM D YYYY")}
        </div>
      )}
      {scheduleHours &&
        scheduleHours.length &&
        scheduleHours.map((item) => {
          return (
            <ScheduleHour
              data={item}
              day={data}
              addSchedule={addSchedule}
              schedulesList={schedulesList}
              hcpUsers={hcpUsers}
              searchedItemId={searchedItemId}
            />
          );
        })}
    </div>
  );
};

export const ScheduleHour = (props) => {
  const {
    data,
    day,
    addSchedule,
    schedulesList,
    hcpUsers,
    searchedItemId,
  } = props;
  const scheduleMinutes = MINUTES;
  return (
    <React.Fragment>
      <div className="schedule-hour">
        <div className="hour-title">{data}</div>
        <div className="minutes-container">
          {scheduleMinutes &&
            scheduleMinutes.length &&
            scheduleMinutes.map((item) => {
              return (
                <ScheduleMinute
                  data={item}
                  day={day}
                  hour={data}
                  addSchedule={addSchedule}
                  schedulesList={schedulesList}
                  hcpUsers={hcpUsers}
                  searchedItemId={searchedItemId}
                />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export const ScheduleMinute = (props) => {
  const {
    data,
    day,
    hour,
    addSchedule,
    schedulesList,
    hcpUsers,
    searchedItemId,
  } = props;
  const clickHandler = (e) => {
    e.stopPropagation();
    if (addSchedule) addSchedule(day, hour, data);
  };

  const scheduleId = `${moment(day)
    .utc()
    .local()
    .format("YYYY_MM_DD")}_${hour}_${data}`;

  const scheduleData =
    schedulesList && schedulesList[scheduleId]
      ? schedulesList[scheduleId]
      : null;

  return (
    <React.Fragment>
      {scheduleData ? (
        <ScheduleMinuteBlock
          {...props}
          id={scheduleId}
          searchedItemId={searchedItemId}
        />
      ) : (
        <div
          id={scheduleId}
          style={{ height: "1px", width: "100%" }}
          className="schedule-minute"
          title={`${hour}:${data}`}
          onClick={clickHandler}
        ></div>
      )}
    </React.Fragment>
  );
};

export const ScheduleMinuteBlock = (props) => {
  const {
    id,
    data,
    day,
    hour,
    addSchedule,
    schedulesList,
    hcpUsers,
    searchedItemId,
  } = props;
  const [scheduleData, setScheduleData] = useState([]);
  const [hcpName, setHcpName] = useState("");
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [scheduleEndTime, setScheduleEndTime] = useState(null);

  const detailsBubble = useRef();
  const scheduledItemMarker = useRef();

  const clickHandler = (e) => {
    // e.stopPropagation();
    // if (addSchedule) addSchedule(day, hour, data);
    setShowDetailsPopup(true);
  };
  const scheduleId = `${moment(day)
    .utc()
    .local()
    .format("YYYY_MM_DD")}_${hour}_${data}`;

  const processData = (id) => {
    if (id && hcpUsers && hcpUsers.length) {
      const found = hcpUsers.filter((item) => item.uid === id);
      if (found && found.length) {
        const name = `${found[0].fName} ${found[0].lName}`;
        setHcpName(name);
      }
    }
  };

  const formatScheduleData = (data) => {
    function processDateString(ts) {
      const scheduleHour = new Date(ts).getHours().toString();
      const scheduleMinute = new Date(ts).getMinutes().toString();
      return `${
        scheduleHour && scheduleHour.length === 1
          ? "0" + scheduleHour
          : scheduleHour
      }:${
        scheduleMinute && scheduleMinute.length === 1
          ? "0" + scheduleMinute
          : scheduleMinute
      }`;
    }
    if (!data) {
      return;
    }
    const startTimeStamp = Date.parse(data.startDateTime);
    const endTimeStamp = new Date(data.startDateTime).setMinutes(data.duration);
    const start = processDateString(startTimeStamp);
    const end = processDateString(endTimeStamp);
    setScheduleTime(start ? start : "");
    setScheduleEndTime(end ? end : "");
  };

  useEffect(() => {
    const scheduleData =
      schedulesList && schedulesList[scheduleId]
        ? schedulesList[scheduleId]
        : null;
    if (scheduleData) {
      setScheduleData(scheduleData);
      formatScheduleData(scheduleData ? scheduleData : null);

      const hcpId = scheduleData.hcpId;
      const meetingStatus = scheduleData.isCancelled;
      processData(hcpId);
    }
  }, [schedulesList, hcpUsers]);

  const handleClickOutside = (event) => {
    console.log(detailsBubble);
    console.log(showDetailsPopup);
    if (
      scheduledItemMarker.current &&
      detailsBubble.current &&
      !detailsBubble.current.contains(event.target) &&
      !scheduledItemMarker.current.contains(event.target)
    ) {
      setShowDetailsPopup(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      id={id}
      style={{ height: "1px", width: "100%" }}
      className={`schedule-minute occupied ${
        scheduleData.isCancelled
          ? "cancelled"
          : scheduleData.udf3
          ? "confirmed"
          : "waiting"
      } ${searchedItemId && searchedItemId === id ? "searched" : ""}`}
      title={`${hour}:${data}`}
      onClick={clickHandler}
    >
      {scheduleData && scheduleData.startDateTime ? (
        <React.Fragment>
          <div
            ref={scheduledItemMarker}
            className="schedule-label"
            style={{ height: `${scheduleData.duration}px` }}
            onClick={clickHandler}
            title={`Meeting with : ${hcpName || ""} \n Status : ${
              scheduleData.isCancelled
                ? "Cancelled"
                : scheduleData.udf3
                ? "Confirmed"
                : "Awaiting"
            } \n ${`Start time : ${
              scheduleTime ? scheduleTime : ""
            }`} \n ${`End time : ${
              scheduleEndTime ? scheduleEndTime : ""
            }`} \n ${`Duration : ${scheduleData.duration} minutes`}`}
          >
            <div>{hcpName || ""}</div>
          </div>
          {showDetailsPopup && (
            <div className="schedule-details" ref={detailsBubble}>
              <div className="details-body">
                <div>Meeting with : {hcpName || ""}</div>
                <div>
                  Status :{" "}
                  {scheduleData.isCancelled
                    ? "Cancelled"
                    : scheduleData.udf3
                    ? "Confirmed"
                    : "Awaiting"}
                </div>
                <div>{`Start time : ${scheduleTime ? scheduleTime : ""}`}</div>
                <div>{`End time : ${
                  scheduleEndTime ? scheduleEndTime : ""
                }`}</div>
                <div>{`Duration : ${scheduleData.duration} minutes`}</div>
                <div>{`Meeting notes : ${scheduleData.content || ""}`}</div>
              </div>
            </div>
          )}
        </React.Fragment>
      ) : null}
    </div>
  );
};
