import { GET_DOCUMENT_ATTACHMENT, GET_VIDEO_ATTACHMENT, GET_MESSAGE_ATTACHMENT, SEND_DOCUMENT_ATTACHMENT,
  RELOAD_NEW_ATTACHMENT, SEND_VIDEO_ATTACHMENT, SEND_TEXT_ATTACHMENT, DELETE_ATTACHMENT } from '../constants/actionTypes';

const initialState = {
  updated: false
}

export default function (state = initialState, action) {
  switch(action.type) {
    case SEND_DOCUMENT_ATTACHMENT:
    case SEND_VIDEO_ATTACHMENT:
    case SEND_TEXT_ATTACHMENT:
    case DELETE_ATTACHMENT:
      return {
        ...state,
        updated: action.payload.status
      };
    case RELOAD_NEW_ATTACHMENT:
      return {
        ...state,
        updated: action.payload
      };
    case GET_DOCUMENT_ATTACHMENT:
      return {
        ...state,
        document: action.payload.content,
        contentPages: action.payload.pages
      };
    case GET_VIDEO_ATTACHMENT:
      return {
        ...state,
        videos: action.payload.content,
        videosPages: action.payload.pages
      };
    case GET_MESSAGE_ATTACHMENT:
      return {
        ...state,
        message: action.payload.content,
        messagePages: action.payload.pages
      };

      // case SEND_MESSAGE:
      //   return {
      //     ...state,
      //   }
    default:
      return state
  }
}
