import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
import { SHOW_ADVERSE_EFFECT_POPUP } from "../../../constants/actionTypes";

const mapStateToProps = (state) => ({
  token: state.common.token,
  currentUser: state.common.currentUser,
  newIncomingMessage: state.common.newMessage,
});

const mapDispatchToProps = (dispatch) => ({
  showAdversePopup: (data) =>
    dispatch({ type: SHOW_ADVERSE_EFFECT_POPUP, payload: data || true }),
  closeTransferPopup: () =>
    dispatch({ type: SHOW_ADVERSE_EFFECT_POPUP, payload: false }),
});

const TextMessage = (props) => {
  const {
    data,
    searchResultArray,
    selectedRecipient,
    chatThreadContainer,
    updateIsReadStatusInThread,
    showAdversePopup,
  } = props;
  const { isRead, uid, messageDirectionType, content, ts, tagName } = data;
  const [isMessageRead, setIsMessageRead] = useState(isRead || false);
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  const postIsReadStatus = () => {
    if (updateIsReadStatusInThread && !isMessageRead) {
      updateIsReadStatusInThread(uid);
      // setIsMessageRead(true);
    }
  };

  const onVisbilityChange = (isVisible) => {
    console.log(content, "~~~", isRead ? "Read" : "notRead", "---", isVisible);
    if (isVisible) {
      setIsMessageVisible(isVisible);
    } else {
      setIsMessageVisible(false);
    }
  };

  const visibilityProps = {
    onChange: onVisbilityChange,
    containment:
      chatThreadContainer && chatThreadContainer.current
        ? chatThreadContainer.current
        : null,
    delayedCall: true,
    resizeCheck: true,
    scrollDelay: 500,
    intervalDelay: 500,
    minTopValue: 70,
    offset: { top: -300, bottom: -300 },
  };

  useEffect(() => {
    if (
      (messageDirectionType === 3 || messageDirectionType === "3") &&
      !isRead
    ) {
      postIsReadStatus();
    }
  }, [isMessageVisible]);

  return (
    <React.Fragment>
      <li
        id={uid}
        className={`message-item text-message ${
          messageDirectionType && messageDirectionType === 4 ? "sent" : ""
        } ${
          searchResultArray &&
          searchResultArray.length &&
          searchResultArray.filter((li) => li.uid === uid).length === 1
            ? "in-search-result"
            : ""
        }`}
        key={uid}
      >
        <VisibilitySensor {...visibilityProps}>
          <div className="message-container col-sm-12 col-md-11 col-lg-8 col-xl-6">
            {messageDirectionType && messageDirectionType === 3 && (
              <div className="sender-name-tag">
                <h5>
                  {tagName ||
                    `${
                      selectedRecipient &&
                      selectedRecipient.hcp &&
                      selectedRecipient.hcp.fName
                        ? selectedRecipient.hcp.fName[0]
                        : ""
                    }${
                      selectedRecipient &&
                      selectedRecipient.hcp &&
                      selectedRecipient.hcp.lName
                        ? selectedRecipient.hcp.lName[0]
                        : ""
                    }`}
                </h5>
              </div>
            )}
            {isMessageVisible && (
              <div className="message-preview">
                <div className="message">{content}</div>
                <div className="message-time">
                  <div className="time-stamp">
                    {new Date(ts).toLocaleString()}
                  </div>
                  <div
                    className="info-icon warning"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      showAdversePopup(data ? {data, selectedRecipient} : {});
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </VisibilitySensor>
      </li>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TextMessage);
