import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  LOGOUT,
  CURRENT_VIEW,
  SHOW_CREATE_EDIT_ORG_POPUP,
  CREATE_ORG,
  UPDATE_ORG,
} from "../constants/actionTypes";
import agent from "../agent";

import "./styles/CreatEditUser.scss";

const mapStateToProps = (state) => ({
  ...state.auth,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  createEditOrgPopupData: state.common.createEditOrgPopup,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => {
    dispatch({ type: LOGOUT, payload: agent.Auth.logout() });
  },
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
  closeOrgPopup: () =>
    dispatch({ type: SHOW_CREATE_EDIT_ORG_POPUP, payload: false }),
  create: (data) =>
    dispatch({ type: CREATE_ORG, payload: agent.Orgs.createOrg(data) }),
  update: (data) =>
    dispatch({ type: UPDATE_ORG, payload: agent.Orgs.updateOrg(data) }),
});

const CreateEditOrg = (props) => {
  const { t } = useTranslation();
  const {
    onViewChange,
    viewName,
    createEditOrgPopupData,
    closeOrgPopup,
    create,
    update,
    inProgress,
    currentUser,
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [duplicateName, setDuplicateName] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [duplicateWebsite, setDuplicateWebsite] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const [isDisabled, setIsDisabled] = useState(false);
  const [formName, setFormName] = useState("");
  const [formWebsite, setFormWebsite] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formPhone, setFormPhone] = useState("");
  const [formAdminEmail, setFormAdminEmail] = useState("");
  const [formNotes, setFormNotes] = useState("");
  const [formComments, setFormComments] = useState("");
  const [formAccessToken, setFormAccessToken] = useState("");
  const [transferSalesToSales, setTransferSalesToSales] = useState(false);
  const [transferMslToMsl, setTransferMslToMsl] = useState(false);
  const [transferSalesToMsl, setTransferSalesToMsl] = useState(false);
  const [transferMslToSales, setTransferMslToSales] = useState(false);
  // const [hangingThreadReleaseTime, setHangingThreadReleaseTime] = useState(0);
  // const [noReplyThreadCloseTime, setNoReplyThreadCloseTime] = useState(0);
  // const [createNewThreadMinTime, setCreateNewThreadMinTime] = useState(0);
  const [prevFormEmail, setPrevFormEmail] = useState(null);
  const [prevFormWebsite, setPrevFormWebsite] = useState(null);
  const [prevFormName, setPrevFormName] = useState(null);

  const [
    durationToReleaseHangingThread,
    setDurationToReleaseHangingThread,
  ] = useState(0);
  const [
    durationToCloseNoReplyThread,
    setDurationToCloseNoReplyThread,
  ] = useState(0);
  const [durationToStartNewThread, setDurationToStartNewThread] = useState(0);
  const [notificationEmail, setNotificationEmail] = useState("");
  const [notificationEmailPass, setNotificationEmailPass] = useState("");

  const updateOrg = () => {
    const formData = {
      org: {
        uid: createEditOrgPopupData.uid,
        name: formName,
        website: formWebsite,
        email: formEmail,
        phone: formPhone,
        notes: formNotes,
        comments: formComments,
        accessToken: formAccessToken,
        adminEmail: formAdminEmail,
      },
      settings: {
        canSalesTransferToSales: transferSalesToSales,
        canMslTransferToMsl: transferMslToMsl,
        canSalesTransferToMsl: transferSalesToMsl,
        canMslTransferToSales: transferMslToSales,
        autoReleaseTimeForHangingThread: durationToReleaseHangingThread,
        autoCloseTimeForNoReplyThread: durationToCloseNoReplyThread,
        minTimeGapRequiredToCreateNewThread: durationToStartNewThread,
        notificationEmail: notificationEmail,
        pwd: notificationEmailPass,
      },
      veeva: {},
      line: {},
    };
    update(formData);
  };

  const createOrg = () => {
    const formData = {
      org: {
        name: formName,
        website: formWebsite,
        email: formEmail,
        phone: formPhone,
        notes: formNotes,
        comments: formComments,
        accessToken: formAccessToken,
        adminEmail: formAdminEmail,
      },
      settings: {
        canSalesTransferToSales: transferSalesToSales,
        canMslTransferToMsl: transferMslToMsl,
        canSalesTransferToMsl: transferSalesToMsl,
        canMslTransferToSales: transferMslToSales,
        autoReleaseTimeForHangingThread: durationToReleaseHangingThread,
        autoCloseTimeForNoReplyThread: durationToCloseNoReplyThread,
        minTimeGapRequiredToCreateNewThread: durationToStartNewThread,
        notificationEmail: notificationEmail,
        pwd: notificationEmailPass,
      },
      veeva: {},
      line: {},
    };
    console.log(formData);
    create(formData);
  };

  const checkAvailability = async (name, email, website) => {
    const response = await agent.Orgs.availableProperties({
      name: name || false,
      email: email || false,
      website: website || false,
    });

    if (name) {
      setDuplicateName(response && response.data);
    } else if (email) {
      setDuplicateEmail(response && response.data);
    } else if (website) {
      setDuplicateWebsite(response && response.data);
    }
  };

  const submitForm = () => {
    if (createEditOrgPopupData && createEditOrgPopupData.uid) {
      updateOrg();
    } else {
      createOrg();
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const newForm = () => {
    // clear form data
    setFormName("");
    setFormWebsite("");
    setFormEmail("");
    setFormPhone("");
    setFormAdminEmail("");
    setFormNotes("");
    setFormComments("");
    setFormAccessToken("");
    setTransferSalesToSales(false);
    setTransferMslToMsl(false);
    setTransferSalesToMsl(false);
    setTransferMslToSales(false);
    // setHangingThreadReleaseTime(0);
    // setNoReplyThreadCloseTime(0);
    // setCreateNewThreadMinTime(0);
    setDurationToReleaseHangingThread(0);
    setDurationToCloseNoReplyThread(0);
    setDurationToStartNewThread(0);
    setDurationToReleaseHangingThread("");
    setDurationToCloseNoReplyThread("");
    setDurationToStartNewThread("");
    setNotificationEmail("");
    setNotificationEmailPass("");
    // show popup
    setShowPopup(true);
    setIsUpdateForm(false);
    setDuplicateEmail(false);
    setDuplicateName(false);
    setDuplicateWebsite(false);
  };

  const populateFormData = (formData) => {
    const {
      uid,
      name,
      accessToken,
      website,
      phone,
      adminEmail,
      email,
      notes,
      comments,
      settings = {},
    } = formData;
    const {
      canSalesTransferToSales = false,
      canMslTransferToMsl = false,
      canSalesTransferToMsl = false,
      canMslTransferToSales = false,
      autoReleaseTimeForHangingThread = 0,
      autoCloseTimeForNoReplyThread = 0,
      minTimeGapRequiredToCreateNewThread = 0,
      notificationEmail = "",
      pwd = "",
    } = settings;
    // set form data
    setFormName(name);
    setFormAccessToken(accessToken);
    setFormWebsite(website);
    setFormEmail(email);
    setFormPhone(phone);
    setFormAdminEmail(adminEmail);
    setFormNotes(notes);
    setFormComments(comments);
    setTransferSalesToSales(canSalesTransferToSales);
    setTransferMslToMsl(canMslTransferToMsl);
    setTransferSalesToMsl(canSalesTransferToMsl);
    setTransferMslToSales(canMslTransferToSales);
    // setHangingThreadReleaseTime(autoReleaseTimeForHangingThread);
    // setNoReplyThreadCloseTime(autoCloseTimeForNoReplyThread);
    // setCreateNewThreadMinTime(minTimeGapRequiredToCreateNewThread);
    setDurationToReleaseHangingThread(autoReleaseTimeForHangingThread);
    setDurationToCloseNoReplyThread(autoCloseTimeForNoReplyThread);
    setDurationToStartNewThread(minTimeGapRequiredToCreateNewThread);
    setDurationToReleaseHangingThread(autoReleaseTimeForHangingThread);
    setDurationToCloseNoReplyThread(autoCloseTimeForNoReplyThread);
    setDurationToStartNewThread(minTimeGapRequiredToCreateNewThread);
    setNotificationEmail(notificationEmail);
    setNotificationEmailPass(pwd);
    // Show popup
    setShowPopup(true);
    setIsUpdateForm(true);
  };

  const checkRequiredFields = (input) => {
    if (input && input.length) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  };

  useEffect(() => {
    if (
      createEditOrgPopupData &&
      typeof createEditOrgPopupData === "object" &&
      createEditOrgPopupData.uid
    ) {
      populateFormData(createEditOrgPopupData);
    } else if (createEditOrgPopupData) {
      newForm();
    } else {
      closePopup();
    }

    return () => {
      setPrevFormEmail(null);
      setPrevFormName(null);
      setPrevFormWebsite(null);
    };
  }, [createEditOrgPopupData]);

  return (
    <React.Fragment>
      {showPopup && (
        <div className="user-form-popup">
          <div className="form-container">
            <div className="popup-header">
              {!isUpdateForm ? (
                <h5>{t("orgs.org_popup.new_org_title")}</h5>
              ) : (
                <h5>{t("orgs.org_popup.update_org_title")}</h5>
              )}
            </div>
            <form>
              <fieldset>
                <div className="form-group row">
                  <div className="col-12 mb-2">
                    <label for="name" className="required">
                      {t("orgs.org_details.name")}
                    </label>
                    <input
                      name="name"
                      className="form-control form-control-md"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={formName}
                      onChange={(e) => {
                        setFormName(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      onFocus={() => {
                        if (!prevFormName) {
                          setPrevFormName(formName);
                        }
                      }}
                      onBlur={() => {
                        if (prevFormName !== formName) {
                          checkAvailability(formName);
                        }
                      }}
                      disabled={isDisabled}
                    />
                    {duplicateName && (
                      <label className="text-danger" for="name">
                        {t("orgs.duplicate.duplicate_name_message")}
                      </label>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="access_token" className="required">
                      {t("orgs.org_details.access_token")}
                    </label>
                    <input
                      name="access_token"
                      className="form-control form-control-md"
                      type="text"
                      value={formAccessToken}
                      onChange={(e) => {
                        setFormAccessToken(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                    />
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="website" className="required">
                      {t("orgs.org_details.website")}
                    </label>
                    <input
                      name="website"
                      className="form-control form-control-md col-sm-12"
                      type="url"
                      // placeholder={t("login.placeholder.username")}
                      value={formWebsite}
                      onChange={(e) => {
                        setFormWebsite(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      onFocus={() => {
                        if (!prevFormWebsite) {
                          setPrevFormWebsite(formWebsite);
                        }
                      }}
                      onBlur={() => {
                        if (prevFormWebsite !== formWebsite) {
                          checkAvailability(false, false, formWebsite);
                        }
                      }}
                      disabled={isDisabled}
                    />
                    {duplicateWebsite && (
                      <label className="text-danger" for="website">
                        {t("orgs.duplicate.duplicate_website_message")}
                      </label>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="email" className="required">
                      {t("orgs.org_details.email")}
                    </label>
                    <input
                      name="email"
                      className="form-control form-control-md"
                      type="email"
                      // placeholder={t("login.placeholder.password")}
                      value={formEmail}
                      onChange={(e) => {
                        setFormEmail(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      onFocus={() => {
                        if (!prevFormEmail) {
                          setPrevFormEmail(formEmail);
                        }
                      }}
                      onBlur={() => {
                        if (prevFormEmail !== formEmail) {
                          checkAvailability(false, formEmail);
                        }
                      }}
                      disabled={isDisabled}
                    />
                    {duplicateEmail && (
                      <label className="text-danger" for="email">
                        {t("orgs.duplicate.duplicate_email_message")}
                      </label>
                    )}
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="phone">{t("orgs.org_details.phone")}</label>
                    <input
                      name="phone"
                      className="form-control form-control-md"
                      type="phone"
                      // placeholder={t("login.placeholder.password")}
                      value={formPhone}
                      onChange={(e) => {
                        setFormPhone(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="admin_email">
                      {t("orgs.org_details.admin_email")}
                    </label>
                    <input
                      name="admin_email"
                      className="form-control form-control-md"
                      type="email"
                      // placeholder={t("login.placeholder.password")}
                      value={formAdminEmail}
                      onChange={(e) => {
                        setFormAdminEmail(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                {/* checkboxs */}
                <hr></hr>
                <label className="mb-2">
                  {t("orgs.org_details.settings_title")}
                </label>
                <br />
                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="salestosales"
                      name="salestosales"
                      checked={transferSalesToSales}
                      onClick={(e) => {
                        setTransferSalesToSales(!transferSalesToSales);
                      }}
                    />
                    <label class="form-check-label" htmlFor="salestosales">
                      {t("orgs.org_details.transfer_sales_to_sales")}
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="msltomsl"
                      name="msltomsl"
                      checked={transferMslToMsl}
                      onClick={(e) => {
                        setTransferMslToMsl(!transferMslToMsl);
                      }}
                    />
                    <label class="form-check-label" for="msltomsl">
                      {t("orgs.org_details.transfer_msl_to_msl")}
                    </label>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="salestoMsl"
                      name="salestoMsl"
                      checked={transferSalesToMsl}
                      onClick={(e) => {
                        setTransferSalesToMsl(!transferSalesToMsl);
                      }}
                    />
                    <label class="form-check-label" for="salestoMsl">
                      {t("orgs.org_details.transfer_sales_to_msl")}
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="msltosales"
                      name="msltosales"
                      checked={transferMslToSales}
                      onClick={(e) => {
                        setTransferMslToSales(!transferMslToSales);
                      }}
                    />
                    <label class="form-check-label" for="msltosales">
                      {t("orgs.org_details.transfer_msl_to_sales")}
                    </label>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  mb-2">
                    <label for="durationtoreleasehangingthread">
                      {t("orgs.org_details.hanging_thread_text")}
                    </label>
                    <input
                      name="durationtoreleasehangingthread"
                      id="durationtoreleasehangingthread"
                      className="form-control"
                      type="number"
                      // placeholder={t("login.placeholder.password")}
                      value={durationToReleaseHangingThread}
                      onChange={(e) => {
                        setDurationToReleaseHangingThread(
                          e.currentTarget.value
                        );
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                    />
                    <br />
                    <label for="durationtoclosenoreplythread">
                      {t("orgs.org_details.autoclose_thread_text")}
                    </label>
                    <input
                      name="durationtoclosenoreplythread"
                      id="durationtoclosenoreplythread"
                      className="form-control form-control-md"
                      type="number"
                      // placeholder={t("login.placeholder.password")}
                      value={durationToCloseNoReplyThread}
                      onChange={(e) => {
                        setDurationToCloseNoReplyThread(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                    />
                    <br />
                    <label for="durationtostartnewthread">
                      {t("orgs.org_details.consecutive_thread_text")}
                    </label>
                    <input
                      name="durationtostartnewthread"
                      id="durationtostartnewthread"
                      className="form-control form-control-md"
                      type="number"
                      // placeholder={t("login.placeholder.password")}
                      value={durationToStartNewThread}
                      onChange={(e) => {
                        setDurationToStartNewThread(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="notificationemail">
                      {t("orgs.org_details.notification_email")}
                    </label>
                    <input
                      name="notificationemail"
                      className="form-control form-control-md"
                      type="email"
                      value={notificationEmail}
                      onChange={(e) => {
                        setNotificationEmail(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                    />
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                    <label for="notificationpass">
                      {t("orgs.org_details.notification_email_password")}
                    </label>
                    <input
                      name="notificationpass"
                      className="form-control form-control-md"
                      type="password"
                      value={notificationEmailPass}
                      onChange={(e) => {
                        setNotificationEmailPass(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <hr></hr>
                <div className="form-group row">
                  <div className="col-12">
                    <label for="notes">{t("orgs.org_details.notes")}</label>
                    <input
                      name="notes"
                      className="form-control form-control-md col-sm-12"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={formNotes}
                      onChange={(e) => {
                        setFormNotes(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12">
                    <label for="comments">
                      {t("orgs.org_details.comments")}
                    </label>
                    <input
                      name="comments"
                      className="form-control form-control-md col-sm-12"
                      type="text"
                      // placeholder={t("login.placeholder.username")}
                      value={formComments}
                      onChange={(e) => {
                        setFormComments(e.currentTarget.value);
                        checkRequiredFields(e.currentTarget.value);
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
            <div className="popup-footer">
              <button
                onClick={closeOrgPopup}
                className="btn btn-secondary ml-4"
              >
                {t("common.cancel")}
              </button>
              <button
                className="btn btn-primary ml-4"
                onClick={submitForm}
                disabled={
                  disableSubmit ||
                  inProgress ||
                  duplicateEmail ||
                  duplicateName ||
                  duplicateWebsite
                }
              >
                {t("common.save")}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditOrg);
