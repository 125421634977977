import React, { useState, useEffect } from "react";
import "zingchart/es6";
import ZingChart from "zingchart-react";
import agent from "../../agent";
import { connect } from "react-redux";
import { NUMBER_OF_HCP_ACROSS_ORG } from "../../constants/actionTypes";
import moment from "moment";
import { useTranslation } from "react-i18next";

const NumberOfHCPAcrossOrganization = ({
  getNoOfHCPAcrossOrganization,
  chartData,
  startDate,
  endDate,
  orgId,
}) => {
  const [datesArray, setDatesArray] = useState([]);
  const [userHCPData, setUserHCPData] = useState({});
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getNoOfHCPAcrossOrganization({
      fromDate: new Date(startDate).getTime(),
      toDate: new Date(endDate).getTime(),
      orgId: orgId,
    });
  }, [startDate, endDate, orgId]);

  useEffect(() => {
    let initialTime = new Date(startDate),
      endTime = new Date(endDate),
      arrTime = [];

    let yAxisArray = [];
    for (let q = initialTime; q <= endTime; q.setDate(q.getDate() + 1)) {
      const formattedDate = moment(q.toString()).format("YYYY-MM-DD");
      arrTime.push(formattedDate);
      const newChartData = chartData.filter((chartItem) => {
        return (
          moment(chartItem.item_date).format("YYYY-MM-DD") === formattedDate
        );
      });
      if (newChartData && newChartData.length) {
        yAxisArray.push(newChartData[0].item_count);
      } else {
        yAxisArray.push(0);
      }
    }
    setXAxisData(arrTime);
    setYAxisData(yAxisArray);
  }, [chartData]);

  useEffect(() => {
    setUserHCPData({
      type: "bar",
      legend: {

      },
      plot: {
        tooltip: {
          text: "%kl : %v"
        }
      },
      series: [
        {
          values: yAxisData,
          text: "Number of HCPs",
        },
      ],
      scaleX: {
        // set scale label
        label: {
          text: t("measure.dates"),
        },
        // convert text on scale indices
        labels: xAxisData,
      },
      'scale-y': {
        values: yAxisData && yAxisData.length ? `"0:${Math.max(...yAxisData)+2}:1"`: "0:10:1" //Min/Max/Step
      }
    });
  }, [yAxisData]);

  return (
    <div>
      <ZingChart data={userHCPData} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.auth,
  loginSuccess: state.common.loginSuccess,
  chartData: state.statistics.noOfHCPAcrossOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  getNoOfHCPAcrossOrganization: (data) => {
    dispatch({
      type: NUMBER_OF_HCP_ACROSS_ORG,
      payload: agent.Measure.noOfHCPAcrossOrganization(data),
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberOfHCPAcrossOrganization);
