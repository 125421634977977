import { toast } from "react-toastify";
import i18n from "i18next";
import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
  REGISTER,
  CREATE_USER,
  UPDATE_USER,
  GET_USERS_LIST,
  GET_ORGS_LIST,
  SHOW_CREATE_EDIT_USER_POPUP,
  SHOW_CREATE_EDIT_ORG_POPUP,
  CREATE_ORG,
  UPDATE_ORG,
  DELETE_ORG,
  DELETE_USER,
  UPDATE_AUTH_TOKEN,
  ON_ADVERSE_EFFECT_UPDATE,
  ON_THREAD_ACTIVE_STATUS_CHANGE,
} from "../constants/actionTypes";

const showToast = (translationKey) => {
  toast(i18n.t(translationKey));
};

export const notifySuccess = (action) => {
  const actionType = action.type || action;
  switch (actionType) {
    case CREATE_USER:
      showToast("users.messages.success.create");
      break;
    case UPDATE_USER:
      showToast("users.messages.success.update");
      break;
    case DELETE_USER:
      showToast("users.messages.success.delete");
      break;
    case UPDATE_AUTH_TOKEN:
      showToast("connect.otp_update_successfully");
    case ON_ADVERSE_EFFECT_UPDATE:
      showToast("chat.adverse.popup.update_success");
    case ON_THREAD_ACTIVE_STATUS_CHANGE:
      showToast("chat.thread_status_change_success");
    default:
      return false;
  }
};

export const notifyError = (action) => {
  const actionType = action.type || action;
  const actionPayload = action.payload || action;
  switch (actionType) {
    case CREATE_USER:
      showToast("Error creating user!");
      break;
    case UPDATE_USER:
      showToast("Error updating User information!");
      break;
    case DELETE_USER:
      showToast("Error deleting user!");
      break;
    case ON_ADVERSE_EFFECT_UPDATE:
      showToast("chat.adverse.popup.update_error");
    default:
      showToast(actionPayload ? actionPayload : "Error!");
    case ON_THREAD_ACTIVE_STATUS_CHANGE:
      showToast("chat.thread_status_change_fail");
      return false;
  }
};

export const notifyWarning = (action) => {
  const actionType = action.type || action;
  switch (actionType) {
    case CREATE_USER:
      showToast("Error creating user!");
      break;
    case UPDATE_USER:
      showToast("Error while updating User information!");
      break;
    case DELETE_USER:
      showToast("Error deleting user!");
      break;
    default:
      return false;
  }
};

export const notifyInfo = (action) => {
  const actionType = action.type || action;
  switch (actionType) {
    case CREATE_USER:
      showToast("Error creating user!");
      break;
    case UPDATE_USER:
      showToast("Error while updating User information!");
      break;
    case DELETE_USER:
      showToast("Error deleting user!");
      break;
    default:
      return false;
  }
};
