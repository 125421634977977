import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CURRENT_VIEW,
} from "../../../constants/actionTypes";
import ContentView from "../../ContentView";
import agent from "../../../agent";

const mapStateToProps = (state) => ({
  ...state.auth,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  createEditOrgPopupData: state.common.createEditOrgPopup,
  currentUser: state.common.currentUser,
  shareableItems: state.chat.shareableItems,
});

const mapDispatchToProps = (dispatch) => ({
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
});

const ContentsPicker = (props) => {
  const { t } = useTranslation();
  const {
    showContentsPicker,
    setShowContentsPicker,
    inProgress,
    currentUser,
    shareableItems,
    onAttachConfirm,
    inPopupView,
  } = props;

  const closePopup = () => {
    setShowContentsPicker(false);
  };

  const submitForm = () => {
    console.log(shareableItems[0].file_name);
    onAttachConfirm(shareableItems);
    closePopup();
  };

  return (
    <React.Fragment>
      <div className="content-picker-popup">
        <div className="form-container">
          <div className="popup-header">
            <h5>{t("orgs.org_popup.content_popup_title")}</h5>
          </div>
          <form>
            <ContentView inPopupView={inPopupView} />
          </form>
          <div className="popup-footer">
            <button onClick={closePopup} className="btn btn-secondary ml-4">
              {t("common.cancel")}
            </button>
            <button
              className="btn btn-primary ml-4"
              onClick={submitForm}
              disabled={inProgress || !shareableItems.length}
            >
              {t("common.attach")}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentsPicker);
