import React, { useState, useEffect } from "react";
import "zingchart/es6";
import ZingChart from "zingchart-react";
import HcpSalesUserLevelChart from "./HcpSalesUserLevelChart";
import HCPChatWithMSLUserLevel from "./HCPChatWithMSLUserLevel";
import NumberOfHCPAcrossOrganization from "./NumberOfHCPAcrossOrganization";
import ContentAnalytics from "./ContentAnalytics";
import NoofConversationTransfers from "./NoofConversationTransfers";
import NumberOfUserInOrgLevel from "./NumberOfUserInOrgLevel";
import NoofHCPActiveRecord from "./NoofHCPActiveRecord";
import TotalNumberOfOrgs from "./TotalNumberOfOrgs";
import TotalNumberOfHCP from "./TotalNumberOfHCP";
import TotalNumberOfSales from "./TotalNumberOfSales";
import TotalNumberOfMSL from "./TotalNumberOfMSL";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import OrgSelector from "../OrgSelector";
import { connect } from "react-redux";
import agent from "../../agent";
import { GET_ORGS_LIST } from "../../constants/actionTypes";

const MainView = ({ currentUser, updateOrgsList }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formOrgID, setFormOrgId] = useState(
    currentUser ? currentUser.orgId : 0
  );
  const { t } = useTranslation();

  useEffect(() => {
    updateOrgsList();
  }, []);

  return (
    <div className="p-5">
      <div className="row">
        <div className="col-md-4 mb-2">
          <label for="support_agent_id">{t("measure.start_date")}</label>
          <br />
          <DatePicker
            className="form-control"
            selected={startDate}
            minDate={null}
            maxDate={null}
            onChange={(d) => {
              setStartDate(new Date(d.toLocaleString()));
            }}
          />
        </div>
        <div className="col-md-4 mb-2">
          <label for="support_agent_id">{t("measure.end_date")}</label>
          <br />
          <DatePicker
            className="form-control"
            selected={endDate}
            minDate={null}
            maxDate={null}
            onChange={(d) => {
              setEndDate(new Date(d.toLocaleString()));
            }}
          />
        </div>
        <div className="col-md-4 mb-2">
          {currentUser.roleId === 1 && (
            <div>
              <label for="support_agent_id">{t("measure.orgID")}</label>
              <br />
              <OrgSelector onSelect={setFormOrgId} />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="text-center">{t("measure.no_of_hcp_organization")}</div>
        <NumberOfHCPAcrossOrganization
          startDate={startDate}
          endDate={endDate}
          orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
        />
      </div>
      <div className="mt-4">
        <div className="text-center">{t("measure.no_of_chats_with_hcp")}</div>
        <HcpSalesUserLevelChart
          startDate={startDate}
          endDate={endDate}
          orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
        />
      </div>
      <div className="mt-4">
        <div className="text-center">{t("measure.no_of_chats_with_hcp_msl_level")}</div>
        <HCPChatWithMSLUserLevel
          startDate={startDate}
          endDate={endDate}
          orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
        />
      </div>
      <div className="mt-4">
        <div className="text-center">{t("measure.content_analytics")}</div>
        <ContentAnalytics
          startDate={startDate}
          endDate={endDate}
          orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
        />
      </div>
      <div className="mt-4">
        <div className="text-center"> {t("measure.conversation_transfers")}</div>
        <NoofConversationTransfers
          startDate={startDate}
          endDate={endDate}
          orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
        />
      </div>
      <div className="mt-4">
        <div className="text-center">{t("measure.no_of_users")}</div>
        <NumberOfUserInOrgLevel
          startDate={startDate}
          endDate={endDate}
          orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
        />
      </div>
      <div className="mt-4">
        <div className="text-center">{t("measure.no_of_active_records")}</div>
        <NoofHCPActiveRecord
          startDate={startDate}
          endDate={endDate}
          orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
        />
      </div>

      {currentUser.roleId === 1 && (
        <div>
          <div className="mt-4">
            <div className="text-center">{t("measure.total_orgs")}</div>
            <TotalNumberOfOrgs
              startDate={startDate}
              endDate={endDate}
              orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
            />
          </div>

          <div className="mt-4">
            <div className="text-center">{t("measure.total_hcps")}</div>
            <TotalNumberOfHCP
              startDate={startDate}
              endDate={endDate}
              orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
            />
          </div>

          <div className="mt-4">
            <div className="text-center">{t("measure.total_sales")}</div>
            <TotalNumberOfSales
              startDate={startDate}
              endDate={endDate}
              orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
            />
          </div>

          <div className="mt-4">
            <div className="text-center">{t("measure.total_msls")}</div>
            <TotalNumberOfMSL
              startDate={startDate}
              endDate={endDate}
              orgId={currentUser.roleId === 1 ? formOrgID : currentUser.orgId}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateOrgsList: () =>
    dispatch({ type: GET_ORGS_LIST, payload: agent.Orgs.getList() }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
