import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import agent from "../../agent";
import MainView from "./MainView";
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  APPLY_TAG_FILTER,
  CURRENT_VIEW,
  LOGOUT,
  DASHBOARD_DATA_GET,
} from "../../constants/actionTypes";

import "./Dashboard.scss";

const Promise = global.Promise;

const mapStateToProps = (state) => ({
  ...state.home,
  appName: state.common.appName,
  currentUser: state.common.currentUser,
  token: state.common.token,
  dashboardData: state.common.dashboardData,
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => dispatch({ type: LOGOUT }),
  onClickTag: (tag, pager, payload) =>
    dispatch({ type: APPLY_TAG_FILTER, tag, pager, payload }),
  onLoad: (tab, pager, payload) =>
    dispatch({ type: HOME_PAGE_LOADED, tab, pager, payload }),
  onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
  onViewChange: (payload) => dispatch({ type: CURRENT_VIEW, payload }),
  getDashboardData: () =>
    dispatch({
      type: DASHBOARD_DATA_GET,
      payload: agent.Dashboard.getDashboardData(),
    }),
});

const Dashboard = (props) => {
  const {
    token,
    currentUser,
    onLoad,
    onViewChange,
    onUnload,
    onSignOut,
    getDashboardData,
    dashboardData,
  } = props;
  const [dashboardInfo, setDashboardInfo] = useState("");
  useEffect(() => {
    onViewChange({ id: "1", name: "sideMenu.dashboard" });
    if (getDashboardData) {
      getDashboardData();
    }

    return () => {
      onUnload();
    };
  }, []);

  useEffect(() => {
    if (dashboardData) setDashboardInfo(dashboardData);
  }, [dashboardData]);

  return (
    <div className="view-container dashboard-view">
      <MainView dashboardInfo={dashboardInfo} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
