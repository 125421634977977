import React, { useEffect, useState } from "react";
import agent from "../agent";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  LOGIN_PAGE_UNLOADED,
} from "../constants/actionTypes";

import "./styles/RoleSelector.scss";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  roles: state.common.roles,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeUsername: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "username", value }),
  onChangePassword: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "password", value }),
  onSubmit: (username, password) => {
    dispatch({ type: LOGIN, payload: agent.Auth.login(username, password) });
  },
});

const RoleSelector = (props) => {
  const {
    roles,
    onSubmit,
    currentUser,
    propRoleId,
    propDisabled,
    onSelect,
  } = props;
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState({});
  const [rolesList, setRolesList] = useState([]);

  const submitForm = (email, password) => (ev) => {
    ev.preventDefault();
    onSubmit(email, password);
  };
  const filterRolesList = (list) => {
    if (currentUser && currentUser.roleId) {
      if (currentUser.roleId === 1) {
        const filteredList = list.filter((item) => item.uid !== 5);
        setRolesList(filteredList);
        if (propRoleId) {
          setSelectedItem(propRoleId);
        } else {
          setSelectedItem(currentUser.roleId);
        }
      } else if (currentUser.roleId === 2) {
        const filteredList = list.filter((item) => item.uid !== 1);
        setRolesList(filteredList);
        if (propRoleId) {
          setSelectedItem(propRoleId);
        } else {
          setSelectedItem(currentUser.roleId);
        }
      } else {
        setRolesList([]);
      }
    }
  };

  const onChange = (e) => {
    setSelectedItem(e.target.value);
    if (onSelect) {
      onSelect(e.target.value);
    }
  };

  useEffect(() => {
    if (roles && roles.length) {
      filterRolesList(roles);
    }
    return () => {
      // onUnload();
    };
  }, [propRoleId]);
  return (
    <div className="form-group role-selector">
      <select
        value={selectedItem}
        onChange={onChange}
        disabled={propDisabled}
        className="form-control"
      >
        {rolesList.map((role) => {
          return (
            <option value={role.uid} key={role.uid}>
              {role.strRole}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleSelector);
