import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import agent from "../../agent";
import {
  CHANGE_TAB,
  SEND_DOCUMENT_ATTACHMENT,
  GET_DOCUMENT_ATTACHMENT,
  RELOAD_NEW_ATTACHMENT,
  SEND_VIDEO_ATTACHMENT,
  GET_VIDEO_ATTACHMENT,
  SEND_TEXT_ATTACHMENT,
  GET_MESSAGE_ATTACHMENT,
  GET_HCP_USERS_LIST,
  DELETE_ATTACHMENT,
  SHARE_DATA,
  SHARE_CONTENT_TO_RECIPIENT,
} from "../../constants/actionTypes";
import VideoPlayer from "../VideoPlayer";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  content: state.content,
  recipientsArray: state.common.hcpUsers,
  currentUser: state.common.currentUser,
  activeRecipientsArray: state.chat.chatRecipients,
});

const mapDispatchToProps = (dispatch) => ({
  onShareDataChange: (data) => dispatch({ type: SHARE_DATA, payload: data }),
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
  onAddDocument: (files) =>
    dispatch({
      type: SEND_DOCUMENT_ATTACHMENT,
      payload: agent.content.add(files),
    }),
  onAddConten: (files) =>
    dispatch({
      type: SEND_VIDEO_ATTACHMENT,
      payload: agent.content.addVideo(files),
    }),
  onAddMessage: (files) =>
    dispatch({
      type: SEND_TEXT_ATTACHMENT,
      payload: agent.content.addText(files),
    }),
  onGetDocument: (page, sort, search) =>
    dispatch({
      type: GET_DOCUMENT_ATTACHMENT,
      payload: agent.content.getDocument(page, sort, search),
    }),
  onGetVideo: (page, sort, search) =>
    dispatch({
      type: GET_VIDEO_ATTACHMENT,
      payload: agent.content.getVideo(page, sort, search),
    }),
  onGetMessage: (page, sort, search) =>
    dispatch({
      type: GET_MESSAGE_ATTACHMENT,
      payload: agent.content.getMessage(page, sort, search),
    }),
  onDeleteContent: (id) =>
    dispatch({
      type: DELETE_ATTACHMENT,
      payload: agent.content.deleteContent(id),
    }),
  getRecipientsList: () =>
    dispatch({
      type: GET_HCP_USERS_LIST,
      payload: agent.Users.getHcpList(),
    }),
  onReload: () =>
    dispatch({
      type: RELOAD_NEW_ATTACHMENT,
      payload: false,
    }),
  shareContentWithHcp: (data) =>
    dispatch({ type: SHARE_CONTENT_TO_RECIPIENT, payload: data }),
});

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const MainView = (props) => {
  const {
    content,
    recipientsArray,
    activeRecipientsArray,
    currentUser,
    onGetDocument,
    onGetVideo,
    onGetMessage,
    onDeleteContent,
    getRecipientsList,
    onReload,
    onShareDataChange, // send share data to redux for use in chat attachment
    shareContentWithHcp, // send share data from contents page to redux to use in selected hcp chat thread
    inPopupView, // set tab view based on chat content share view
    chatThreads,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedTabItem, setSelectedTabItem] = useState(null);
  const [selectedPageItem, setSelectedPageItem] = useState(null);
  const [sort, setSort] = useState("timestamp desc");
  const [model, setModal] = useState(false);
  const [searchParams, setSearchParams] = useState("");
  const [videoName, setVideoName] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentURL, setDocumentURL] = useState("");
  const [videos, setVideos] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [videoModel, setVideoModel] = useState(false);
  const [documentModel, setDocumentModel] = useState(false);
  const [shareModel, setShareModel] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [fileUrl, setFileUrl] = useState([]);
  const [shareMedia, setShareMedia] = useState([]);
  const [shareData, setShareData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState([]);
  const [page, setPage] = useState(1);
  const [textHeader, setTextHeader] = useState("");
  const [textBody, setTextBody] = useState("");
  const [shareWithHcp, setShareWithHcp] = useState(null);

  const onUploadAllDocument = (e) => {
    const files = e.target.files;
    var formdata = new FormData();
    formdata.append(`media_type`, 11);
    if (documentName.trim() != "" && documentURL.trim() != "") {
      formdata.append(`file_name`, documentName);
      formdata.append(`media_location`, documentURL);
      setDocumentName("");
      setDocumentURL("");
    }
    if (documents && Object.keys(documents).length > 0) {
      Object.keys(documents).map((index) => {
        formdata.append(`document`, documents[index]);
      });
    }
    props.onAddDocument(formdata);
    setDocumentModel(false);
    onReload();
  };

  const deleteContent = () => {
    onDeleteContent(deleteId);
    if (contentType === "doc") {
      onGetDocument(selectedPageItem.id - 1, sort, searchParams);
      setPage(content && content.contentPages);
    }
    if (contentType === "video") {
      onGetVideo(selectedPageItem.id - 1, sort, searchParams);
      setPage(content && content.videosPages);
    }
    if (contentType === "text") {
      onGetMessage(0, sort, searchParams);
      setPage(content && content.messagePages);
    }
    setDeleteModel(false);
    setDeleteId(null);
  };

  const setDeleteContents = (type, id) => {
    console.log("Called ", type, id);
    setDeleteId(id);
    setContentType(type);
    setDeleteModel(true);
  };

  useEffect(() => {
    if (content.updated) {
      onGetDocument(0, sort, searchParams);
      onGetVideo(0, sort, searchParams);
      onGetMessage(0, sort, searchParams);
      onReload();
      setSelectedPageItem({ id: 1, name: 1 });
    }
  }, [content.updated]);

  // getRecipientsList call this dispatch to get the list of hcp,

  // console.log("recipientsArray", recipientsArray, getRecipientsList)
  const onSubmitVideo = (e) => {
    var formdata = new FormData();
    formdata.append(`media_type`, 3);
    if (videoName.trim() != "" && videoURL.trim() != "") {
      formdata.append(`file_name`, videoName);
      formdata.append(`media_location`, videoURL);
      setVideoName("");
      setVideoURL("");
    }
    if (videos && Object.keys(videos).length > 0) {
      Object.keys(videos).map((index) => {
        formdata.append(`video`, videos[index]);
      });
    }
    onReload();
    props.onAddConten(formdata);
    setVideoModel(false);
  };
  const onUploadVideo = (e) => {
    const validVideoTypes = ["video/mp4", "video/mov", "video/avi", "video/webm", "video/wmv", "video/ogg"];
    if(e.target.files && e.target.files[0] && e.target.files[0].type){ 
      const isValidType = validVideoTypes.filter(item => {
        return item.toLowerCase() === e.target.files[0].type.toLowerCase();
      });
      if(isValidType && isValidType.length){
        const files = e.target.files;
        setVideos(files);
      }else{
        toast(t("content.errors.invalid_file_type_upload_video"));
        return false;
      }
    }
  };

  const onUploadDocument = (e) => {
    if(e.target.files && e.target.files[0] && e.target.files[0].type && e.target.files[0].type === "application/pdf" ){ 
      const files = e.target.files;
      setDocuments(files);
    }else if(e.target.files && e.target.files[0] && e.target.files[0].type && e.target.files[0].type !== "application/pdf"){
      toast(t("content.errors.invalid_file_type_upload_pdf"));
      return false;
    }
  };

  const showVideoModel = () => {
    setVideoModel(true);
  };

  const showDocumentModel = () => {
    setDocumentModel(true);
  };

  const onUploadMessage = (heading, body) => {
    if (heading.trim() !== "" && body.trim() !== "") {
      var formdata = new FormData();
      formdata.append(`media_type`, 1);
      formdata.append(`text_heading`, heading);
      formdata.append(`pre_approved_text`, body);
      props.onAddMessage(formdata);
    }
    setTextHeader("");
    setTextBody("");
    setShowModel(false);
    onReload();
    onGetMessage(0, sort, searchParams);
  };

  const handleSearch = (e) => {
    setSearchParams(e.target.value);
    if (selectedTabItem.id == 1) {
      onGetDocument(0, sort, e.target.value);
      setPage(content && content.contentPages);
    }
    if (selectedTabItem.id == 2) {
      onGetVideo(0, sort, e.target.value);
      setPage(content && content.videosPages);
    }
    if (selectedTabItem.id == 3) {
      onGetMessage(0, sort, e.target.value);
      setPage(content && content.messagePages);
    }
  };

  const shareAttachments = () => {
    setShareModel(false);
    // here we can pass the uid's to recipts.
    shareContentWithHcp({ hcp_id: shareWithHcp, share_data: shareData });
    history.push("/connect");
  };

  const share = (e, type) => {
    if (type === "single") {
      console.log("uids", e);
    } else {
      console.log("uids", shareData);
    }
    setShareModel(!shareModel);
  };

  useEffect(() => {
    onGetDocument(0, sort, searchParams);
    onGetVideo(0, sort, searchParams);
    onGetMessage(0, sort, searchParams);
    getRecipientsList();
  }, []);

  useEffect(() => {
    setPage(content && content.contentPages);
  }, [content.contentPages]);

  useEffect(() => {
    console.log(chatThreads);
  }, [chatThreads]);

  const tabsData = [
    { id: "1", name: t("content.documents", "Documents") },
    { id: "2", name: t("content.videos", "Videos") },
    { id: "3", name: t("content.pre_approved_text", "Pre-Approved Text") },
  ];

  const TabsComponent = (data) => {
    const { items } = data;

    useEffect(() => {
      if (items && items.length) {
        if (!selectedTabItem) {
          if (inPopupView) {
            const tabToSelect = tabsData.filter((li) => li.id === inPopupView);
            if (tabToSelect && tabToSelect.length) {
              setSelectedTabItem(tabToSelect[0]);
            }
          } else {
            setSelectedTabItem(items[0]);
          }
        }
      }
    }, [items]);
    const tabSwitch = (item) => {
      setShareData([]);
      setSelectedTabItem(item);
      setSearchParams("");
      setShareMedia([]);
      setSelectedPageItem({ id: 1, name: 1 });
      if (item.id == 1) {
        onGetDocument(0, sort, "");
        setPage(content && content.contentPages);
      } else if (item.id == 2) {
        onGetVideo(0, sort, "");
        setPage(content && content.videosPages);
      } else if (item.id == 3) {
        onGetMessage(0, sort, "");
        setPage(content && content.messagePages);
      }
    };

    return (
      <div className="tabs-block">
        {items &&
          items.map((item) => {
            return (
              <div
                className={
                  selectedTabItem && selectedTabItem.id === item.id
                    ? "tab-item selected"
                    : "tab-item"
                }
                key={item.id}
                onClick={() => tabSwitch(item)}
              >
                <div className="tab-text">{item.name}</div>
              </div>
            );
          })}
      </div>
    );
  };

  const handleCheck = (data) => {
    setShareModel(false);
    if (shareData && shareData.length) {
      const shareExist = shareData.filter((item) => item.uid === data.uid);
      if (shareExist && shareExist.length) {
        const updatedShareData = shareData.filter(
          (item) => item.uid !== data.uid
        );
        setShareData(
          updatedShareData && updatedShareData.length
            ? [...updatedShareData]
            : []
        );
      } else {
        const updatedShareData = [...shareData, data];
        setShareData(updatedShareData);
      }
    } else {
      setShareData([data]);
    }
  };
  const CloseModel = () => {
    setShowModel(false);
    setVideoModel(false);
    setDocumentModel(false);
    setShareModel(false);
    setDeleteModel(false);
    setDeleteId(null);
  };

  const PreviewPDF = (index) => {
    if (!isNaN(index)) {
      const location = content.document
        ? content.document[index].media_location
        : "";
      const name = content.document ? content.document[index].file_name : "";
      setPreview(location);
      setFileName(name);
    }
    setModal(!model);
  };

  const handleSort = (e) => {
    setSort(e.target.value);
    if (selectedTabItem.id == 1) onGetDocument(0, e.target.value, searchParams);
    if (selectedTabItem.id == 2) onGetVideo(0, e.target.value, searchParams);
    if (selectedTabItem.id == 3) onGetMessage(0, e.target.value, searchParams);
    setSelectedPageItem({
      id: 1,
      name: 1,
    });
  };

  const PaginationComponent = (data) => {
    const { items } = data;
    let pages = [];
    for (let i = 1; i < items.length + 1; i++) {
      pages.push({
        id: i,
        name: i,
      });
    }

    const onPrev = () => {
      if (selectedPageItem.id > 1) {
        if (selectedTabItem.id == 1)
          onGetDocument(selectedPageItem.id - 2, sort, searchParams);
        if (selectedTabItem.id == 2)
          onGetVideo(selectedPageItem.id - 2, sort, searchParams);
        if (selectedTabItem.id == 3)
          onGetMessage(selectedPageItem.id - 2, sort, searchParams);
        setSelectedPageItem({
          id: selectedPageItem.id - 1,
          name: selectedPageItem.name - 1,
        });
      }
    };

    const onNext = () => {
      if (pages.length > selectedPageItem.id) {
        setSelectedPageItem({
          id: selectedPageItem.id + 1,
          name: selectedPageItem.name + 1,
        });
        if (selectedTabItem.id == 1)
          onGetDocument(selectedPageItem.id, sort, searchParams);
        if (selectedTabItem.id == 2)
          onGetVideo(selectedPageItem.id, sort, searchParams);
        if (selectedTabItem.id == 3)
          onGetMessage(selectedPageItem.id, sort, searchParams);
      }
    };
    const onSelectPagination = (item) => {
      setSelectedPageItem(item);
      if (selectedTabItem.id == 1)
        onGetDocument(item.id - 1, sort, searchParams);
      if (selectedTabItem.id == 2) onGetVideo(item.id - 1, sort, searchParams);
      if (selectedTabItem.id == 3)
        onGetMessage(item.id - 1, sort, searchParams);
    };

    useEffect(() => {
      if (pages && pages.length) {
        if (!selectedPageItem) {
          setSelectedPageItem(pages[0]);
        }
      }
    }, [pages]);

    console.log("pages", pages.length, selectedPageItem && selectedPageItem.id);
    return (
      <div className="pagination-block">
        {selectedPageItem &&
          pages.length !== 1 &&
          pages.length <= selectedPageItem.id && (
            <div className="pagination-item" onClick={onPrev}>
              <div className="pagination-text">Prev</div>
            </div>
          )}
        {pages &&
          pages.map((item) => {
            return (
              <div
                className={
                  selectedPageItem && selectedPageItem.id === item.id
                    ? "pagination-item selected"
                    : "pagination-item"
                }
                key={item.id + 1}
                onClick={() => onSelectPagination(item)}
              >
                <div className="pagination-text">{item.name}</div>
              </div>
            );
          })}
        {selectedPageItem && pages.length > selectedPageItem.id ? (
          <div className="pagination-item" onClick={onNext}>
            <div className="pagination-text">Next</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  useEffect(() => {
    // update share data to redux for use in chat attachement
    if (shareData) {
      onShareDataChange(shareData);
    } else {
      onShareDataChange([]);
    }
    return () => {
      onShareDataChange([]);
    };
  }, [shareData]);

  return (
    <div className="mainview-container">
      <div className="main-body-container">
        <div className="contents-head">
          <div className="search-section">
            <div className="search-box">
              <div className="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder={t("content.quick_search_content")}
                  value={searchParams}
                  onChange={(e) => handleSearch(e)}
                />
                <div class="input-group-append">
                  <span class="input-group-text"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-section">
            <TabsComponent items={tabsData} />
          </div>
          <div className="sort-section">
            <div className="sort-container">
              <label for="sort">{t("content.sort_by")}</label>
              <select value={sort} onChange={handleSort}>
                <option value="timestamp desc">{t("content.date")}</option>
                {/* <option value="size">Size</option> */}
                <option value="file_name asc">Name</option>
              </select>
            </div>
            {shareData.length > 0 ? (
              <div>
                <button
                  className="btn add-submit mr-4"
                  onClick={(e) => share(e, "multi")}
                >
                  Share selected
                </button>
              </div>
            ) : (
              <></>
            )}
            <Modal
              isOpen={shareModel && shareData.length > 0}
              toggle={CloseModel}
              className="share-model"
            >
              <ModalBody className="py-4 share-model-body">
                {activeRecipientsArray && activeRecipientsArray.length ? (
                  <React.Fragment>
                    <ul>
                      {activeRecipientsArray &&
                        activeRecipientsArray.length &&
                        activeRecipientsArray.map((item) => (
                          <li
                            className={`${
                              item.hcp.uid === shareWithHcp ? "selected" : ""
                            }`}
                            onClick={() => setShareWithHcp(item.hcp.uid)}
                          >
                            {item.hcp.fName} {item.hcp.lName}
                          </li>
                        ))}
                    </ul>
                    <button
                      className="btn mt-5 mb-0"
                      onClick={shareAttachments}
                    >
                      Share
                    </button>
                  </React.Fragment>
                ) : (
                  <div>{t("content.no_recipients_to_share")}</div>
                )}
              </ModalBody>
            </Modal>
            {currentUser.roleId === 2 &&
              selectedTabItem &&
              selectedTabItem.id === "3" && (
                <div>
                  <button
                    className="btn add-submit mr-4"
                    onClick={() => setShowModel(true)}
                  >
                    Add
                  </button>
                </div>
              )}
          </div>
        </div>
        <div className="contents-body">
          <div className="contents-main-section">
            <Modal
              isOpen={deleteModel}
              toggle={CloseModel}
              className="delete-model"
            >
              <ModalBody className="delete-model-body">
                <h4>Are you sure to delete this content</h4>
                <div className="btn-grp">
                  <button className="yes" onClick={deleteContent}>
                    Yes
                  </button>
                  <button className="no" onClick={CloseModel}>
                    No
                  </button>
                </div>
              </ModalBody>
            </Modal>
            {selectedTabItem && selectedTabItem.id === "1" && (
              <ul className="documents-section">
                {currentUser.roleId === 2 && (
                  <li className="document-item">
                    <div className="doc-inset">
                      <div className="doc-upload-icon">
                        <div onClick={showDocumentModel}></div>
                      </div>
                      <div className="doc-name">upload Document</div>
                    </div>
                  </li>
                )}
                {content &&
                  content.document &&
                  content.document.map((item, i) => {
                    return (
                      <li className="document-item">
                        <div className="doc-inset">
                          <div className="doc-icon">
                            <div></div>
                          </div>
                          <div className="doc-name">{item.file_name}</div>
                        </div>
                        <div className="overlay">
                          {(currentUser.roleId === 3 ||
                            currentUser.roleId === 4 ||
                            currentUser.roleId === 5) && (
                            <input
                              type="checkbox"
                              className="icon select-checkbox"
                              defaultValue={null}
                              onClick={() => handleCheck(item)}
                            />
                          )}
                          <div
                            className="icon icon-fullscreen"
                            onClick={() => PreviewPDF(i)}
                          ></div>

                          <a
                            href={item.media_location}
                            download={true}
                            target="_blank"
                          >
                            <div className="icon icon-download-small"></div>
                          </a>
                          {(currentUser.roleId === 3 ||
                            currentUser.roleId === 4 ||
                            currentUser.roleId === 5) && (
                            <div
                              className="icon icon-chat-small"
                              onClick={(e) => share(item.uid, "single")}
                            ></div>
                          )}
                          {currentUser.roleId === 2 && (
                            <div
                              onClick={() => setDeleteContents("doc", item.uid)}
                            >
                              <h2 className="delete">-</h2>
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            )}
            <Modal
              isOpen={documentModel}
              toggle={CloseModel}
              className="doc-model"
            >
              <ModalBody className="py-4 doc-model-body">
                <div className="doc-model-body-top">
                  <input
                    className="field-inputs"
                    type="text"
                    value={documentName}
                    name="textHeader"
                    placeholder="File Name"
                    onChange={(e) => setDocumentName(e.target.value)}
                  />
                  <input
                    type="textarea"
                    className="field-inputs"
                    value={documentURL}
                    placeholder="File URL"
                    name="textBody"
                    onChange={(e) => setDocumentURL(e.target.value)}
                  />
                  <span>( OR )</span>
                  <div className="custom-file-uploader">
                    <label className="upload-doc-btn">
                      <input
                        type="file"
                        multiple
                        accept=".pdf"
                        onChange={onUploadDocument}
                        className="btn"
                      />{" "}
                      Browse
                    </label>
                  </div>
                </div>
                <button
                  className="btn mt-5 mb-0 upload-doc-submit-btn"
                  onClick={onUploadAllDocument}
                >
                  submit
                </button>
              </ModalBody>
            </Modal>
            <Modal isOpen={model} toggle={PreviewPDF} className="pdf-model">
              <ModalHeader className="pdf-model-header" toggle={PreviewPDF}>
                {fileName && fileName}
              </ModalHeader>
              <ModalBody>
                <>
                  <h2>{fileName && fileName}</h2>
                  <iframe src={preview} className="frame-container"></iframe>
                </>
              </ModalBody>
            </Modal>
            {selectedTabItem && selectedTabItem.id === "2" && (
              <div className="videos-section">
                {currentUser.roleId === 2 && (
                  <div className="video-item">
                    <div className="video-inset">
                      <div className="video-upload-icon">
                        <div onClick={showVideoModel}></div>
                      </div>
                      <div className="video-name">video upload</div>
                    </div>
                  </div>
                )}
                {content &&
                  content.videos &&
                  content.videos.map((item) => {
                    return (
                      <VideoPlayer
                        {...item}
                        deleteContent={setDeleteContents}
                        handleCheck={() => handleCheck(item)}
                        share={share}
                      />
                    );
                  })}
              </div>
            )}
            <Modal
              isOpen={videoModel}
              toggle={CloseModel}
              className="videos-model"
            >
              <ModalBody className="py-4" className="videos-model-body">
                <div className="videos-model-body-top">
                  <input
                    className="field-inputs"
                    type="text"
                    value={videoName}
                    name="textHeader"
                    placeholder="File Name"
                    onChange={(e) => setVideoName(e.target.value)}
                  />
                  <input
                    type="textarea"
                    className="field-inputs"
                    value={videoURL}
                    placeholder="File URL"
                    name="textBody"
                    onChange={(e) => setVideoURL(e.target.value)}
                  />
                  <span>( OR )</span>
                  <div className="custom-file-uploader">
                    <label className="upload-video-btn">
                      <input
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        multiple
                        onChange={onUploadVideo}
                        className="btn"
                      />{" "}
                      Browse
                    </label>
                  </div>
                </div>
                <button
                  className="btn mt-5 mb-0 upload-video-submit-btn"
                  onClick={onSubmitVideo}
                >
                  submit
                </button>
              </ModalBody>
            </Modal>
            <div class="modal" tabindex="-1" role="dialog">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>Modal body text goes here.</p>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {selectedTabItem && selectedTabItem.id === "3" && (
              <div className="text-section">
                <div className="grid-container">
                  <div className="grid-header">
                    <div className="text">TEXT</div>
                    <div className="topic">TOPIC</div>
                    <div className="updated">LAST UPDATED</div>
                    {(currentUser.roleId === 3 ||
                      currentUser.roleId === 4 ||
                      currentUser.roleId === 5) && (
                      <div className="share">SHARE</div>
                    )}
                    {currentUser.roleId === 2 && (
                      <div className="delete">DELETE</div>
                    )}
                  </div>
                  <div className="scroll-message">
                    {content &&
                      content.message &&
                      content.message.map((item) => {
                        return (
                          <div className="grid-item">
                            <div className="text">{item.pre_approved_text}</div>
                            <div className="topic">{item.text_heading}</div>
                            <div className="updated">
                              {moment(item.updated_at).format("DD MMM YYYY")}
                            </div>
                            {(currentUser.roleId === 3 ||
                              currentUser.roleId === 4 ||
                              currentUser.roleId === 5) && (
                              <input
                                type="checkbox"
                                className="icon select-checkbox"
                                defaultValue={null}
                                onClick={() => handleCheck(item)}
                              />
                            )}
                            {currentUser.roleId === 2 && (
                              <div
                                className="delete"
                                onClick={() =>
                                  setDeleteContents("text", item.uid)
                                }
                              >
                                Delete
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
            <Modal
              isOpen={showModel}
              toggle={CloseModel}
              className="text-model"
            >
              <ModalBody className="py-4 text-model-body">
                <div className="text-model-body-top">
                  <input
                    className="field-inputs"
                    type="text"
                    value={textHeader}
                    name="textHeader"
                    placeholder="Enter the heading"
                    onChange={(e) => setTextHeader(e.target.value)}
                  />
                  <textarea
                    type="textarea"
                    className="field-inputs"
                    value={textBody}
                    placeholder="Enter the message"
                    name="textBody"
                    onChange={(e) => setTextBody(e.target.value)}
                  />
                </div>
                <button
                  className="btn  mt-5 mb-0 text-model-submit"
                  onClick={() => onUploadMessage(textHeader, textBody)}
                >
                  submit
                </button>
              </ModalBody>
            </Modal>
          </div>
          <div className="pagination-section">
            {selectedTabItem && selectedTabItem.id === "3" ? (
              <> </>
            ) : (
              <PaginationComponent items={[...Array(page).keys()]} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
