import React, { useState, useEffect } from "react";
import agent from "../../agent";
import { connect } from "react-redux";
import {
  CHANGE_TAB,
  SHOW_CREATE_EDIT_USER_POPUP,
  DELETE_USER,
} from "../../constants/actionTypes";
import { useTranslation } from "react-i18next";
import RoleSelector from "../RoleSelector";
import OrgSelector from "../OrgSelector";
import { Roles } from "../../constants/rolesConstants";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  orgs: state.common.orgs,
  users: state.common.users,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
  showUserPopup: (editData) =>
    dispatch({ type: SHOW_CREATE_EDIT_USER_POPUP, payload: editData || true }),
  deleteUser: (deleteData) =>
    dispatch({
      type: DELETE_USER,
      payload: agent.Users.deleteUser(deleteData),
    }),
  deleteHcp: (deleteData) =>
    dispatch({
      type: DELETE_USER,
      payload: agent.Users.deleteHcp(deleteData),
    }),
});

const MainView = (props) => {
  const {
    showUserPopup,
    usersArray,
    hcpsArray,
    orgs,
    users,
    currentUser,
    deleteUser,
    deleteHcp,
    showDeletePopup,
  } = props;
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedHcp, setSelectedHcp] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isUsersTabSelected, setIsUsersTabSelected] = useState(true);

  const onUserSelect = (item) => {
    setSelectedUser(item);
  };

  const onHcpSelect = (item) => {
    setSelectedHcp(item);
  };

  const submitForm = (formData) => {
    console.log(formData);
  };

  const hideDeletePopup = () => {
    showDeletePopup(null);
  };

  const afterDeletePopupClose = () => {
    // TODO
  };

  const onDeleteUser = (deleteObj) => {
    const { uid } = deleteObj;
    const popupData = {
      inProgress: null,
      onConfirm: () => deleteUser(uid),
      onCancel: hideDeletePopup,
      onDestroy: afterDeletePopupClose,
      primaryButtonText: "common.yes",
      secondaryButtonText: "common.no",
      confirmTitle: "common.confirm",
      confirmMessage: "users.user_delete.confirm_message",
    };
    showDeletePopup(popupData);
  };

  const onDeleteHcp = (deleteObj) => {
    const { uid } = deleteObj;
    const popupData = {
      inProgress: null,
      onConfirm: () => deleteHcp(uid),
      onCancel: hideDeletePopup,
      onDestroy: afterDeletePopupClose,
      primaryButtonText: "common.yes",
      secondaryButtonText: "common.no",
      confirmTitle: "common.confirm",
      confirmMessage: "users.user_delete.confirm_message",
    };
    showDeletePopup(popupData);
  };

  const getUserName = (id) => {
    if (users && users.length) {
      const user = users.filter((item) => item.uid === id);
      return user && user.length && user[0] && user[0].username
        ? user[0].username
        : null;
    }
  };

  const getOrgName = (id) => {
    if (orgs && orgs.length) {
      const org = orgs.filter((item) => item.uid === id);
      return org && org.length && org[0].name ? org[0].name : "Unknown";
    }
  };

  const getRoleName = (id) => {
    if (Roles && Roles.length) {
      const role = Roles.filter((item) => item.uid === id);
      return role && role[0] && role[0].name;
    }
  };

  useEffect(() => {
    if (usersArray && usersArray.length && !selectedUser) {
      setSelectedUser(usersArray[0]);
    } else if (usersArray && usersArray.length && selectedUser) {
      const alreadySelected = usersArray.filter((item) => {
        return item.uid === selectedUser.uid;
      });
      if (alreadySelected && alreadySelected.length) {
        setSelectedUser(alreadySelected[0]);
      } else {
        setSelectedUser(usersArray[0]);
      }
    }
    hideDeletePopup();
  }, [usersArray]);

  useEffect(() => {
    if (hcpsArray && hcpsArray.length && !selectedHcp) {
      setSelectedHcp(hcpsArray[0]);
    } else if (hcpsArray && hcpsArray.length && selectedHcp) {
      const alreadySelected = hcpsArray.filter((item) => {
        return item.uid === selectedHcp.uid;
      });
      if (alreadySelected && alreadySelected.length) {
        setSelectedHcp(alreadySelected[0]);
      } else {
        setSelectedHcp(hcpsArray[0]);
      }
    } else if (hcpsArray && !hcpsArray.length) {
      setSelectedHcp(null);
    }
    hideDeletePopup();
  }, [hcpsArray]);

  return (
    <div className="mainview-container">
      <div className="names-container">
        <div className="title-container">
          <h6 className="title">{t("users.list_title")}</h6>
          <div className="add-user" onClick={() => showUserPopup()}></div>
        </div>
        <div className="names-list-container">
          {currentUser &&
            currentUser.roleId &&
            currentUser.roleId !== 1 &&
            currentUser.roleId !== "1" && (
              <div className="tab-div">
                <div
                  className={`${
                    isUsersTabSelected ? "tab-item selected" : "tab-item"
                  }`}
                  onClick={() => setIsUsersTabSelected(true)}
                >
                  Users
                </div>
                <div
                  className={`${
                    isUsersTabSelected ? "tab-item" : "tab-item selected"
                  }`}
                  onClick={() => setIsUsersTabSelected(false)}
                >
                  HCPs
                </div>
              </div>
            )}
          <ul className="names-list">
            {isUsersTabSelected && (
              <React.Fragment>
                {usersArray &&
                  usersArray.length > 0 &&
                  usersArray.map((item) => {
                    return (
                      <li
                        className={
                          selectedUser && selectedUser.uid === item.uid
                            ? `${item.uid} names-item selected`
                            : `${item.uid} names-item`
                        }
                        key={item.uid}
                        onClick={() => onUserSelect(item)}
                      >
                        <div className="username-block">
                          <div
                            className="user-name"
                            title={item.username || ""}
                          >
                            {item.username ||
                              `${item.fName} ${item.lName}` ||
                              ""}
                          </div>
                          <div
                            className="org-name"
                            title={getOrgName(item.orgId)}
                          >
                            {getOrgName(item.orgId)}
                          </div>
                        </div>
                        <div className="full-name-block">
                          {selectedUser && selectedUser.uid === item.uid && (
                            <React.Fragment>
                              <div
                                className="name-sub"
                                title={`${item.fName} ${item.lName} (${item.username})`}
                              >
                                <bold>{`${item.fName} ${item.lName} (${
                                  item.username ? item.username : "HCP"
                                })`}</bold>
                              </div>
                              <div
                                className="role-org-sub"
                                title={`${t(
                                  getRoleName(item.roleId)
                                )}, ${getOrgName(item.orgId)}`}
                              >
                                {`${t(getRoleName(item.roleId))}, ${getOrgName(
                                  item.orgId
                                )}`}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </React.Fragment>
            )}
            {!isUsersTabSelected && (
              <React.Fragment>
                {hcpsArray &&
                  hcpsArray.length > 0 &&
                  hcpsArray.map((item) => {
                    return (
                      <li
                        className={
                          selectedHcp && selectedHcp.uid === item.uid
                            ? `${item.uid} names-item selected`
                            : `${item.uid} names-item`
                        }
                        key={item.uid}
                        onClick={() => onHcpSelect(item)}
                      >
                        <div className="username-block">
                          <div
                            className="user-name"
                            title={item.username || ""}
                          >
                            {item.username ||
                              `${item.fName} ${item.lName}` ||
                              ""}
                          </div>
                          <div
                            className="org-name"
                            title={getOrgName(item.orgId)}
                          >
                            {getOrgName(item.orgId)}
                          </div>
                        </div>
                        <div className="full-name-block">
                          {selectedHcp && selectedHcp.uid === item.uid && (
                            <React.Fragment>
                              <div
                                className="name-sub"
                                title={`${item.fName} ${item.lName} (${item.username})`}
                              >
                                <bold>{`${item.fName} ${item.lName} (${
                                  item.username ? item.username : "HCP"
                                })`}</bold>
                              </div>
                              <div
                                className="role-org-sub"
                                title={`${t(
                                  getRoleName(item.roleId)
                                )}, ${getOrgName(item.orgId)}`}
                              >
                                {`${t(getRoleName(item.roleId))}, ${getOrgName(
                                  item.orgId
                                )}`}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </React.Fragment>
            )}
          </ul>
        </div>
      </div>
      <div className="main-body-container">
        {isUsersTabSelected && (
          <React.Fragment>
            {selectedUser && selectedUser.uid && (
              <div className="user-details-container">
                <div className="title-container mb-4">
                  <h4>{t("users.user_details.title")}</h4>
                  <div className="controls">
                    <div
                      className="icon edit-icon"
                      onClick={() => showUserPopup(selectedUser)}
                    ></div>
                    <div
                      className="icon delete-icon"
                      onClick={() => onDeleteUser(selectedUser)}
                    ></div>
                  </div>
                </div>
                <form onSubmit={() => submitForm()}>
                  <fieldset>
                    <div className="form-group row">
                      <div className="col-12 mb-2">
                        <label for="username">
                          {t("users.user_details.username")}
                        </label>
                        <input
                          name="username"
                          className="form-control form-control-md"
                          type="text"
                          // placeholder={t("login.placeholder.username")}
                          value={selectedUser.username}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                        <label for="fname">
                          {t("users.user_details.firstname")}
                        </label>
                        <input
                          name="fname"
                          className="form-control form-control-md"
                          type="text"
                          // placeholder={t("login.placeholder.password")}
                          value={selectedUser.fName}
                          disabled={isDisabled}
                        />
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                        <label for="lname">
                          {t("users.user_details.lastname")}
                        </label>
                        <input
                          name="lname"
                          className="form-control form-control-md"
                          type="text"
                          // placeholder={t("login.placeholder.password")}
                          value={selectedUser.lName}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                        <label for="email">
                          {t("users.user_details.e_mail")}
                        </label>
                        <input
                          name="email"
                          className="form-control form-control-md"
                          type="email"
                          // placeholder={t("login.placeholder.password")}
                          value={selectedUser.email}
                          disabled={isDisabled}
                        />
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                        <label for="phone">
                          {t("users.user_details.phone")}
                        </label>
                        <input
                          name="phone"
                          className="form-control form-control-md"
                          type="phone"
                          // placeholder={t("login.placeholder.password")}
                          value={selectedUser.phone}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <label for="veeva_link">
                          {t("users.user_details.veeva_link")}
                        </label>
                        <input
                          name="veeva_link"
                          className="form-control form-control-md col-sm-12"
                          type="url"
                          // placeholder={t("login.placeholder.username")}
                          value={selectedUser.veevaLink}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <label for="role">{t("users.user_details.role")}</label>
                        <RoleSelector
                          propRoleId={selectedUser.roleId}
                          propDisabled
                        />
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <label for="org">{t("users.user_details.org")}</label>
                        <OrgSelector
                          propOrgId={selectedUser.orgId}
                          propDisabled
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <label for="fname">
                          {t("users.user_details.notes")}
                        </label>
                        <input
                          name="notes"
                          className="form-control form-control-md col-sm-12"
                          type="text"
                          // placeholder={t("login.placeholder.username")}
                          value={selectedUser.notes}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <label for="comments">
                          {t("users.user_details.comments")}
                        </label>
                        <input
                          name="comments"
                          className="form-control form-control-md col-sm-12"
                          type="text"
                          // placeholder={t("login.placeholder.username")}
                          value={selectedUser.comments}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            )}
          </React.Fragment>
        )}
        {!isUsersTabSelected && (
          <React.Fragment>
            {selectedHcp && selectedHcp.uid && (
              <div className="user-details-container">
                <div className="title-container mb-4">
                  <h4>{t("users.user_details.title")}</h4>
                  <div className="controls">
                    <div
                      className="icon edit-icon"
                      onClick={() => showUserPopup(selectedHcp)}
                    ></div>
                    <div
                      className="icon delete-icon"
                      onClick={() => onDeleteHcp(selectedHcp)}
                    ></div>
                  </div>
                </div>
                <form onSubmit={() => submitForm()}>
                  <fieldset>
                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                        <label for="fname">
                          {t("users.user_details.hcp_external_id")}
                        </label>
                        <input
                          name="fname"
                          className="form-control form-control-md"
                          type="text"
                          // placeholder={t("login.placeholder.password")}
                          value={selectedHcp.externalUserId}
                          disabled={isDisabled}
                        />
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                        <label for="lname">
                          {t("users.user_details.support_agent_id")}
                        </label>
                        <input
                          name="lname"
                          className="form-control form-control-md"
                          type="text"
                          // placeholder={t("login.placeholder.password")}
                          value={getUserName(
                            selectedHcp.personalSupportAgentId
                          )}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                        <label for="fname">
                          {t("users.user_details.firstname")}
                        </label>
                        <input
                          name="fname"
                          className="form-control form-control-md"
                          type="text"
                          // placeholder={t("login.placeholder.password")}
                          value={selectedHcp.fName}
                          disabled={isDisabled}
                        />
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                        <label for="lname">
                          {t("users.user_details.lastname")}
                        </label>
                        <input
                          name="lname"
                          className="form-control form-control-md"
                          type="text"
                          // placeholder={t("login.placeholder.password")}
                          value={selectedHcp.lName}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                        <label for="email">
                          {t("users.user_details.e_mail")}
                        </label>
                        <input
                          name="email"
                          className="form-control form-control-md"
                          type="email"
                          // placeholder={t("login.placeholder.password")}
                          value={selectedHcp.email}
                          disabled={isDisabled}
                        />
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-2">
                        <label for="phone">
                          {t("users.user_details.phone")}
                        </label>
                        <input
                          name="phone"
                          className="form-control form-control-md"
                          type="phone"
                          // placeholder={t("login.placeholder.password")}
                          value={selectedHcp.phone}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4  mb-2">
                        <label for="title">
                          {t("users.user_details.hcp_title")}
                        </label>
                        <input
                          name="title"
                          className="form-control form-control-md"
                          type="text"
                          value={selectedHcp.title}
                          disabled={isDisabled}
                        />
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4  mb-2">
                        <label for="speciality">
                          {t("users.user_details.hcp_speciality")}
                        </label>
                        <input
                          name="speciality"
                          className="form-control form-control-md"
                          type="text"
                          value={selectedHcp.speciality}
                          disabled={isDisabled}
                        />
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4  mb-2">
                        <label for="org">
                          {t("users.user_details.hcp_org")}
                        </label>
                        <input
                          name="org"
                          className="form-control form-control-md"
                          type="text"
                          value={selectedHcp.org}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <label for="veeva_link">
                          {t("users.user_details.veeva_link")}
                        </label>
                        <input
                          name="veeva_link"
                          className="form-control form-control-md col-sm-12"
                          type="url"
                          // placeholder={t("login.placeholder.username")}
                          value={selectedHcp.veevaLink}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <label for="role">{t("users.user_details.role")}</label>
                        <RoleSelector
                          propRoleId={selectedHcp.roleId || "5"}
                          propDisabled
                        />
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <label for="org">{t("users.user_details.org")}</label>
                        <OrgSelector
                          propOrgId={selectedHcp.orgId}
                          propDisabled
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <label for="fname">
                          {t("users.user_details.notes")}
                        </label>
                        <input
                          name="notes"
                          className="form-control form-control-md col-sm-12"
                          type="text"
                          // placeholder={t("login.placeholder.username")}
                          value={selectedHcp.notes}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-12">
                        <label for="comments">
                          {t("users.user_details.comments")}
                        </label>
                        <input
                          name="comments"
                          className="form-control form-control-md col-sm-12"
                          type="text"
                          // placeholder={t("login.placeholder.username")}
                          value={selectedHcp.comments}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
