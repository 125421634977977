import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const AttachPreview = (props) => {
  const { attachment, setAttachment } = props;
  const { media_type, media_location, file_name } = attachment;
  const clearAttachment = () => {
    setAttachment(null);
  };
  return (
    <div className="attach-preview">
      <div className="attach-container">
        {media_type && media_type === 11 && <div className="icon pdf"></div>}
        {media_type && media_type === 3 && <div className="icon video"></div>}
        {media_type && media_type === 7 && (
          <div className="icon pre-text"></div>
        )}
        {file_name ? file_name : ""}
      </div>
      <div className="icon remove" onClick={clearAttachment}></div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachPreview);
