import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from 'react-flags-select';
import { LOGIN_PAGE_UNLOADED } from "../constants/actionTypes";

import 'react-flags-select/scss/react-flags-select.scss';
import "./styles/LanguageSelector.scss";

const mapStateToProps = (state) => ({ ...state.auth });

const mapDispatchToProps = (dispatch) => ({
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
});

const LanguageSelector = (props) => {
  const { onUnload } = props;
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const onSelectFlag = (flag) => {
    if(flag === "JP"){
      changeLanguage("ja")
    }else if(flag === "US"){
      changeLanguage("en")
    }
  };

  useEffect(() => {
    // changeLanguage("ja");
    console.log(i18n);
    return () => {
      onUnload();
    };
  }, []);

  return (
    <div className="language-selector language-container">
      {/* {i18n && i18n.language === "en" ? (
        <div
          className="language text-primary"
          onClick={() => changeLanguage("ja")}
        >
          {t("language.japanese")}
        </div>
      ) : (
        <div
          className="language text-primary"
          onClick={() => changeLanguage("en")}
        >
          {t("language.english")}
        </div>
      )} */}
      <ReactFlagsSelect
        className="language-selector-flag"
        countries={["US", "JP"]}
        defaultCountry="US"
        customLabels={{
          US: "English",
          JP: "Japanese"
        }}
        // placeholder="Select Language"
        showSelectedLabel={true}
        showOptionLabel={true}
        selectedSize={15}
        optionsSize={15}
        onSelect={onSelectFlag}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
