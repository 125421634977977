import ArticleList from "../ArticleList";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import agent from "../../agent";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ScheduleCalendar } from "../SchedulingView/ScheduleComponents";
import { CHANGE_TAB } from "../../constants/actionTypes";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  schedulesList: state.schedule.schedulesList,
  hcpUsers: state.common.hcpUsers,
  chatThreadsClone: state.chat.chatThreadsClone,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const MainView = (props) => {
  const { schedulesList, hcpUsers, chatThreadsClone, dashboardInfo } = props;
  const { t } = useTranslation();
  const [unreadCount, setUnreadCount] = useState("");
  const history = useHistory();

  const navigateToChatView = () => {
    history.push("/connect");
  };

  const updateStatus = () => {
    if (chatThreadsClone && chatThreadsClone.length) {
      const list = chatThreadsClone.map((li) => {
        const unreadMsgs = li.chats.filter((item) => {
          return (
            item.messageDirectionType === 3 &&
            item.messageType !== 18 &&
            item.messageType !== 19 &&
            !item.isRead
          );
        });
        if (unreadMsgs && unreadMsgs.length) {
          return unreadMsgs.length;
        } else {
          return 0;
        }
      });
      if (list && list.length) {
        const sum = list.reduce((a, b) => {
          return a + b;
        }, 0);
        setUnreadCount(sum.toString());
      } else {
        setUnreadCount("0");
      }
    } else {
      setUnreadCount("0");
    }
  };

  useEffect(() => {
    updateStatus();
  }, [chatThreadsClone]);

  return (
    <div className="dashboard-view mainview-container">
      <div className="dashboard-schedule-container">
        <div className="title">
          <h6>{t("dashboard.todays_schedule")}</h6>
        </div>
        <div className="schedule-list-container">
          <ScheduleCalendar
            schedulesList={schedulesList}
            hcpUsers={hcpUsers}
            singleDayDisplayMode={true}
          />
        </div>
      </div>
      <div className="main-body-container">
        <div className="your-dash content-container">
          <div className="title">
            <p>{t("dashboard.your_dashboard")}</p>
          </div>
          <div className="grid-container">
            <div className="grid-item msgs" onClick={navigateToChatView}>
              <div className="text">
                <div className="text-container">
                  <div
                    className="stats-box"
                    dangerouslySetInnerHTML={{
                      __html: t("dashboard.unread").replace(
                        "{x}",
                        unreadCount
                          ? `<span class="number">${unreadCount}<div class="red-dot"></div></span>`
                          : `<span class="number">0<div class="red-dot"></div></span>`
                      ),
                    }}
                  ></div>
                </div>
                <h6>{t("dashboard.conversations")}</h6>
              </div>
            </div>
            <div className="grid-item requests">
              <div className="text">
                <div className="text-container">
                  <div
                    className="stats-box"
                    dangerouslySetInnerHTML={{
                      __html: t("dashboard.new_requests").replace(
                        "{x}",
                        dashboardInfo && dashboardInfo.meeting_request
                          ? `<span class="number">${dashboardInfo.meeting_request.toString()}<div class="red-dot"></div></span>`
                          : `<span class="number">0<div class="red-dot"></div></span>`
                      ),
                    }}
                  ></div>
                </div>
                <h6>{t("dashboard.meeting_requests")}</h6>
              </div>
            </div>
            <div className="grid-item hcps">
              <div className="text">
                <div className="text-container">
                  <div
                    className="stats-box"
                    dangerouslySetInnerHTML={{
                      __html: t("dashboard.number_of_new_hcps").replace(
                        "{x}",
                        dashboardInfo && dashboardInfo.hcp_joined
                          ? `<span class="number">${dashboardInfo.hcp_joined.toString()}<div class="red-dot"></div></span>`
                          : `<span class="number">0<div class="red-dot"></div></span>`
                      ),
                    }}
                  ></div>
                </div>
                <h6>{t("dashboard.hcps_joined_text")}</h6>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------------------------- */}
        <div className="recent-stats content-container">
          <div className="title">
            <p>{t("dashboard.recent_stats")}</p>
          </div>
          <div className="grid-container">
            <div className="grid-item">
              {/* <div className="icon icon-conversation"></div> */}
              <div className="text">
                <div
                  className="stats-box"
                  dangerouslySetInnerHTML={{
                    __html: t("dashboard.conversations_this_week_with_hcps")
                      .replace(
                        "{x}",
                        dashboardInfo && dashboardInfo.conversation
                          ? `<span class="number">${dashboardInfo.conversation.toString()}</span><br/>`
                          : `<span class="number">0</span><br/>`
                      )
                      .replace(
                        "{y}",
                        dashboardInfo && dashboardInfo.conversation_hcp
                          ? dashboardInfo.conversation_hcp.toString()
                          : "0"
                      ),
                  }}
                ></div>
              </div>
            </div>
            <div className="grid-item">
              <div className="text">
                <div
                  className="stats-box"
                  dangerouslySetInnerHTML={{
                    __html: t("dashboard.appointments_scheduled").replace(
                      "{x}",
                      dashboardInfo && dashboardInfo.appointment_scheduled
                        ? `<span class="number">${dashboardInfo.appointment_scheduled.toString()}</span><br/>`
                        : `<span class="number">0</span><br/>`
                    ),
                  }}
                ></div>
              </div>
            </div>
            <div className="grid-item">
              <div className="text">
                <div
                  className="stats-box"
                  dangerouslySetInnerHTML={{
                    __html: t("dashboard.pieces_of_contents_shared").replace(
                      "{x}",
                      dashboardInfo && dashboardInfo.content_shared
                        ? `<span class="number">${dashboardInfo.content_shared.toString()}</span><br/>`
                        : `<span class="number">0</span><br/>`
                    ),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------------------------- */}
        <div className="top-shared content-container">
          <div className="title">
            <p>{t("dashboard.teams_top_shared_content_this_week")}</p>
          </div>
          <div className="grid-container">
            <div className="grid-item">
              <div className="icon icon-pdf-grey-bg"></div>
              <div className="text">
                {/* <small><div className="red-dot"></div>3 UNREAD</small> */}
                <h6>
                  {t("dashboard.conversations_this_week_with_hcps")
                    .replace(
                      "{x}",
                      dashboardInfo && dashboardInfo.conversation
                        ? dashboardInfo.conversation.toString()
                        : "0"
                    )
                    .replace(
                      "{y}",
                      dashboardInfo && dashboardInfo.conversation_hcp
                        ? dashboardInfo.conversation_hcp.toString()
                        : "0"
                    )}
                </h6>
              </div>
              <div className="secondary-icon icon-download"></div>
            </div>
            <div className="grid-item">
              <div className="icon icon-photo-1">
                <div className="icon-inset icon-video-small"></div>
              </div>
              <div className="text">
                {/* <small><div className="red-dot"></div>1 NEW REQUEST</small> */}
                <h6>
                  {t("dashboard.appointments_scheduled").replace(
                    "{x}",
                    dashboardInfo && dashboardInfo.appointment_scheduled
                      ? dashboardInfo.appointment_scheduled.toString()
                      : "0"
                  )}
                </h6>
              </div>
              <div className="secondary-icon icon-download"></div>
            </div>
            <div className="grid-item">
              <div className="icon icon-pdf-grey-bg"></div>
              <div className="text">
                {/* <small><div className="red-dot"></div>4 NEW</small> */}
                <h6>
                  {t("dashboard.pieces_of_contents_shared").replace(
                    "{x}",
                    dashboardInfo && dashboardInfo.content_shared
                      ? dashboardInfo.content_shared.toString()
                      : "0"
                  )}
                </h6>
              </div>
              <div className="secondary-icon icon-download"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
