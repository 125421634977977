import agent from "../agent";
// import Header from './Header';
import React, { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import { deviceType, osName, withOrientationChange } from "react-device-detect";
import moment from "moment";
import {
  APP_LOAD,
  REDIRECT,
  ADD_MESSAGE,
  CHAT_THREAD_UPDATED,
  GET_CHAT_RECIPIENTS_LIST,
  CLONE_CHAT_THREAD,
  GET_APPOINTMENTS_LIST,
  GET_HCP_USERS_LIST,
  LOGOUT,
} from "../constants/actionTypes";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Article from "../components/Article";
import Editor from "../components/Editor";
import Home from "../components/Home";
import NoMatch from "../components/NoMatch";
import Login from "../components/Login";
import Forgot from "../components/Forgot";
import Header from "../components/Header";
import SideMenu from "./SideMenu/SideMenu";
import PreLogin from "../components/PreLogin";
import Dashboard from "../components/Dashboard";
import EmailTemplate from "../components/EmailTemplate";
import ConnectView from "../components/ConnectView";
import SchedulingView from "../components/SchedulingView";
import ContentView from "../components/ContentView";
import UsersView from "../components/UsersView";
import OrgsView from "../components/OrgsView";
import Profile from "../components/Profile";
import ProfileFavorites from "../components/ProfileFavorites";
import Register from "../components/Register";
import Settings from "../components/Settings";
import { store } from "../store";
import { push } from "react-router-redux";
import { openWebsocket } from "../actions/actions";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Invite from "../components/Invites";
import ResetPassword from "../components/ResetPassword";
import Measure from "../components/Measure";

const mapStateToProps = (state) => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
    loggedIn: state.common.loggedIn,
    usertoken: state.common.token,
    chatThreads: state.chat.chatThreads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openWebsocket,
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () => dispatch({ type: REDIRECT }),
  onNewMessageReceived: (payload) => dispatch({ type: ADD_MESSAGE, payload }),
  onChatThreadUpdated: (payload) => dispatch({ type: ADD_MESSAGE, payload }),
  getRecipientsList: () =>
    dispatch({
      type: GET_CHAT_RECIPIENTS_LIST,
      payload: agent.Chat.getReceipients(),
    }),
  getSchedulesList: () =>
    dispatch({
      type: GET_APPOINTMENTS_LIST,
      payload: agent.Schedule.getSchedulesList(),
    }),
  cloneChatThread: (clone) =>
    dispatch({ type: CLONE_CHAT_THREAD, payload: clone || false }),
  getHcpsList: () =>
    dispatch({ type: GET_HCP_USERS_LIST, payload: agent.Users.getHcpList() }),
  onSignOut: () => {
    dispatch({ type: LOGOUT, payload: agent.Auth.logout() });
  },
});

const AppComponent = (props) => {
  const {
    loggedIn,
    currentUser,
    location,
    usertoken,
    getRecipientsList,
    getSchedulesList,
    chatThreads,
    cloneChatThread,
    getHcpsList,
    onSignOut,
  } = props;

  useEffect(() => {
    let socket;
    const token = window.localStorage.getItem("jwt");
    if (token) {
      socket = io(`${process.env.REACT_APP_WEBSOCKET_URL}?query=${token}`, {
        transports: ["websocket"],
      });
      // store.dispatch({
      //   type: UPDATE_SOCKET_OBJECT,
      //   payload: socket,
      // });

      socket.on("connect", () => {
        console.log("connected to server", socket);
      });
      const clientId = socket.id;
      socket.on("message", (data) => {
        console.log(data);
      });

      socket.on("newChatReceived", (data) => {
        getRecipientsList();
        props.onNewMessageReceived(data);
      });

      socket.on("newChatSent", (data) => {
        // if(data && data.agentId && currentUser && currentUser.uid === data.agentId){
        //   return;
        // }
        props.onChatThreadUpdated(data);
      });

      socket.on("threadUpdated", (data) => {
        if (token) {
          getRecipientsList();
          getSchedulesList();
        }
      });
    } else {
      if (socket) socket.close();
    }
    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("message");
        socket.off("newChatReceived");
        socket.off("newChatSent");
        socket.off("threadUpdated");
        socket.close();
      }
    };
  }, [loggedIn, usertoken]);

  useEffect(() => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
      agent.setToken(token);
    }
    props.onLoad(token ? agent.Auth.current() : null, token);
  }, [location]);

  useEffect(() => {
    const token = window.localStorage.getItem("jwt");
    if (currentUser && token) {
      getHcpsList();
      getRecipientsList();
      getSchedulesList();
    }
  }, [currentUser]);

  useEffect(() => {
    cloneChatThread(true);
  }, [chatThreads]);

  useEffect(() => {
    const timer = setInterval(() => {
      const lslt = window.localStorage.getItem("LSLT");
      const lastcall = window.localStorage.getItem("LASTCALL");
      if (lslt) {
        if (moment(new Date()).subtract(12, "hours").isAfter(new Date(lslt))) {
          onSignOut();
        } else if (moment(new Date()).subtract(4, "hours").isAfter(new Date(lslt)) && moment(new Date()).subtract(4, "hours").isAfter(new Date(lastcall))){
          onSignOut();
        }
      }
    }, 60000);
    return () => clearInterval(timer);
  });

  return (
    <React.Fragment>
      {props.appLoaded && props.currentUser ? (
        <Suspense fallback={null}>
          <React.Fragment>
            {props.location.pathname !== "/login" &&
              props.location.pathname !== "/forgot" &&
              props.location.pathname !== "/reset" && (
                <div
                  className={`body-container ${
                    props.isPortrait ? "portrait " : "landscape "
                  } ${deviceType + " "}
                  ${osName.toLowerCase() + " "}`}
                >
                  <div className="header">
                    <Switch>
                      <Route path="/">
                        <Header
                          appName={props.appName}
                          currentUser={props.currentUser}
                        />
                      </Route>
                    </Switch>
                  </div>

                  <div className="main-container">
                    <Switch>
                      <Route path="/">
                        <SideMenu />
                      </Route>
                    </Switch>
                    <Switch>
                      <Route exact path="/" component={Dashboard} />
                      <Route path="/dashboard" component={Dashboard} />
                      <Route path="/emailTemplate" component={EmailTemplate} />
                      <Route path="/connect">
                        {props.currentUser &&
                        (props.currentUser.roleId === 3 ||
                          props.currentUser.roleId === 4) ? (
                          <ConnectView />
                        ) : (
                          <Redirect to="/dashboard" />
                        )}
                      </Route>
                      <Route path="/scheduling">
                        {props.currentUser &&
                        (props.currentUser.roleId === 3 ||
                          props.currentUser.roleId === 4) ? (
                          <SchedulingView />
                        ) : (
                          <Redirect to="/dashboard" />
                        )}
                      </Route>
                      <Route path="/content" component={ContentView} />
                      <Route path="/users">
                        {props.currentUser &&
                        (props.currentUser.roleId === 1 ||
                          props.currentUser.roleId === 2) ? (
                          <UsersView />
                        ) : (
                          <Redirect to="/dashboard" />
                        )}
                      </Route>
                      <Route path="/organizations">
                        {props.currentUser && props.currentUser.roleId === 1 ? (
                          <OrgsView />
                        ) : (
                          <Redirect to="/dashboard" />
                        )}
                      </Route>
                      <Route path="/invite">
                        {(props.currentUser &&
                          props.currentUser.roleId === 3) ||
                        props.currentUser.roleId === 4 ? (
                          <Invite />
                        ) : (
                          <Redirect to="/dashboard" />
                        )}
                      </Route>
                      <Route path="/measure">
                        {props.currentUser &&
                        (props.currentUser.roleId === 1 ||
                          props.currentUser.roleId === 2) ? (
                          <Measure />
                        ) : (
                          <Redirect to="/dashboard" />
                        )}
                      </Route>
                    </Switch>
                  </div>
                </div>
              )}
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/forgot" component={Forgot} />
              <Route path="/reset" component={ResetPassword} />
              {/* <Route path="*" component={NoMatch} /> */}
            </Switch>
            <ToastContainer
              position="top-right"
              transition={Slide}
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </React.Fragment>
        </Suspense>
      ) : (
        <Suspense fallback={null}>
          <React.Fragment>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/login" component={Login} />
              <Route exact path="/forgot" component={Forgot} />
              <Route path="/reset" component={ResetPassword} />
              <Route path="*" component={Login} />
            </Switch>
          </React.Fragment>
        </Suspense>
      )}
    </React.Fragment>
  );
};

// App.contextTypes = {
//   router: PropTypes.object.isRequired
// };
const App = withOrientationChange(AppComponent);

export default connect(mapStateToProps, mapDispatchToProps)(App);
