import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import OtpInput from 'react-otp-input';
import agent from "../../agent";
import { UPDATE_AUTH_TOKEN, GET_HCP_BY_AGENT_ID } from "../../constants/actionTypes"
import SeachInput from './SearchInput'

export class mainview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search_name: "",
            loading: false
        }
        this.searchName = debounce(this.searchName, 300);
    }

    componentDidMount = () => {
        this.onRefresh()
    }

    //Refresh Auth Token
    onRefresh = () => {
        this.setState({ loading: true })

        const { uid } = this.props.currentUser
        this.getUserDetails(uid)

        const hcpformData = { user: { uid } }
        this.props.generateAuthToken(hcpformData)

        setTimeout(() => {
            this.setState({ loading: false })
        }, 500);
    }

    //Get User Onboarded By Agent
    getUserDetails = (user_id, name = "") => {
        this.props.getHcpByAgentId({ user_id, search_name: name })
    }

    onChange = (e) => {
        this.setState({
            search_name: e.target.value
        }, () => {
            this.searchName()
        })
    }

    //Search Filter
    searchName = () => {
        const { uid } = this.props.currentUser
        this.getUserDetails(uid, this.state.search_name)
    }

    //Copy Text Logic
    copyToClipboard = () => {
        var elm = document.getElementById("divClipboard");
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(elm);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("Copy");
    }

    //Get Org Name by OrgId
    getOrgName = () => {
        const { orgId } = this.props.currentUser
        if (this.props.orgList && this.props.orgList.length) {
            const org = this.props.orgList.filter((item) => item.uid === orgId);
            return org[0].name;
        }
    }

    render() {
        return (<div className="search-view-container search-dashboard-view">
            <div className="search-dashboard-view search-mainview-container">
                <SeachInput dataArray={this.props.userList} search_name={this.state.search_name} onChange={this.onChange} orgName={this.getOrgName()}/>
                <div className="invite-body-container">
                    <h4 className="mt-3 ml-4 mb-3">Create Invitation</h4>
                    <div className="d-flex grey">
                        <div className="invite-padding ml-3 w-50">
                            <div className="d-flex justify-content-sm-between email-padding mb-3">
                                <div className="d-flex">
                                    <i class="fa fa-envelope text-voilet mt-1" aria-hidden="true"></i>
                                    <span className="f-10 text-voilet ml-2">Via Email</span>
                                </div>
                                <span className="f-10 text-grey pointer" onClick={() => this.copyToClipboard()}>Copy this text</span>
                            </div>
                            <div className="align-items-center mr-3" id="divClipboard">
                                <p className="f-10">Hello <input className="border-none" placeholder='[Enter Doctor Name]'></input> - {this.getOrgName()} is excited to announce a new way you can connected with your account team through line.By using the steps below,you can easily reach me or your MSL contact using the line chat features you use everyday including voice and video chat options.You can even book time to meet with us when it is convenient for you.  </p>
                                <p className="f-10">To get started (1) Click on this link to open the {this.getOrgName()} LINE homepage.
                                (2) Type {this.props.authToken ? this.props.authToken.udf1 : "XXXXXX"} into the chat box (3)Respond to the automated ststem to grant permission to contact you throught this channel.</p>
                                <p className="f-10">For a more detailed step by step,click here to open our easy how to guide.</p>
                                <p className="f-10">Feel free to reach out to me through email or my phone number if you have any questions.</p>
                                <p className="f-10">Regards,</p>
                                <p className="f-10">{this.props.currentUser.fName}</p>
                            </div>
                        </div>
                        <div className="invite-padding ml-3 mr-2 otp-height">
                            <div className="d-flex justify-content-sm-between email-padding mb-3">
                                <div className="d-flex">
                                    <i class="fa fa-user-plus text-voilet mt-1" aria-hidden="true"></i>
                                    <span className="f-10 text-voilet ml-2">In-Person Or On the Phone</span>
                                </div>
                            </div>
                            <div className="align-items-center mr-3 ml-2">
                                <div className="row mb-2">
                                    <span className="f-10 ml-2">1. Ask the HCP to add "{this.getOrgName()}" on Line</span>
                                </div>
                                <div className="row mb-3">
                                    <span className="f-10 ml-2">2. Ask the HCP to send message to "{this.getOrgName()}" with this code</span>
                                </div>
                                <div className="row ml-1 mb-3">
                                    <OtpInput
                                        value={this.props.authToken ? this.props.authToken.udf1 : "XXXXXX"}
                                        onChange={this.handleChange}
                                        numInputs={6}
                                        separator={<span>-</span>}
                                        containerStyle="font-20"
                                        isDisabled={true}
                                        inputStyle="otp-width"
                                    />
                                    <i className={`${this.state.loading ? 'fa-spin ' : ''} fa fa-refresh mt-2 ml-4 font-20 pointer`} onClick={this.onRefresh}></i>
                                    <button className="btn btn-primary verify-btn ml-4 font-12 pointer d-none" type="submit"> Verify</button>
                                </div>
                                <div className="row">
                                    <span className="f-10 ml-2">3. The HCP is now authenticated</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    ...state.auth,
    currentUser: state.common.currentUser,
    authToken: state.common.authToken,
    userList: state.common.userList,
    orgList: state.common.orgs
})

const mapDispatchToProps = (dispatch) => ({
    generateAuthToken: (data) => {
        dispatch({ type: UPDATE_AUTH_TOKEN, payload: agent.Chat.generateAuthToken(data) })
    },
    getHcpByAgentId: (data) => {
        dispatch({ type: GET_HCP_BY_AGENT_ID, payload: agent.Chat.getHcpByAgentId(data) })
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(mainview) 
