export const config = {
    appId: '0569b3f5-3bd6-4d24-9b9e-6af6cd4cf474',
    redirectUri: `${process.env.REACT_APP_MSAL_URL}/scheduling`, // Live url
    scopes: [
      'user.read',
      // 'mailboxsettings.read',
      'calendars.readwrite'
    ]
  };
  
