import React, { useState, useEffect, useRef } from "react";
import { Picker, Parser, Emoji } from "mr-emoji";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import agent from "../../../agent";
import HcpProvider from "./HcpProvider";
import SearchThread from "./SearchThread";
import Message from "./Message";
import {
  SEND_TEXT_MESSAGE,
  LATEST_MESSAGES_LIST,
  SEND_MESSAGE,
  SHOW_TRANSFER_CONVERSATION_POPUP,
  POST_IS_READ_STATUS,
  SHARE_CONTENT_TO_RECIPIENT,
} from "../../../constants/actionTypes";
import ContentsPicker from "./ContentsPicker";
import AttachPreview from "./AttachPreview";
import SchedulerPopup from "../../SchedulingView/SchedulePopup";

const mapStateToProps = (state) => ({
  ...state.articleList,
  tags: state.home.tags,
  token: state.common.token,
  viewName: state.common.viewName,
  currentUser: state.common.currentUser,
  currentSettings: state.common.currentSettings,
  newIncomingMessage: state.common.newMessage,
  threadUpdatedInServer: state.common.threadUpdated,
  unReadMessages: state.common.unReadMessages,
  threadsArray: state.chat.chatThreads,
  shareContentToHcp: state.chat.shareContentToHcp,
});

const mapDispatchToProps = (dispatch) => ({
  // sendChatMessage: (data) =>
  //   dispatch({
  //     type: SEND_TEXT_MESSAGE,
  //     payload: agent.Chat.sendMessage(data),
  //   }),
  sendChatMessage: (data) =>
    dispatch({
      type: SEND_MESSAGE,
      payload: agent.Chat.sendMessage(data),
    }),
  getMessages: (ids) => {
    return agent.Chat.getMessages(ids);
  },
  updateLatestMessagesList: (data) =>
    dispatch({ type: LATEST_MESSAGES_LIST, payload: data }),
  showTransferPopup: (data) =>
    dispatch({ type: SHOW_TRANSFER_CONVERSATION_POPUP, payload: data || true }),
  updateMsgIsRead: (msgId, threadId) =>
    dispatch({
      type: POST_IS_READ_STATUS,
      payload: {
        response: agent.Chat.updateMsgReadStatus({
          messageIds: [msgId],
        }),
        threadId: threadId,
        msgId: msgId,
      },
    }),
  clearShareContentWithHcp: (data) =>
    dispatch({ type: SHARE_CONTENT_TO_RECIPIENT, payload: null }),
});

const ChatTread = (props) => {
  const {
    selectedRecipient,
    onRecipientSelect,
    currentUser,
    sendChatMessage,
    data,
    className,
    getMessages,
    newIncomingMessage,
    updateLatestMessagesList,
    threadUpdatedInServer,
    unReadMessages,
    threadsArray,
    showTransferPopup,
    updateMsgIsRead,
    currentSettings,
    shareContentToHcp,
    clearShareContentWithHcp,
  } = props;
  const { hcp, thread } = data;
  const { uid: currentHcpId } = hcp;
  const {
    uid: currentThreadId,
    currentSupportAgentId,
    currentStatus: currentThreadStatus,
  } = thread;
  const { t } = useTranslation();
  const [openEmojis, setOpenEmojis] = useState(false);
  const [contentsBalloon, setContentsBalloon] = useState(false);
  const [showContentsPicker, setShowContentsPicker] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [messageThread, setMessageThread] = useState([]);
  const chatViewContainer = useRef();
  const threadList = useRef();
  const [searchResultArray, setSearchResultArray] = useState([]);
  const [shareContentType, setShareContentType] = useState("");
  const [schedulePopupObject, setSchedulePopupObject] = useState(null);
  const chatThreadContainer = useRef();

  const lockoutPeriod = () => {
    if (currentSettings && currentSettings.autoReleaseTimeForHangingThread) {
      console.log(currentSettings.autoReleaseTimeForHangingThread);
      if (threadsArray && threadsArray.length) {
        const currentThread = threadsArray.filter((item) => {
          return item.id === currentThreadId;
        });
        if (currentThread && currentThread.length) {
          const sysMsgs = currentThread[0].chats.filter(
            (item) => item.messageDirectionType === 1 && item.messageType === 12
          );
          const lastMsg = sysMsgs[sysMsgs.length - 1];
          const transferTs = lastMsg.ts;
          const lockoutPeriodInMilliseconds =
            currentSettings.autoReleaseTimeForHangingThread * 60000;
          const lockoutUntil = new Date(
            transferTs + lockoutPeriodInMilliseconds
          );

          var start = moment.utc();
          var end = moment(lockoutUntil);
          const until = end.to(start, true);
          return until;
        }
      }
    }
  };

  const updateIsReadStatusInThread = (msgId) => {
    if (msgId && currentThreadId) {
      updateMsgIsRead(msgId, currentThreadId);
    }
  };

  const scrollChatThreadToEnd = () => {
    if (
      chatViewContainer &&
      chatViewContainer.current &&
      chatViewContainer.current.scrollIntoView
    ) {
      chatViewContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  const scrollChatThreadToUnreadMsg = () => {
    if (
      chatViewContainer &&
      chatViewContainer.current &&
      chatViewContainer.current.scrollIntoView
    ) {
      chatViewContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  const sendAttachmentWithText = () => {
    const sentAt = new Date().getTime();
    const typeProcess = () => {
      if(attachment.media_type === 3){
        if(attachment.is_s3_file){
          return attachment.media_type;
        }else{
          return 17;
        }
      }else{
        return attachment.media_type;
      }
    };
    const newMessage = {
      chatMessages: [
        {
          threadId:
            data && data.thread && data.thread.uid ? data.thread.uid : "",
          messageType: typeProcess(),
          messageDirectionType: "4",
          content: messageText,
          attachmentUrl: attachment.media_location,
          createdAt: sentAt,
        },
      ],
    };
    // const updatedThread = [...messageThread];
    // updatedThread.push(newMessage.chatMessages[0]);
    sendChatMessage(newMessage);
    // setMessageThread(updatedThread);
    setMessageText("");
    scrollChatThreadToEnd();
    setAttachment(null);
  };

  const sendOnlyText = () => {
    const sentAt = new Date().getTime();
    const newMessage = {
      chatMessages: [
        {
          threadId:
            data && data.thread && data.thread.uid ? data.thread.uid : "",
          messageType: 1,
          messageDirectionType: "4",
          content: messageText,
          attachmentUrl: "",
          createdAt: sentAt,
        },
      ],
    };
    // const updatedThread = [...messageThread];
    // updatedThread.push(newMessage.chatMessages[0]);
    sendChatMessage(newMessage);
    // setMessageThread(updatedThread);
    setMessageText("");
    scrollChatThreadToEnd();
  };

  const sendMessage = () => {
    if (
      currentSupportAgentId &&
      currentUser &&
      currentUser.uid &&
      currentSupportAgentId !== currentUser.uid
    ) {
      return;
    }
    if (messageText && messageText.length && !attachment) {
      sendOnlyText();
    } else if (attachment || (messageText && messageText.length)) {
      sendAttachmentWithText();
    }
  };

  const onEnterKey = (e) => {
    console.log(e);
    if (e.key.toLowerCase() === "enter") {
      sendMessage();
    }
  };

  const updateThread = async () => {
    // const payload = {
    //   threadIds: [data.thread.uid],
    // };
    // const messages = await getMessages(payload);
    if (threadsArray && threadsArray.length) {
      const currentThread = threadsArray.filter((item) => {
        return item.id === currentThreadId;
      });
      if (currentThread && currentThread.length) {
        setMessageThread(currentThread[0].chats);
      }
      scrollChatThreadToEnd();
    }
  };

  const transfer = () => {
    if (currentThreadId) {
      showTransferPopup(currentThreadId);
    }
  };

  const addEmoji = (emoji, event) => {
    console.log(emoji.unified);
    const x = `\u{1f44d}`;
    const msgWithEmoji = `${messageText} ${emoji.native} `;
    // const emo = () => {
    //   return (
    //     <Emoji
    //       emoji={"shrug"}
    //       size={24}
    //       fallback={(emoji) => {
    //         return `:${emoji.short_names[0]}:`;
    //       }}
    //     />
    //   );
    // };
    setMessageText(msgWithEmoji);
    setOpenEmojis(false);
  };

  const processAttachments = (attachments) => {
    if (attachments && attachments.length) {
      setAttachment(attachments[0]);
      if (attachments[0] && attachments[0].pre_approved_text) {
        setMessageText(attachments[0].pre_approved_text);
      }
    }
    clearShareContentWithHcp();
  };
  const onAttachConfirm = (attachments) => {
    setContentsBalloon(false);
    processAttachments(attachments);
  };

  const openContentsPicker = (type) => {
    setShareContentType(type);
    setShowContentsPicker(true);
  };

  const openSchedulerPopup = () => {
    const scheduleProps = {
      setSchedulePopupObject,
      threadId: currentThreadId
    };
    setSchedulePopupObject({...scheduleProps});
  };

  const contentsPickerProps = {
    showContentsPicker,
    setShowContentsPicker,
    onAttachConfirm,
    inPopupView: shareContentType,
  };

  useEffect(() => {
    if (data && data.hcp && data.hcp.uid && data.thread && data.thread.uid) {
      updateThread();
      scrollChatThreadToEnd();
    }
  }, [data.hcp.uid, threadsArray]);

  // useEffect(() => {
  //   if (newIncomingMessage) {
  //     console.log(newIncomingMessage);
  //     const { hcpId, threadId } = newIncomingMessage;
  //     if (hcpId === currentHcpId && threadId === currentThreadId) {
  //       includeInThread(newIncomingMessage);
  //     }
  //   }
  // }, [newIncomingMessage]);

  useEffect(() => {
    if (selectedRecipient) {
      if (
        selectedRecipient &&
        selectedRecipient.hcp &&
        data &&
        data.hcp &&
        data.hcp.uid &&
        selectedRecipient.hcp.uid === data.hcp.uid
      ) {
        scrollChatThreadToEnd();
      }
    }
  }, [selectedRecipient]);

  useEffect(() => {
    if (messageThread) {
      const messageLength = messageThread.length;
      const latestMessage = messageThread[messageLength - 1];
      updateLatestMessagesList({
        hcpId: currentHcpId,
        threadId: currentThreadId,
        message: latestMessage,
      });
    }
    console.log(unReadMessages);
  }, [messageThread]);

  useEffect(() => {
    if (threadUpdatedInServer) {
      const {
        hcpId: incomingHcpId,
        threadId: incomingThreadId,
      } = threadUpdatedInServer;
      if (
        incomingHcpId &&
        incomingThreadId &&
        incomingHcpId === currentHcpId &&
        incomingThreadId === currentThreadId
      ) {
        updateThread();
      }
    }
  }, [threadUpdatedInServer]);

  useEffect(() => {
    if (
      currentHcpId &&
      shareContentToHcp &&
      shareContentToHcp.hcp_id &&
      shareContentToHcp.hcp_id === currentHcpId
    ) {
      processAttachments(shareContentToHcp.share_data);
    }
  }, []);

  return (
    <div className={`chat-thread-view ${className}`}>
      <div ref={chatThreadContainer} className="chat-thread-container">
        {/* <div className="provider-section">
          <HcpProvider selectedRecipient={data} />
        </div> */}
        <div className="search-section">
          <SearchThread
            selectedRecipient={data}
            threadListRef={threadList}
            messageThread={messageThread}
            searchResultArray={searchResultArray}
            setSearchResultArray={setSearchResultArray}
            scrollChatThreadToEnd={scrollChatThreadToEnd}
          />
        </div>
        <div className="conversation-section" ref={chatViewContainer}>
          <ul ref={threadList}>
            {messageThread &&
              messageThread.length > 0 &&
              messageThread.map((item) => {
                return (
                  <Message
                    data={item}
                    chatThreadContainer={chatThreadContainer}
                    selectedRecipient={selectedRecipient}
                    searchResultArray={searchResultArray}
                    currentThreadStatus={currentThreadStatus}
                    currentThreadId={currentThreadId}
                    messageThread={messageThread}
                    updateIsReadStatusInThread={updateIsReadStatusInThread}
                  />
                );
              })}
          </ul>
        </div>
      </div>
      <div
        className={`message-box-section ${
          currentSupportAgentId &&
          currentUser &&
          currentUser.uid &&
          currentSupportAgentId !== currentUser.uid
            ? "disabled"
            : currentThreadStatus !== 2
            ? "disabled"
            : ""
        }`}
      >
        {/* {attachment && !attachment.pre_approved_text && (
          <AttachPreview attachment={attachment} setAttachment={setAttachment} />
        )} */}
        <div className="message-box-container">
          <div
            className="add-button icon-bg p-1"
            onClick={() => {
              setOpenEmojis(false);
              setContentsBalloon(!contentsBalloon);
            }}
          ></div>
          <div
            className="smiley-button icon-bg p-1"
            onClick={() => {
              setContentsBalloon(false);
              setOpenEmojis(!openEmojis);
            }}
          ></div>
          <div className="message-box p-1">
            {attachment && !attachment.pre_approved_text ? (
              <AttachPreview
                attachment={attachment}
                setAttachment={setAttachment}
              />
            ) : (
              <React.Fragment>
                <textarea
                  wrap="hard"
                  rows="2"
                  className="form-control input-box"
                  type="text"
                  placeholder={t("connect.type_message_to_send")}
                  value={messageText}
                  onChange={(e) => setMessageText(e.currentTarget.value)}
                  onKeyPress={onEnterKey}
                />
                {currentSupportAgentId !== currentUser.uid &&
                  currentThreadStatus !== 2 && (
                    <textarea
                      wrap="hard"
                      rows="2"
                      className="form-control input-box-disabled"
                      type="text"
                      placeholder={t(
                        "chat.transfer.input_lockout_text"
                      ).replace("{x}", lockoutPeriod())}
                      value={messageText}
                      onChange={(e) => setMessageText(e.currentTarget.value)}
                      onKeyPress={onEnterKey}
                    />
                  )}
              </React.Fragment>
            )}
          </div>
          <div
            onClick={transfer}
            className="conversation-button icon-bg p-1"
          ></div>
          <div className="send-button icon-bg p-1" onClick={sendMessage}></div>
        </div>
        {openEmojis && (
          <div className="emoji-container">
            <Picker onClick={addEmoji} native />
          </div>
        )}
        {contentsBalloon && (
          <div className="contents-balloon-container">
            <div className="balloon">
              <div className="list">
              <div
                  className="list-item scheduling"
                  onClick={() => openSchedulerPopup()}
                >
                  {t("chat.attach.attach_schedule")}
                </div>
                <div
                  className="list-item document"
                  onClick={() => openContentsPicker("1")}
                >
                  {t("chat.attach.attach_doc")}
                </div>
                <div
                  className="list-item video"
                  onClick={() => openContentsPicker("2")}
                >
                  {t("chat.attach.attach_video")}
                </div>
                <div
                  className="list-item text"
                  onClick={() => openContentsPicker("3")}
                >
                  {t("chat.attach.attach_text")}
                </div>
              </div>
              <div className="pointing-arrow"></div>
            </div>
          </div>
        )}
        {showContentsPicker && <ContentsPicker {...contentsPickerProps} />}
        {schedulePopupObject && <SchedulerPopup {...schedulePopupObject} />}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatTread);
