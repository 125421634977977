import {
  SEND_MESSAGE,
  ADD_MESSAGE,
  GET_CHAT_THREADS,
  GET_CHAT_RECIPIENTS_LIST,
  SHOW_TRANSFER_CONVERSATION_POPUP,
  GET_TRANSFERABLE_PEERS,
  SHOW_TRANSFER_SUCCESS_POPUP,
  UPDATE_IS_READ_STATUS,
  CLONE_CHAT_THREAD,
  SHARE_DATA,
  SHARE_CONTENT_TO_RECIPIENT,
  SEARCH_CHAT_RECIPIENTS,
  GO_TO_CHAT_RECIPIENT,
  SHOW_ADVERSE_EFFECT_POPUP,
  ON_ADVERSE_EFFECT_GET,
} from "../constants/actionTypes";

const initialState = {
  messages: [],
  chatThreads: [],
  chatThreadsClone: [],
  shareableItems: [],
  shareContentToHcp: {},
  searchChatRecipients: "",
  adverseEffectData: null,
};

const updateIsReadStatusinThread = (state, action) => {
  if (action && action.payload) {
    const { threadId, msgId } = action.payload;
    if (!threadId || !msgId) {
      return;
    }
    const fullThread = state.chatThreadsClone;
    const updatedThreads =
      fullThread && fullThread.length
        ? fullThread.map((item) => {
            if (item.id === threadId) {
              const newItem = { ...item };
              const inChatThread = newItem.chats.map((msg) => {
                if (msg.uid === msgId) {
                  if (msg.isRead === 0) {
                    msg.isRead = 1;
                  }
                }
                return msg;
              });
              return { ...newItem, chats: [...inChatThread] };
            } else {
              return item;
            }
          })
        : null;
    return {
      ...state,
      chatThreadsClone:
        updatedThreads && updatedThreads.length
          ? updatedThreads
          : state.chatThreadsClone,
    };
  } else {
    return {
      ...state,
    };
  }
};

const addMessageToThread = (state, action) => {
  const fullThread = state.chatThreads;
  const { threadId } = action.payload;
  const updatedThreads =
    fullThread && fullThread.length
      ? fullThread.map((item) => {
          if (item.id === threadId) {
            const newItem = { ...item };
            const inChatThread = newItem.chats.filter(
              (item) => item.uid === action.payload.uid
            );
            if (inChatThread && inChatThread.length) {
              // TODO
            } else {
              newItem.chats.push(action.payload);
            }
            return newItem;
          } else {
            return item;
          }
        })
      : null;
  return {
    ...state,
    chatThreads:
      updatedThreads && updatedThreads.length
        ? [...updatedThreads]
        : [...state.chatThreads],
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CHAT_RECIPIENTS_LIST:
      return {
        ...state,
        chatRecipients:
          action.payload && action.payload.data ? action.payload.data : [],
      };
    case GET_CHAT_THREADS:
      return {
        ...state,
        chatThreads:
          action.payload && action.payload.data && action.payload.data.length
            ? action.payload.data
            : [],
      };
    case CLONE_CHAT_THREAD:
      return {
        ...state,
        chatThreadsClone:
          state.chatThreads && state.chatThreads.length
            ? state.chatThreads
            : [],
      };
    case SHOW_TRANSFER_CONVERSATION_POPUP:
      return {
        ...state,
        transferConversationPopup: action.payload,
      };
    case GET_TRANSFERABLE_PEERS:
      return {
        ...state,
        transferablePeers:
          action.payload && action.payload.data && action.payload.data.length
            ? action.payload.data
            : null,
      };
    case SHOW_TRANSFER_SUCCESS_POPUP:
      return {
        ...state,
        transferSuccess: action.payload,
      };
    case ADD_MESSAGE:
      return addMessageToThread(state, action);
    case UPDATE_IS_READ_STATUS:
      return updateIsReadStatusinThread(state, action);
    case SHARE_DATA:
      return {
        ...state,
        shareableItems:
          action.payload && action.payload.length ? [...action.payload] : [],
      };
    case SHARE_CONTENT_TO_RECIPIENT:
      return {
        ...state,
        shareContentToHcp: action.payload ? { ...action.payload } : {},
      };
    case SEARCH_CHAT_RECIPIENTS:
      return {
        ...state,
        searchChatRecipients:
          action.payload && action.payload.length ? action.payload : "",
      };
    case GO_TO_CHAT_RECIPIENT:
      return {
        ...state,
        navigateToChatRecipient: action.payload ? action.payload : {},
      };
    case SHOW_ADVERSE_EFFECT_POPUP:
      return {
        ...state,
        showAdverseEffectPopup: action.payload,
      };
    case ON_ADVERSE_EFFECT_GET:
      return {
        ...state,
        adverseEffectData: !action.error
          ? action.payload && action.payload.data
            ? action.payload.data
            : {}
          : null,
      };
    default:
      return state;
  }
}
