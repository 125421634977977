import {
  OPEN_WEBSOCKET,
  UPDATE_SOCKET_OBJECT,
  ADD_MESSAGE,
} from "../constants/actionTypes";
import io from "socket.io-client";

var socket;

const handleServerMessage = (data, store, clientId) => {
  console.log("data ", data, clientId);

  // package into a variable server message data
  const response = {
    message: data.message,
    handle: data.handle,
  };
  // dispatch action that adds new message to the state array
  store.dispatch({
    type: ADD_MESSAGE,
    payload: response,
  });
};

const handleNewIncomingThread = (data, store, clientId) => {
  console.log("data ", data, clientId);

  // package into a variable server message data
  const response = {
    message: data.message,
    handle: data.handle,
  };
  // dispatch action that adds new message to the state array
  store.dispatch({
    type: ADD_MESSAGE,
    payload: response,
  });
};

const socketMiddleware = (store) => (next) => (action) => {
  // if (action.type === OPEN_WEBSOCKET) {
  //   const currentUserToken = store.getState().token;
  //   if (currentUserToken) {
  //     socket = io(`${process.env.REACT_APP_BACKEND}?query=${store.common.token}`);
  //     socket.on("connect", () => {
  //       console.log("connected to server", socket);
  //     });
  //     const clientId = socket.id;
  //     socket.on("message", (data) => {
  //       handleServerMessage(data, store, clientId);
  //     });

  //     socket.on("newChatReceived", (data) => {
  //       handleNewIncomingThread(data, store, clientId);
  //     });

  //     store.dispatch({
  //       type: UPDATE_SOCKET_OBJECT,
  //       payload: socket,
  //     });
  //   }
  // }

  // if (action.type === UPDATE_SOCKET_OBJECT) {
  //   // TODO
  // }

  next(action);
};

export default socketMiddleware;
